import moment from 'moment'
import { PureComponent } from 'react'
import { SingleDatePicker } from 'react-dates'
import { withTranslation } from 'react-i18next'
import { getAllProjectDeliveries } from 'src/service/DeliveryService'
import { getDisciplines } from 'src/service/DisciplineService'
import { getUploadDocURl } from 'src/service/FileUploadService'
import {
  getTaskErrorMessage,
  StructureValidationError,
} from 'src/service/ValidationErrors'
import RadioItems from 'src/ui-elements/radio/RadioItems'
import Textarea from 'src/ui-elements/textarea/Textarea'
import { deleteFile } from '../../service/DocumentService'
import {
  IDelivery,
  IDiscipline,
  IDocument,
  ISingleUser,
  ITaskData,
  ITaskType,
  IUserData,
} from '../../service/OrgTypes'
import {
  createTask,
  editTask,
  getProjectTaskData,
} from '../../service/TaskService'
import {
  getProjectDisplineUsers,
  getProjectUsersWithDisciplines,
} from '../../service/UserService'
import Button from '../../ui-elements/button/Button'
import Input from '../../ui-elements/input/Input'
import Spinner from '../../ui-elements/loader/Spinner'
import ModalFooter from '../../ui-elements/modal/ModalFooter'
import { ISelectItem } from '../../ui-elements/select/Select'
import { renderDayContents } from '../../utility/Utility'
import { capFirstLetter, classNames } from '../../utility/utils'
import UploadFile from '../document/UploadFile'
import UploadFileList from '../document/UploadFileList'
import Selector from '../selectors/Selector'

interface ICreateMeetingTaskFormProps {
  projectId: number
  editingMode: boolean
  createdByLeader: boolean
  task?: ITaskData
  meetingId?: number
  closeModal: () => void
  t: any
}

interface ICreateMeetingTaskFormState {
  taskId: number
  title: string
  description: string
  status: string
  taskType: string
  deadline: moment.Moment
  endDate: moment.Moment
  responsible_id: number
  parent_id: number
  parent_type: string
  discipline_id: number
  contract_id: number | undefined
  task_type_id: number | undefined | null
  disciplines: IDiscipline[]
  users: IUserData[]
  task_types: ITaskType[]
  loading: boolean
  deadlineDatePickerFocused: boolean
  datoErrorMessage: string
  titleErrorMessage: string
  disciplineErrorMessage: string
  descriptionErrorMessage: string
  responsibleErrorMessage: string
  deliveries: IDelivery[]
  selectedDelivery?: number
  selectLoading: boolean
  documents: IDocument[]
}

class MeetingTaskForm extends PureComponent<
  ICreateMeetingTaskFormProps,
  ICreateMeetingTaskFormState
> {
  private static styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row', 'pt-1'),
    taskWrapper: classNames('px-2', 'flex-1'),
  }

  public constructor(props: ICreateMeetingTaskFormProps) {
    super(props)
    this.state = {
      taskId: props.task ? props.task.id : 0,
      title: props.task ? props.task.title : '',
      description: props.task ? props.task.description : '',
      status: props.task ? props.task.status : 'open',
      taskType: props.task ? props.task.taskType : 'Aksjon',
      deadline: props.task ? moment(props.task.deadline) : moment(),
      endDate: props.task
        ? props.task.endDate
          ? moment(props.task.endDate)
          : moment()
        : moment(),
      responsible_id: props.task ? props.task.responsible_id : 0,
      parent_id: props.task ? props.task.parent_id : 0,
      parent_type: props.task ? props.task.parent_type : '',
      discipline_id: props.task ? props.task.discipline_id : 0,
      contract_id: props.task ? props.task.contract_id : 0,
      task_type_id: props.task?.task_type_id,
      users: [],
      disciplines: [],
      task_types: [],
      loading: false,
      deadlineDatePickerFocused: false,

      datoErrorMessage: '',
      titleErrorMessage: '',
      disciplineErrorMessage: '',
      descriptionErrorMessage: '',
      responsibleErrorMessage: '',
      deliveries: [],
      selectedDelivery: undefined,
      selectLoading: false,
      documents: [],
    }
  }

  private tasksTypes = [
    {
      id: 'Aksjon',
      name: <span>{capFirstLetter(this.props.t('task'))}</span>,
    },
    {
      id: 'Beslutning',
      name: <span>{capFirstLetter(this.props.t('decision'))}</span>,
    },
    {
      id: 'Informasjon',
      name: <span>{capFirstLetter(this.props.t('information'))}</span>,
    },
  ]

  private reload = () => {
    getProjectTaskData(this.props.projectId)
      .then((res: any) => {
        const taskTypeSelection: ISelectItem | any = []
        res.task_types.map((type: ITaskType) => {
          taskTypeSelection.push({
            name: type.taskTypeName,
            id: type.id,
          })
        })
        this.setState({
          task_types: taskTypeSelection,
          users: res.users ? res.users : [],
          disciplines: res.disciplines ? res.disciplines : [],
        })
      })
      .catch((err) => console.error(err))
  }

  public componentDidMount() {
    this.reload()
  }

  private onSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    let error = false
    this.setState({
      loading: true,
      datoErrorMessage: '',
      titleErrorMessage: '',
      disciplineErrorMessage: '',
      descriptionErrorMessage: '',
      responsibleErrorMessage: '',
    })

    if (this.state.taskType === 'Aksjon') {
      if (!this.state.deadline) {
        this.setState({
          datoErrorMessage:
            this.props.t('select') + ' ' + this.props.t('deadline'),
        })
        error = true
      }

      if (!this.state.discipline_id || this.state.discipline_id === 0) {
        this.setState({
          disciplineErrorMessage:
            this.props.t('select') + ' ' + this.props.t('discipline'),
        })
        error = true
      }
    }
    if (!this.state.responsible_id || this.state.responsible_id === 0) {
      this.setState({
        responsibleErrorMessage:
          this.props.t('select') + ' ' + this.props.t('responsible'),
      })
      error = true
    }

    if (!this.state.title) {
      this.setState({
        titleErrorMessage:
          this.props.t('fill_out') + ' ' + this.props.t('title'),
      })
      error = true
    }

    if (this.state.selectedDelivery) {
      const delivery = this.state.deliveries.find(
        (d) => d.id === this.state.selectedDelivery,
      )
      const { deadline } = this.state
      if (
        delivery &&
        delivery.endTime &&
        deadline.isAfter(moment(delivery.endTime), 'day')
      ) {
        this.setState({
          datoErrorMessage:
            getTaskErrorMessage(
              StructureValidationError.PAST_PARENT_DEADLINE,
              this.props.t,
            ) +
            ' (' +
            moment(delivery.endTime).format('L') +
            ')',
        })
        error = true
      }
    }

    if (!error) {
      const task = {
        id: this.state.taskId,
        title: this.state.title,
        description: this.state.description,
        status: this.state.taskType === 'Aksjon' ? this.state.status : 'done',
        taskType: this.state.taskType,
        deadline: moment(this.state.deadline, 'L'),
        responsible_id: this.state.responsible_id,
        parent_id: this.state.parent_id,
        parent_type: this.state.parent_type,
        discipline_id: this.state.discipline_id,
        system_id: undefined,
        location_id: undefined,
        project_id: this.props.projectId,
        task_type_id: this.state.task_type_id,
        endDate: this.state.status === 'done' ? this.state.endDate : undefined,
        documents: this.state.documents,
        suggested: !this.props.createdByLeader,
        delivery_id: this.state.selectedDelivery,
        first_meeting_id: this.props.meetingId,
      }

      if (this.props.editingMode) {
        editTask(task).then(() => {
          this.props.closeModal()
        })
      } else {
        createTask(task).then(() => {
          this.props.closeModal()
        })
      }
    }

    this.setState({
      loading: false,
      documents: [],
    })
    e.stopPropagation()
  }

  private onTitleChange = (e: any) => {
    const value = e.target.value
    this.setState({
      title: value,
      titleErrorMessage: '',
    })
  }

  private onDescriptionChange = (e: any) => {
    const value = e.target.value
    this.setState({
      description: value,
      descriptionErrorMessage: '',
    })
  }

  private onDeadlineChange = (date: moment.Moment) => {
    this.setState({
      deadline: date,
      datoErrorMessage: '',
    })
  }

  private setDeadlineDatePickerFocus = (focused: any) => {
    this.setState({
      deadlineDatePickerFocused: focused.focused,
    })
  }

  private outSideRangeDate = () => {
    return false
  }

  private onDisciplineChange = (discipline: number) => {
    const { projectId } = this.props
    if (this.state.responsible_id <= 0) {
      getProjectDisplineUsers(projectId, discipline).then((res) => {
        this.setState({
          users: res || [],
          responsible_id: 0,
        })
      })
    }
    const selectedDiscipline = this.state.disciplines
      .filter((d) => d.id === discipline)
      .pop()
    this.setState({
      discipline_id: discipline,
      contract_id:
        selectedDiscipline && selectedDiscipline.contract_id
          ? selectedDiscipline.contract_id
          : undefined,
      disciplineErrorMessage: '',
    })
  }

  private onResponsibleChange = (user: number) => {
    const { projectId } = this.props
    const selectedUser: IUserData | undefined = this.state.users
      .filter((u: ISingleUser) => {
        return u.id === user
      })
      .pop()
    const userDisciplines =
      selectedUser && selectedUser.disciplines
        ? selectedUser.disciplines.filter((d) => d.project_id === projectId)
        : undefined
    const discipline =
      userDisciplines && userDisciplines.length === 1
        ? userDisciplines[0]
        : undefined
    if (discipline) {
      this.setState({
        discipline_id: discipline.id,
        contract_id: discipline.contract_id,
      })
    }
    this.setState({
      responsible_id: user,
      disciplines: userDisciplines || [],
      contract_id:
        discipline && discipline.contract_id
          ? discipline.contract_id
          : undefined,
      responsibleErrorMessage: '',
    })
  }

  private onDisciplineUserClear = async () => {
    this.setState({
      discipline_id: 0,
      responsible_id: 0,
    })
    const { projectId } = this.props
    const allProjectDis = await getDisciplines(projectId)
    const allUsers = await getProjectUsersWithDisciplines(projectId)
    this.setState({
      disciplines: allProjectDis,
      users: allUsers,
    })
  }

  private onTaskTypeChange = (e: any) => {
    const value = e.target.value
    this.setState({
      taskType: value,
    })
  }

  private onTaskTypeIdChange = (task_type_id: number) => {
    this.setState({
      task_type_id,
    })
  }

  private filesUploaded = (docs: IDocument[]) => {
    this.setState({
      documents: this.state.documents.concat(docs),
    })
  }

  private updateUploadedFiles = (docs: IDocument[]) => {
    this.setState({
      documents: docs,
    })
  }

  private onCloseModal = () => {
    this.state.documents.forEach((item) => {
      deleteFile({ file_url: item.fileUrl }).catch((err) => console.error(err))
    })

    this.setState({
      documents: [],
    })

    this.props.closeModal()
  }

  private onSelectDelivery = (delivery: number) => {
    this.setState({
      selectedDelivery: delivery,
      datoErrorMessage: '',
    })
  }

  private onClearDelivery = () => {
    this.setState({
      selectedDelivery: 0,
      datoErrorMessage: '',
    })
  }

  private getDeliveries = async () => {
    this.setState({
      selectLoading: true,
    })
    const allDelivers = await getAllProjectDeliveries(this.props.projectId)
    this.setState({
      selectLoading: false,
      deliveries: allDelivers,
    })
  }

  public render() {
    const {
      title,
      description,
      deadline,
      taskType,
      responsible_id,
      discipline_id,
      task_type_id,
      users,
      disciplines,
      loading,
      documents,
      deadlineDatePickerFocused,
      datoErrorMessage,
      titleErrorMessage,
      disciplineErrorMessage,
      responsibleErrorMessage,
      deliveries,
      selectedDelivery,
      selectLoading,
      task_types,
    } = this.state

    const { t } = this.props

    return (
      <form
        className={MeetingTaskForm.styleClass.root}
        onSubmit={this.onSubmit}
      >
        <div className={'-ml-3'}>
          <RadioItems
            items={this.tasksTypes}
            onClick={this.onTaskTypeChange}
            value={taskType}
          />
        </div>
        <div>
          <div className={'flex flex-col'}>
            <label className={'pl-2 text-sm font-medium text-gray-700 mb-1'}>
              {capFirstLetter(
                taskType === 'Aksjon' ? t('deadline') : t('date'),
              )}{' '}
              *
            </label>
            {datoErrorMessage ? (
              <label className="text-red-500 text-sm">{datoErrorMessage}</label>
            ) : null}
            <div className={'pl-2'}>
              <SingleDatePicker
                firstDayOfWeek={1}
                date={deadline}
                onDateChange={this.onDeadlineChange}
                renderDayContents={renderDayContents}
                focused={deadlineDatePickerFocused}
                onFocusChange={this.setDeadlineDatePickerFocus}
                id="startDate"
                small={true}
                isOutsideRange={this.outSideRangeDate}
                showDefaultInputIcon={true}
                noBorder={true}
                numberOfMonths={1}
                displayFormat={() =>
                  moment.localeData('no').postformat('DD.MM.YY')
                }
                hideKeyboardShortcutsPanel={true}
              />
            </div>
          </div>

          <div className={MeetingTaskForm.styleClass.inputGroup}>
            <Input
              label={t('title')}
              block={true}
              value={title}
              onChange={this.onTitleChange}
              required={true}
              errorMessage={titleErrorMessage}
            />
          </div>
          <div className={MeetingTaskForm.styleClass.inputGroup}>
            <div className={'w-1/2'}>
              <Selector
                items={disciplines}
                selectedItemId={discipline_id}
                onSelect={this.onDisciplineChange}
                label={t('discipline')}
                dataFields={['shortName', 'name']}
                required={true}
                fontSize={'sm'}
                fontWeight={'bold'}
                errorMessage={disciplineErrorMessage}
                onCancel={this.onDisciplineUserClear}
                cancelButton={true}
              />
            </div>
            <div className={'w-1/2'}>
              <Selector
                items={users}
                selectedItemId={responsible_id}
                onSelect={this.onResponsibleChange}
                label={t('responsible')}
                dataFields={['firstName', 'lastName']}
                required={true}
                fontSize={'sm'}
                userSelector={true}
                fontWeight={'bold'}
                errorMessage={responsibleErrorMessage}
                onCancel={this.onDisciplineUserClear}
                cancelButton={true}
              />
            </div>
          </div>
          <div className={MeetingTaskForm.styleClass.inputGroup}>
            <div className={'w-1/2'}>
              <Selector
                items={deliveries}
                selectedItemId={selectedDelivery ?? -1}
                onSelect={this.onSelectDelivery}
                onOpenSelectFunction={this.getDeliveries}
                loading={selectLoading}
                label={t('delivery')}
                dataFields={['record_id', 'name']}
                fontSize={'sm'}
                fontWeight={'bold'}
                cancelButton={true}
                onCancel={this.onClearDelivery}
              />
            </div>
            <div className={'w-1/2'}>
              <Selector
                items={task_types}
                selectedItemId={task_type_id ?? 0}
                onSelect={this.onTaskTypeIdChange}
                label={t('task_type')}
                dataFields={['name']}
                fontSize={'sm'}
                fontWeight={'bold'}
                cancelButton={true}
                onCancel={() => this.setState({ task_type_id: null })}
              />
            </div>
          </div>
          <div className={MeetingTaskForm.styleClass.inputGroup}>
            <Textarea
              label={t('description')}
              value={description}
              isValid={false}
              onChange={this.onDescriptionChange}
              block={true}
              disabled={false}
            />
          </div>
        </div>

        {documents && documents.length > 0 && (
          <UploadFileList
            documents={documents}
            updateDocuments={this.updateUploadedFiles}
          />
        )}

        <UploadFile
          uploadUrl={getUploadDocURl(this.props.projectId, 'Task')}
          uploadedDocuments={this.filesUploaded}
        />

        <ModalFooter>
          <Button type={Button.ButtonType.DEFAULT} onClick={this.onCloseModal}>
            {t('cancel')}
          </Button>
          {this.props.editingMode ? (
            <Button type={Button.ButtonType.PRIMARY} disabled={loading}>
              {loading ? <Spinner /> : t('update')}
            </Button>
          ) : (
            <Button type={Button.ButtonType.PRIMARY} disabled={loading}>
              {loading ? <Spinner /> : t('add')}
            </Button>
          )}
        </ModalFooter>
      </form>
    )
  }
}

export default withTranslation()(MeetingTaskForm)
