import { useContext, useEffect, useRef, useState } from 'react'
import {
  IProjectContext,
  ProjectContext,
} from 'src/context/ProjectContextProvider/ProjectContext'
import { ITask } from 'src/service/OrgTypes'
import { getFilterTasks } from 'src/service/TaskService'
import List from 'src/ui-elements/list/List'
import {
  IListFilter,
  ResourceType,
} from 'src/ui-elements/list/ListContextProvider'
import { IListColumns, ISorting } from '../../ui-elements/list/ListTypes'
import {
  classNames,
  constructFilterJson,
  IActiveFilter,
} from '../../utility/utils'
import UserIcon from '../user/UserIcon'

interface INoFilterIssuesListProps {
  selectedIds?: number[]
}

const styleClass = {
  root: classNames('w-full', 'flex', 'flex-col', 'pt-0', 'pl-0'),
  inputGroup: classNames('w-full', 'flex', 'row'),
}

const NoFilterIssuesList = ({ selectedIds }: INoFilterIssuesListProps) => {
  const [tasks, setTasks] = useState<ITask[]>([])
  const [reloadTable, setReloadTable] = useState<boolean>(false)
  const [totalPages, setTotalPages] = useState<number>(0)

  const projectContext: IProjectContext = useContext(ProjectContext)
  const projectId = projectContext.state.currentProject.id
  const tasksRef = useRef(tasks)

  useEffect(() => {
    reload()
  }, [JSON.stringify(selectedIds)])

  useEffect(() => {
    tasksRef.current = tasks
  })

  const reload = () => {
    setReloadTable((n) => !n)
  }

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting

    if (status === 'expired') {
      activeFilters['delayed'] = true
    }

    activeFilters['ids'] = selectedIds ?? []

    taskFilter(activeFilters, page)
  }

  const taskFilter = (activeFilters: IActiveFilter, page: number) => {
    getFilterTasks(projectId, activeFilters, page, 5, 'Aksjon').then((res) => {
      setTasks(res.tasks)
      setTotalPages(res.pages)
    })
  }

  const columns: IListColumns[] = [
    {
      name: 'id',
      size: '150',
      id: 'recordId',
      dataField: 'record_id',
    },
    {
      name: 'title',
      size: '250',
      id: 'title',
      dataField: 'title',
    },
    {
      name: 'responsible',
      size: '250',
      id: 'responsible',
      dataField: 'responsible',
      cell: (responsible: any) =>
        responsible ? (
          <UserIcon
            userId={responsible.id}
            firstName={responsible.firstName}
            lastName={responsible.lastName}
            image_url={responsible.image_url}
          />
        ) : (
          <span />
        ),
    },
  ]

  return (
    <div className={styleClass.root}>
      <List
        columns={columns}
        data={selectedIds?.length && selectedIds?.length > 0 ? tasks : []}
        tableName={'NoFilterIssuesList'}
        itemsPerPage={50}
        pagination={true}
        resourceType={ResourceType.TASK}
        getFilteredData={getFilteredData}
        disableColumnSelector={true}
        reload={reloadTable}
        totalPages={
          selectedIds?.length && selectedIds?.length > 0 ? totalPages : 0
        }
      />
    </div>
  )
}
export default NoFilterIssuesList
