import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import {
  getFilterValues,
  setFilterValues,
} from 'src/service/CanavasFilterService'
import { getProjectContracts } from 'src/service/ContractService'
import { getProjectContractDisciplines } from 'src/service/DisciplineService'
import { getProjectMilestones } from 'src/service/MilestoneService'
import { IUserData, TimelineFilterTypes } from 'src/service/OrgTypes'
import {
  getMainProcesses,
  getProjectKeypoints,
} from 'src/service/ProcessService'
import { actionSource } from 'src/service/SystemValues'
import { getProjectTags } from 'src/service/TagService'
import { getProjectTeams } from 'src/service/TeamService'
import {
  getProjectUsersWithDisciplines,
  getUserBySelectedDisciplines,
} from 'src/service/UserService'
import Button from 'src/ui-elements/button/Button'
import { ButtonSize } from 'src/ui-elements/button/ButtonEnums'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import { checkKeys, classNames } from '../../utility/utils'
import FilterIcon from '../canvas-header/FilterIcon'
import CloseClickOutside from '../click-outside/CloseClickOutside'
import { mainProcessDatatypes } from '../discipline/disciplineUtils'
import useDidMountEffect from '../hooks/UseDidMountEffect'
import MultiSelector from '../multi-selector/MultiSelector'
import SwitchHOC from '../switchHoc/switchHoc'

export const FloatingFilterstyleClass = () => {
  return {
    root: () => classNames('z-40 relative'),
    rootFloating: (hide?: boolean) =>
      classNames('z-[45] relative', hide ? 'hidden' : ''),
    icon: (opened: boolean, filtersOn: boolean) =>
      classNames(
        'w-9',
        'h-9',
        'rounded-full',
        opened
          ? 'bg-red-500 text-white'
          : filtersOn
            ? 'bg-blue-root text-white'
            : 'bg-white text-blue-root hover:bg-blue-root hover:text-white',
        'flex',
        'justify-center',
        'items-center',
        'cursor-pointer',
        'relative',
        'shadow-md',
      ),
    filter: classNames(
      'flex',
      'flex-col',
      'bg-white',
      'shadow-xl',
      'absolute',
      'right-2',
      'top-10',
      'min-w-75',
      'mr-2',
      'mt-1',
      'z-20',
      'max-h-[80vh]',
    ),
    rootFilter: classNames(
      'flex',
      'flex-col',
      'items-left',
      'flex-wrap',
      'min-w-75 ',
    ),
    filterSelector: classNames(
      'mr-6',
      'mb-2',
      'w-full',
      'min-w-75 ',
      'max-w-74',
    ),
    switch: (halfWidth: boolean) =>
      classNames(
        'mr-5',
        'w-full',
        'flex',
        'justify-left',
        'items-end',
        halfWidth ? 'w-1/2' : 'sm:w-1/2 lg:w-1/3',
        'py-2',
        'mb-0.8',
        'max-w-32',
      ),
    booleanFilters: (noBooleanFilters: boolean) =>
      classNames('flex', 'justify-between', noBooleanFilters ? 'my-3' : ''),
    label: {
      root: classNames(
        'p-1',
        'rounded',
        'bg-black',
        'absolute',
        'text-xxs',
        'z-10',
        'font-normal',
        'text-white',
      ),
      label: classNames(
        'text-sm',
        'leading-5',
        'font-medium',
        'text-gray-600',
        'pr-2',
        'w-mx-content',
        'my-2',
      ),
    },
    filterBox: classNames(
      'bg-white',
      'font-normal',
      'border-gray-300',
      'border',
      'shadow-sm',
      'flex',
      'flex-col',
      'justify-start',
      'text-capitalize',
      'px-1',
    ),
  }
}

export enum CanvasFilterType {
  USERS = 'users',
  CONTRACTS = 'contracts',
  DISCIPLINES = 'disciplines',
  MILESTONES = 'milestones',
  KEYPOINTS = 'keypoints',
  PROCESSES = 'processes',
  THEMES = 'themes',
  TYPES = 'types',
  TAGS = 'tags',
  OPEN = 'open',
  BACK_PLAN = 'backplan',
  SOURCE = 'source',
}

export interface ICanvasFloatingFilterProps {
  onFilterSet?: (type: string, values: any[] | boolean) => void
  showProcessSelector?: boolean
  showMilestoneSelector?: boolean
  showBackPlanFilter?: boolean
  showThemes?: boolean
  showKeypoints?: boolean
  showUser?: boolean
  showTag?: boolean
  showSource?: boolean
  users?: IUserData[]
  showOpenFilter?: boolean
  showTypes?: boolean
  onFilterClear?: () => void
  filterName: TimelineFilterTypes
  getData: (filter: any) => void
  reload: boolean
  hide?: boolean
}

const CanvasFloatingFilter = ({
  hide,
  reload,
  getData,
  filterName,
  onFilterClear,
  onFilterSet,
  showProcessSelector,
  showMilestoneSelector,
  showBackPlanFilter,
  showThemes,
  showKeypoints,
  showUser,
  showOpenFilter,
  users,
  showTypes,
  showTag,
  showSource,
}: ICanvasFloatingFilterProps) => {
  const initState = {
    open: false,
    backplan: false,
    users: [],
    contracts: [],
    disciplines: [],
    milestones: [],
    keypoints: [],
    processes: [],
    themes: [],
    tags: [],
    source: [],
    types: ['mile_stone', 'key_point', 'delivery', 'task'],
  }
  const initFirstLoad = {
    users: true,
    contracts: true,
    disciplines: true,
    milestones: true,
    keypoints: true,
    processes: true,
    themes: true,
    tags: true,
    types: true,
    source: false,
  }

  const styleClass = FloatingFilterstyleClass()

  const [items, setItems] = useState(initState)
  const [itemsFirst, setItemsFirst] = useState(initFirstLoad)
  const [selectedItem, setSelectedItems] = useState<any>(initState)
  const [loading, setLoading] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const projectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject
  const { t } = useTranslation()

  useEffect(() => {
    loadStoredFilter()
  }, [])

  const loadStoredFilter = async () => {
    const storedFilter = await getFilterValues(projectId, filterName)
    const filter =
      storedFilter && !Array.isArray(storedFilter) ? storedFilter : initState
    if (!checkKeys(filter, initState)) {
      // stored filter misses some items
      for (const key of Object.keys(initState)) {
        if (filter[key] === undefined) {
          filter[key] = initState[key]
        }
      }
    }
    setSelectedItems(filter)
    getData(filter)
  }

  useDidMountEffect(() => {
    getData(selectedItem)
  }, [reload])

  useDidMountEffect(() => {
    clearFileds()
    loadStoredFilter()
  }, [projectId])

  useEffect(() => {
    if (users && users.length > 0) {
      const clonedItems = { ...selectedItem }
      clonedItems[CanvasFilterType.USERS] = users.map((usr) => usr.id)
      setSelectedItems(clonedItems)
    }
  }, [users])

  useEffect(() => {
    const noFilters = _.isEqual(selectedItem, initState)
    setFiltersApplied(!noFilters)
  }, [selectedItem])

  const onClear = () => {
    clearFileds()
    getData(initState)
    setFilterValues(projectId, filterName, initState)
    if (onFilterClear) {
      onFilterClear()
    }
  }

  const clearFileds = () => {
    setItems(initState)
    setSelectedItems(initState)
    setItemsFirst(initFirstLoad)
    setFiltersApplied(false)
  }

  const onSelectedIdSet = (type: string, value: any[] | boolean) => {
    const cloneSelectedItem = { ...selectedItem }
    cloneSelectedItem[type] = value
    setSelectedItems(cloneSelectedItem)
    getData(cloneSelectedItem)
    setFilterValues(projectId, filterName, cloneSelectedItem)
    if (onFilterSet) {
      onFilterSet(type, value)
    }
  }

  const setItem = (type: string, values: any[]) => {
    const clonedItems = { ...items }
    clonedItems[type] = values
    setItems(clonedItems)
  }

  const setFirstLoad = (type: string, value: boolean) => {
    const clonedItemsFirst = { ...itemsFirst }
    clonedItemsFirst[type] = value
    setItemsFirst(clonedItemsFirst)
  }

  const onDisciplineOpen = () => {
    return new Promise<void>(async (resolve) => {
      if (itemsFirst[CanvasFilterType.DISCIPLINES]) {
        setLoading(CanvasFilterType.DISCIPLINES)
        const allDisciplines = await getProjectContractDisciplines(projectId, {
          contract:
            selectedItem[CanvasFilterType.CONTRACTS].length > 0
              ? selectedItem[CanvasFilterType.CONTRACTS]
              : selectedItem[''],
        })
        setItem(CanvasFilterType.DISCIPLINES, allDisciplines)
        setFirstLoad(CanvasFilterType.DISCIPLINES, false)
        setLoading('')
        resolve()
      }
      resolve()
    })
  }

  const onContractOpen = () => {
    return new Promise<void>(async (resolve) => {
      if (itemsFirst[CanvasFilterType.CONTRACTS]) {
        setLoading(CanvasFilterType.CONTRACTS)
        const allContracts = await getProjectContracts(projectId)
        setItem(CanvasFilterType.CONTRACTS, allContracts)
        setFirstLoad(CanvasFilterType.CONTRACTS, false)
        setLoading('')
        resolve()
      }
      resolve()
    })
  }

  const onMilestoneOpen = (): Promise<any> => {
    return new Promise<void>(async (resolve) => {
      if (itemsFirst[CanvasFilterType.MILESTONES]) {
        setLoading(CanvasFilterType.MILESTONES)
        const allMilestones = await getProjectMilestones(projectId)
        setItem(CanvasFilterType.MILESTONES, allMilestones)
        setFirstLoad(CanvasFilterType.MILESTONES, false)
        setLoading('')
        resolve()
      }
      resolve()
    })
  }

  const onProcessOpen = (): Promise<any> => {
    return new Promise<void>(async (resolve) => {
      if (itemsFirst[CanvasFilterType.PROCESSES]) {
        setLoading(CanvasFilterType.PROCESSES)
        const dataProcesses = await getMainProcesses(projectId)
        setItem(CanvasFilterType.PROCESSES, dataProcesses)
        setFirstLoad(CanvasFilterType.PROCESSES, false)
        setLoading('')
        resolve()
      }
      resolve()
    })
  }

  const onThemesOpen = (): Promise<any> => {
    return new Promise<void>(async (resolve) => {
      if (itemsFirst[CanvasFilterType.THEMES]) {
        setLoading(CanvasFilterType.THEMES)
        const dataThemes = await getProjectTeams(projectId)
        setItem(CanvasFilterType.THEMES, dataThemes)
        setFirstLoad(CanvasFilterType.THEMES, false)
        setLoading('')
        resolve()
      }
      resolve()
    })
  }

  const onResponsibleOpen = async () => {
    return new Promise<void>(async (resolve) => {
      if (itemsFirst[CanvasFilterType.USERS]) {
        setLoading(CanvasFilterType.USERS)

        const allUsers =
          selectedItem[CanvasFilterType.DISCIPLINES].length > 0
            ? await getUserBySelectedDisciplines(projectId, {
                discipline: selectedItem[CanvasFilterType.DISCIPLINES],
              })
            : await getProjectUsersWithDisciplines(projectId)
        setItem(CanvasFilterType.USERS, allUsers)
        setFirstLoad(CanvasFilterType.USERS, false)
        setLoading('')
        resolve()
      }
      resolve()
    })
  }

  const onKeypointOpen = (): Promise<any> => {
    return new Promise<void>(async (resolve) => {
      if (itemsFirst[CanvasFilterType.KEYPOINTS]) {
        setLoading(CanvasFilterType.KEYPOINTS)
        const keypointsData = await getProjectKeypoints(projectId)
        setItem(CanvasFilterType.KEYPOINTS, keypointsData)
        setFirstLoad(CanvasFilterType.KEYPOINTS, false)
        setLoading('')
        resolve()
      }
      resolve()
    })
  }

  const onTagOpen = (): Promise<any> => {
    return new Promise<void>(async (resolve) => {
      if (itemsFirst[CanvasFilterType.TAGS]) {
        setLoading(CanvasFilterType.TAGS)
        const allTags = await getProjectTags(projectId)
        setItem(CanvasFilterType.TAGS, allTags)
        setFirstLoad(CanvasFilterType.TAGS, false)
        setLoading('')
        resolve()
      }
      resolve()
    })
  }

  const onChangeContract = async (selectedids: any[]) => {
    setLoading(CanvasFilterType.DISCIPLINES)
    const cloneSelectedItem = { ...selectedItem }
    const allDisciplines = await getProjectContractDisciplines(projectId, {
      contract: selectedids,
    })
    cloneSelectedItem[CanvasFilterType.CONTRACTS] = selectedids
    if (selectedids.length > 0) {
      const newSelectedDiscipline = allDisciplines.map(
        (discipline: any) => discipline.id,
      )
      if (onFilterSet) {
        onFilterSet(CanvasFilterType.DISCIPLINES, newSelectedDiscipline)
      }
      cloneSelectedItem[CanvasFilterType.DISCIPLINES] = newSelectedDiscipline
    } else {
      if (onFilterSet) {
        onFilterSet(CanvasFilterType.DISCIPLINES, [])
      }
      cloneSelectedItem[CanvasFilterType.DISCIPLINES] = []
    }
    setSelectedItems(cloneSelectedItem)
    setItem(CanvasFilterType.DISCIPLINES, allDisciplines)
    getData(cloneSelectedItem)
    setFilterValues(projectId, filterName, cloneSelectedItem)
    setLoading('')
  }

  const onChangeDiscipline = async (selectedids: any[]) => {
    setLoading(CanvasFilterType.USERS)
    const cloneSelectedItem = { ...selectedItem }
    const allUsers = await getUserBySelectedDisciplines(projectId, {
      discipline: selectedids,
    })
    cloneSelectedItem[CanvasFilterType.DISCIPLINES] = selectedids
    if (selectedids.length > 0) {
      const newSelectedUser = allUsers.map((user: any) => user.id)
      if (onFilterSet) {
        onFilterSet(CanvasFilterType.USERS, newSelectedUser)
      }
      cloneSelectedItem[CanvasFilterType.USERS] = newSelectedUser
    } else {
      if (onFilterSet) {
        onFilterSet(CanvasFilterType.USERS, [])
      }
      cloneSelectedItem[CanvasFilterType.USERS] = []
    }
    setSelectedItems(cloneSelectedItem)
    setItem(CanvasFilterType.USERS, allUsers)
    getData(cloneSelectedItem)
    setFilterValues(projectId, filterName, cloneSelectedItem)
    setLoading('')
  }

  return (
    <CloseClickOutside onClose={() => setOpen(false)}>
      <div className={styleClass.rootFloating(hide)}>
        <FilterIcon
          open={open}
          setOpen={setOpen}
          filtersApplied={filtersApplied}
        />
        {open && (
          <div className={styleClass.filter}>
            <div className={styleClass.rootFilter}>
              <div className={'pl-5 pt-4'}>
                <div className={styleClass.filterSelector}>
                  <MultiSelector
                    items={items[CanvasFilterType.CONTRACTS]}
                    onOpenList={onContractOpen}
                    onSelect={onChangeContract}
                    label={t('contracts')}
                    selectedItems={selectedItem[CanvasFilterType.CONTRACTS]}
                    dataFields={['contractNumber', 'contractName']}
                    fontWeight={'bold'}
                    scroll={true}
                    loading={loading === CanvasFilterType.CONTRACTS}
                    noBorder={true}
                    bgColor={'white'}
                  />
                </div>

                <div className={styleClass.filterSelector}>
                  <MultiSelector
                    items={items[CanvasFilterType.DISCIPLINES]}
                    onOpenList={onDisciplineOpen}
                    label={t('disciplines')}
                    dataFields={['shortName', 'name']}
                    selectedItems={selectedItem[CanvasFilterType.DISCIPLINES]}
                    fontWeight={'bold'}
                    onSelect={(ids) =>
                      users
                        ? onSelectedIdSet(CanvasFilterType.DISCIPLINES, ids)
                        : onChangeDiscipline(ids)
                    }
                    loading={loading === CanvasFilterType.DISCIPLINES}
                    scroll={true}
                    noBorder={true}
                    bgColor={'white'}
                  />
                </div>

                {showUser && (
                  <div className={styleClass.filterSelector}>
                    <MultiSelector
                      items={users ? users : items[CanvasFilterType.USERS]}
                      label={t('responsible')}
                      onOpenList={users ? undefined : onResponsibleOpen}
                      dataFields={['firstName', 'lastName']}
                      selectedItems={selectedItem[CanvasFilterType.USERS]}
                      fontWeight={'bold'}
                      onSelect={(ids) =>
                        onSelectedIdSet(CanvasFilterType.USERS, ids)
                      }
                      loading={loading === CanvasFilterType.USERS}
                      scroll={true}
                      noBorder={true}
                      bgColor={'white'}
                    />
                  </div>
                )}

                {showKeypoints && (
                  <div className={styleClass.filterSelector}>
                    <MultiSelector
                      items={items[CanvasFilterType.KEYPOINTS]}
                      onOpenList={onKeypointOpen}
                      label={t('keypoints')}
                      dataFields={['record_id', 'name']}
                      selectedItems={selectedItem[CanvasFilterType.KEYPOINTS]}
                      fontWeight={'bold'}
                      onSelect={(ids) =>
                        onSelectedIdSet(CanvasFilterType.KEYPOINTS, ids)
                      }
                      loading={loading === CanvasFilterType.KEYPOINTS}
                      scroll={true}
                      noBorder={true}
                      bgColor={'white'}
                    />
                  </div>
                )}

                {showMilestoneSelector && (
                  <div className={styleClass.filterSelector}>
                    <MultiSelector
                      items={items[CanvasFilterType.MILESTONES]}
                      onOpenList={onMilestoneOpen}
                      onSelect={(ids) =>
                        onSelectedIdSet(CanvasFilterType.MILESTONES, ids)
                      }
                      label={t('milestones')}
                      selectedItems={selectedItem[CanvasFilterType.MILESTONES]}
                      dataFields={['record_id', 'name']}
                      fontWeight={'bold'}
                      scroll={true}
                      loading={loading === CanvasFilterType.MILESTONES}
                      noBorder={true}
                      bgColor={'white'}
                    />
                  </div>
                )}

                {showProcessSelector && (
                  <div className={styleClass.filterSelector}>
                    <MultiSelector
                      items={items[CanvasFilterType.PROCESSES]}
                      onOpenList={onProcessOpen}
                      label={t('main_processes')}
                      dataFields={['name']}
                      selectedItems={selectedItem[CanvasFilterType.PROCESSES]}
                      fontWeight={'bold'}
                      onSelect={(ids) =>
                        onSelectedIdSet(CanvasFilterType.PROCESSES, ids)
                      }
                      loading={loading === CanvasFilterType.PROCESSES}
                      scroll={true}
                      noBorder={true}
                      bgColor={'white'}
                    />
                  </div>
                )}
                {showThemes && (
                  <div className={styleClass.filterSelector}>
                    <MultiSelector
                      items={items[CanvasFilterType.THEMES]}
                      onOpenList={onThemesOpen}
                      label={t('teams')}
                      dataFields={['name']}
                      selectedItems={selectedItem[CanvasFilterType.THEMES]}
                      fontWeight={'bold'}
                      onSelect={(ids) =>
                        onSelectedIdSet(CanvasFilterType.THEMES, ids)
                      }
                      loading={loading === CanvasFilterType.THEMES}
                      scroll={true}
                      noBorder={true}
                      bgColor={'white'}
                    />
                  </div>
                )}

                {showTag && (
                  <div className={styleClass.filterSelector}>
                    <MultiSelector
                      items={items[CanvasFilterType.TAGS]}
                      onOpenList={onTagOpen}
                      label={t('type')}
                      dataFields={['name']}
                      selectedItems={selectedItem[CanvasFilterType.TAGS]}
                      fontWeight={'bold'}
                      onSelect={(ids) =>
                        onSelectedIdSet(CanvasFilterType.TAGS, ids)
                      }
                      loading={loading === CanvasFilterType.TAGS}
                      scroll={true}
                      noBorder={true}
                      bgColor={'white'}
                    />
                  </div>
                )}

                {showTypes && (
                  <div className={styleClass.filterSelector}>
                    <MultiSelector
                      items={mainProcessDatatypes(t)}
                      label={t('name')}
                      dataFields={['name']}
                      selectedItems={selectedItem[CanvasFilterType.TYPES]}
                      fontWeight={'bold'}
                      onSelect={(ids) =>
                        onSelectedIdSet(CanvasFilterType.TYPES, ids)
                      }
                      loading={loading === CanvasFilterType.TYPES}
                      scroll={true}
                      noBorder={true}
                      bgColor={'white'}
                    />
                  </div>
                )}

                {showSource && (
                  <div className={styleClass.filterSelector}>
                    <MultiSelector
                      items={actionSource(t).map((c) => ({
                        ...c,
                        id: c.value,
                      }))}
                      label={t('source')}
                      dataFields={['name']}
                      selectedItems={selectedItem[CanvasFilterType.SOURCE]}
                      fontWeight={'bold'}
                      onSelect={(ids) =>
                        onSelectedIdSet(CanvasFilterType.SOURCE, ids)
                      }
                      loading={loading === CanvasFilterType.SOURCE}
                      scroll={true}
                      noBorder={true}
                      bgColor={'white'}
                    />
                  </div>
                )}

                <div
                  className={styleClass.booleanFilters(
                    !showBackPlanFilter && !showOpenFilter,
                  )}
                >
                  {showBackPlanFilter && (
                    <div className={styleClass.switch(false)}>
                      <span className={'mx-2 text-gray-600'}>
                        {t('delayed')}
                      </span>
                      <SwitchHOC
                        valueProp={selectedItem[CanvasFilterType.BACK_PLAN]}
                        className="custom-classname"
                        onChange={(v) => {
                          onSelectedIdSet(CanvasFilterType.BACK_PLAN, v)
                        }}
                      />
                    </div>
                  )}

                  {showOpenFilter && (
                    <div className={styleClass.switch(false)}>
                      <span className={'mx-2 text-gray-600'}>{t('open')}</span>
                      <SwitchHOC
                        valueProp={selectedItem[CanvasFilterType.OPEN]}
                        className="custom-classname"
                        onChange={(v) => {
                          onSelectedIdSet(CanvasFilterType.OPEN, v)
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={'w-full flex justify-end px-4 py-2 bg-gray-100'}>
              <Button
                onClick={onClear}
                size={ButtonSize.XSMALL}
                noTextWrap={true}
              >
                <Icon icon={Icons.CLOSE_GRAY} className={'mr-2 w-4 h-4 flex'} />
                {t('reset')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </CloseClickOutside>
  )
}

export default CanvasFloatingFilter
