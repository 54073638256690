import { t } from 'i18next'
import { FC, useState } from 'react'
import * as React from 'react'
import SystemSelectTable from 'src/components/system/system-table/SystemSelectTable'
import { TableFilter } from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { classNames } from 'src/utility/utils'

interface ISelectSystemsListProps {
  tableName: string
  onSubmit: (ids: number[]) => void
  closeModal: () => void
  initialSelection?: number[]
  targetName?: string
  filter?: Omit<TableFilter, 'sort'>
}

const SystemSelectModal: FC<ISelectSystemsListProps> = ({
  tableName,
  onSubmit,
  closeModal,
  initialSelection,
  targetName,
  filter,
}) => {
  const [selectedSystems, setSelectedSystems] = useState<number[]>(
    initialSelection ?? [],
  )

  const styleClass = {
    root: classNames('md_w-full', 'flex', 'p-2', 'flex-col', 'min-h-[700px]'),
    inputGroup: classNames('w-full', 'flex', 'row'),
  }

  return (
    <Modal
      show={true}
      title={
        targetName
          ? t('select_items_you_want_to_add_to_target_w_param', {
              param: t('systems').toLowerCase(),
              target: targetName,
            })
          : t('select_items_you_want_to_add_w_param', {
              param: t('systems').toLowerCase(),
            })
      }
      closeModal={closeModal}
      size={'w-9/10'}
      minHeight={'800px'}
      maxWidth={1700}
    >
      <div className={styleClass.root}>
        <SystemSelectTable
          tableName={tableName}
          onSelectedSystems={setSelectedSystems}
          initialSelection={initialSelection}
          filter={filter}
        />
      </div>
      <ModalFooter>
        <Button onClick={closeModal}>{t('cancel')}</Button>
        <Button
          disabled={selectedSystems.length < 1}
          type={Button.ButtonType.PRIMARY}
          onClick={() => onSubmit(selectedSystems)}
        >
          {t('save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SystemSelectModal
