import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getProjectSystemTypeGroupPerDomain } from 'src/service/SystemTypeGroupService'

export const useSystemTypeGroups = (domainName: string) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: ['systemTypeGroups', projectId, domainName],
    queryFn: () =>
      getProjectSystemTypeGroupPerDomain(projectId, domainName).then((res) =>
        res.pop(),
      ),
  })
}
