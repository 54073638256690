import { PureComponent } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { capFirstLetter, classNames } from '../../utility/utils'
import Icon from '../icon/Icon'
import { IListContext } from './ListContextProvider'
import { IListColumns } from './ListTypes'
import { withListContext } from './withListContext'

interface IColumnFilterItem extends IListContext, WithTranslation {
  column: IListColumns
  active: boolean
}

const styleClass = {
  filterItem: classNames(
    'group',
    'flex',
    'cursor-pointer',
    'items-center',
    'px-2',
    'py-1',
    'text-sm',
    'leading-5',
    'text-gray-700',
    'hover:bg-gray-100',
    'hover:text-gray-900',
    'focus:bg-gray-100',
    'focus:text-gray-900',
    'w-full',
    'gap-x-2',
  ),
}

class ColumnFilterItem extends PureComponent<IColumnFilterItem> {
  private toggleItem = () => {
    const { removeColumn, addColumn } = this.props.actions
    const { active, column } = this.props
    if (active) {
      removeColumn(column)
    } else {
      addColumn(column)
    }
  }

  private getTranslatedColumnName = (val: string) => {
    const { t } = this.props
    const hasParam = val.match(',')

    if (hasParam) {
      const splittedVal = val.split(',')
      return capFirstLetter(
        t(splittedVal[0], { [splittedVal[1]]: splittedVal[2] }),
      )
    } else {
      return capFirstLetter(t(val))
    }
  }

  public render() {
    const { name } = this.props.column
    const { active } = this.props
    return (
      <div className={styleClass.filterItem} onClick={this.toggleItem}>
        {!active ? (
          <Icon icon={Icon.IconType.CHECK_BOX_TW} />
        ) : (
          <Icon icon={Icon.IconType.CHECKED_BOX_TW} />
        )}
        {`${this.getTranslatedColumnName(name)}`}
      </div>
    )
  }
}

export default withTranslation()(withListContext(ColumnFilterItem))
