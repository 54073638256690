import moment from 'moment'
import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import InspectorSections, {
  IInspectorSection,
} from 'src/document/components/Inspector/InspectorSections'
import { ITestExecution, TestApproval } from 'src/service/OrgTypes'
import { getTestApprovals } from 'src/service/TestApproval'
import { editTestExecution, getTestExecution } from 'src/service/TestService'
import { updateWallToken } from 'src/service/TheWallService'
import FixedPane from 'src/ui-elements/fixed-pane/FixedPane'
import { Icons } from 'src/ui-elements/icon/Icon'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineCompontent from 'src/ui-elements/page-display/inline-components/InlineComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import CloseTestExecution from '../approval/CloseTestExecution'
import SendForSigning from '../approval/SendForSigning'
import { TestExecutionStatus } from '../tests/TestExecutionList'
import ApprovalInspectorTable from './ApprovalInspectorTable'

interface TestExecutionInspectorPanelProps {
  testId: number
  testExecutionId: number
  open: boolean
  isTestOwner: boolean
  onClose: () => void
  onUpdate: () => void
}

const TestExecutionInspectorPanel: FC<TestExecutionInspectorPanelProps> = ({
  testId,
  testExecutionId,
  open,
  onClose,
  onUpdate,
  isTestOwner,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [executionData, setExecutionData] = useState<ITestExecution>()
  const [approvals, setApprovals] = useState<TestApproval[]>([])

  const loadData = useCallback(async () => {
    const data = await getTestExecution(testExecutionId)
    setExecutionData(data)
    setLoading(false)
  }, [testExecutionId])

  useEffect(() => {
    loadData()
  }, [loadData])

  const projectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject

  const editExecution = async (
    id: number,
    testExecution: Partial<ITestExecution>,
  ) => {
    if (id < 0) return
    await editTestExecution(projectId, testId, { id: id, ...testExecution })
    loadData()
    onUpdate()
  }

  const pullApprovals = async () => {
    const res = await getTestApprovals(projectId, testExecutionId)
    setApprovals(res)
  }

  const downloadFile = async (url: string) => {
    const token = await updateWallToken()
    window.open(`${url}&token=${token}`, '_blank')
  }

  const sections: IInspectorSection[] = [
    {
      name: t('documents'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: (
        <InspectorSectionTable
          headerColumns={[t('name'), t('uploaded'), t('')]}
          rowsData={(executionData?.documents ?? []).map((doc) => {
            return {
              cells: [
                <a
                  key={doc.fileName}
                  className="cursor-pointer underline text-blue-root"
                  onClick={() => downloadFile(doc.fileUrl)}
                >
                  {doc.fileName}
                </a>,
                moment(doc.created_at).format('L'),
                <MaterialIcon
                  key={doc.id}
                  icon="open_in_new"
                  onClick={() => downloadFile(doc.fileUrl)}
                  className="bg-d-lightblue cursor-pointer w-12 h-5 text-blue-root text-xs flex px-2 items-center justify-center rounded-md"
                />,
              ],
              id: doc.id,
            }
          })}
        />
      ),
    },
    {
      name: t('approve_test_report'),
      icon: 'fact_check',
      activeIcon: 'fact_check',
      isMaterialIcon: true,
      onClick: pullApprovals,
      content: <ApprovalInspectorTable approvals={approvals} />,
    },
  ]

  return (
    <FixedPane
      title={!loading ? `${executionData?.record_id}` : t('loading...')}
      show={open}
      onClose={() => onClose()}
      className={'w-[700px]'}
    >
      <>
        {loading ? (
          <SpinnerMobile className="scale-75 flex self-center" />
        ) : (
          <>
            {executionData && (
              <InlineComponentsWrapper
                key={testExecutionId}
                loading={loading}
                padding="left"
                labelWidth="w-48"
              >
                <DateTimeInlineInputComponent
                  label={t('planned_start')}
                  selectedTime={`${executionData.test_planned_start}`}
                  onValueSubmitted={(d) => {
                    editExecution(testExecutionId, {
                      test_planned_start: moment(d),
                    })
                  }}
                  disabled={executionData.step !== 'planning'}
                  inspectorPanel={true}
                />
                <DateTimeInlineInputComponent
                  label={t('planned_end')}
                  selectedTime={`${executionData.test_planned_end}`}
                  onValueSubmitted={(d) => {
                    editExecution(testExecutionId, {
                      test_planned_end: moment(d),
                    })
                  }}
                  disabled={executionData.step !== 'planning'}
                  inspectorPanel={true}
                />
                <DateTimeInlineInputComponent
                  label={t('started')}
                  selectedTime={`${executionData.test_started}`}
                  onValueSubmitted={(d) => {
                    editExecution(testExecutionId, { test_started: moment(d) })
                  }}
                  disabled={executionData.step !== 'in_progress'}
                  inspectorPanel={true}
                />
                <DateTimeInlineInputComponent
                  label={t('completed')}
                  selectedTime={`${executionData?.test_ended}`}
                  onValueSubmitted={(d) => {
                    editExecution(testExecutionId, { test_ended: moment(d) })
                  }}
                  validate={(value) => {
                    if (value) {
                      return moment(value).isAfter(
                        moment(executionData?.test_started),
                      )
                        ? undefined
                        : t('invalid')
                    }
                    return
                  }}
                  disabled={executionData.step !== 'in_progress'}
                  inspectorPanel={true}
                />
                <TextInlineInputCompontent
                  label={t('revision')}
                  value={executionData?.revision}
                  onValueSubmitted={(value) =>
                    editExecution(testExecutionId, { revision: value })
                  }
                  disabled={executionData.step !== 'in_progress'}
                />
                <SelectorInlineInputComponent
                  items={Object.values(TestExecutionStatus).map((e) => ({
                    id: e,
                    name: t(e),
                  }))}
                  label="status"
                  disabled={executionData.step !== 'in_progress'}
                  initialItem={{
                    id: executionData?.status,
                    name: t(executionData?.status ?? ''),
                  }}
                  getItemLabel={(s) => `${t(s?.name ?? '')}`}
                  selectedId={executionData?.status}
                  onValueSubmitted={(id) =>
                    editExecution(testExecutionId, { status: id })
                  }
                  inspectorPanel={true}
                />
                {executionData && (
                  <InlineCompontent
                    label="send_for_approval"
                    disabled={executionData.step !== 'singing'}
                    content={
                      <SendForSigning
                        id={testExecutionId}
                        isTestOwner={isTestOwner}
                        step={executionData?.step ?? false}
                        editExecution={editExecution}
                      />
                    }
                  />
                )}

                <InlineCompontent
                  label="close"
                  content={
                    <CloseTestExecution
                      id={testExecutionId}
                      isTestOwner={isTestOwner}
                      step={executionData.step}
                      editExecution={editExecution}
                    />
                  }
                />
                <DateTimeInlineInputComponent
                  label="closed_date"
                  selectedTime={executionData.closed_date?.toString()}
                  onValueSubmitted={(_) => {
                    /*noop*/
                  }}
                  disabled={true}
                  inspectorPanel={true}
                />
                <TextInlineInputCompontent
                  label={t('comment')}
                  value={executionData.comment}
                  onValueSubmitted={(value) =>
                    editExecution(testExecutionId, { comment: value })
                  }
                  textArea={true}
                  disabled={executionData.step !== 'closed'}
                />
                <DateTimeInlineInputComponent
                  label="created_at"
                  selectedTime={executionData.created_at}
                  onValueSubmitted={(_) => {
                    /*noop*/
                  }}
                  disabled={true}
                  inspectorPanel={true}
                />
                <DateTimeInlineInputComponent
                  label="updated_at"
                  selectedTime={executionData.updated_at}
                  onValueSubmitted={(_) => {
                    /*noop*/
                  }}
                  disabled={true}
                  inspectorPanel={true}
                />
              </InlineComponentsWrapper>
            )}
          </>
        )}
        <InspectorSections sections={sections} />
      </>
    </FixedPane>
  )
}

export default TestExecutionInspectorPanel
