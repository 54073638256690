import moment from 'moment'
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useTranslation } from 'react-i18next'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { milestoneListImportTemplate } from 'src/export-templates/MilestoneImport'
import {
  IDiscipline,
  IImportItemList,
  IMainProcess,
  IMilestone,
  IStatusTypes,
  ISystemTypeGroup,
  ITeam,
  IUserData,
} from 'src/service/OrgTypes'
import { getProjectSystemTypeGroupPerDomain } from 'src/service/SystemTypeGroupService'
import { statusTypes } from 'src/service/SystemValues'
import Button from 'src/ui-elements/button/Button'
import List from 'src/ui-elements/list/List'
import { IListFilter } from 'src/ui-elements/list/ListContextProvider'
import { IListColumns, ISorting } from 'src/ui-elements/list/ListTypes'
import Loader from 'src/ui-elements/loader/Loader'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import { IMetaValue } from '../../document/types/IMetaData'
import { milestoneListExportTemplate } from '../../export-templates/MilestoneExports'
import {
  deleteBulkMilestones,
  deleteMilestone,
  editMilestone,
  getProjectMilestonesPaginated,
} from '../../service/MilestoneService'
import { updateUserDefinedFieldsValue } from '../../service/SystemTypeFieldService'
import {
  getMileStoneErrorMessage,
  StructureValidationError,
} from '../../service/ValidationErrors'
import Modal from '../../ui-elements/modal/Modal'
import { constructFilterJson, IActiveFilter } from '../../utility/utils'
import {
  BaselineColumn,
  ClosedColumn,
  CreatedAtColumn,
  DeadlineColumn,
  DelayColumn,
  DurationColumn,
  KeypointsColumn,
  RecordIdColumn,
  StatusColumn,
  TagColumn,
  TitleColumn,
  UpdatedAtColumn,
  userDefinedColumns,
} from '../TableColumns/Columns'
import {
  ContractColumn,
  DisciplineColumn,
  ResponsibleColumn,
} from '../TableColumns/DisciplineResponsibleColumns'
import {
  MainProcessColumn,
  TeamColumn,
} from '../TableColumns/MainProcessTemaColumns'
import DeleteModal from '../delete-modal/DeleteModal'
import MilestoneForm from './MilestoneForm'
import MilestoneInspectorPanel from './MilestoneInspectorPanel'

interface IMilestoneListProps {
  importMilestone?: boolean
  onOpenItem: (id: number, type: string, parentId?: number) => void
  reloadTree?: () => void
}

const MilestoneList = ({
  importMilestone,
  onOpenItem,
  reloadTree,
}: IMilestoneListProps) => {
  const tempMilestone = {
    id: 0,
    name: '',
    status: 'open',
    description: '',
  } as IMilestone
  const { t } = useTranslation()
  const [milestones, setMilestones] = useState<IMilestone[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [copyMilestone, setCopyMilestone] = useState<IMilestone>(tempMilestone)
  const [selectedMilestone, setSelectedMilestone] = useState<IMilestone>(
    {} as IMilestone,
  )
  const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false)
  const [totalPages, setTotalPages] = useState<number>(0)
  const pageSize = 30
  const [reloadTable, setReloadTable] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedMilestones, setSelectedMilestones] = useState<number[]>([])
  const [openInspectorPanel, setOpenInspection] = useState(false)
  const { addAlert } = useAlert()

  const projectContext = useContext(ProjectContext)
  const projectId = projectContext.state.currentProject.id

  const milestonesRef = useRef(milestones)
  const projectRef = useRef(projectContext.state.currentProject.id)
  const projectUsers = useRef<IUserData[]>([])
  const projectDiscipline = useRef<IDiscipline[]>([])
  const mainProcesses = useRef<IMainProcess[]>([])
  const themes = useRef<ITeam[]>([])
  const [systemTypeGroup, setSystemTypeGroup] = useState<ISystemTypeGroup>()
  const mounted = useRef(false)
  const [milestoneOptionalFieldsReload, setMilestoneOptionalFieldsReload] =
    useState(true)

  useEffect(() => {
    reload()
    setMilestones([])
    setLoading(true)
  }, [projectContext.state])

  const onCloseInpectionPanel = () => {
    setOpenInspection(false)
  }

  const handlePreviewClick = (e: any, data: IMilestone) => {
    e.preventDefault()
    e.stopPropagation()
    setOpenInspection(true)
    setSelectedMilestone(data)
  }

  useEffect(() => {
    projectRef.current = projectId
    milestonesRef.current = milestones
  })

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting
    if (!milestoneOptionalFieldsReload) {
      milestoneFilter(activeFilters, page)
    }
  }

  const milestoneFilter = (activeFilters: IActiveFilter, page: number) => {
    getProjectMilestonesPaginated(
      projectId,
      activeFilters,
      page,
      pageSize,
    ).then((res) => {
      setMilestones(res.mile_stones)
      setTotalPages(res.pages)
      setLoading(false)
    })
  }

  const onCloneItem = (row: any) => {
    const copy = { ...copyMilestone }
    copy.name = `${row.name}-Copy`
    copy.description = row.description
    copy.tag_id = row.tag_id
    copy.tag = row.tag
    setCopyModalOpen(true)
    setCopyMilestone(copy)
  }

  const closeCopyModal = () => {
    setCopyModalOpen(false)
    reload()
    if (reloadTree) {
      reloadTree()
    }
  }

  const reload = () => {
    setReloadTable((n) => !n)
  }

  const importMilestoneTemplate: IImportItemList = {
    title: t('upload_milestones'),
    templateJson: milestoneListImportTemplate,
    type: 'mile_stones',
    reload,
  }

  const onDeleteItemClick = (milestone: IMilestone) => {
    setShowDeleteModal((n) => !n)
    setSelectedMilestone(milestone)
  }

  const openEditModal = () => {
    setShowEditModal((n) => !n)
  }

  const closeEditModal = () => {
    setShowEditModal((n) => !n)
    reload()
    if (reloadTree) {
      reloadTree()
    }
  }

  const showAlert = (type: IAlertType, title: string, text: string) => {
    addAlert({ type, title, description: text })
  }

  const toggleDeleteModal = () => {
    setShowDeleteModal((n) => !n)
  }

  const toggleCreateModal = () => {
    setShowCreateModal((n) => !n)
  }

  const closeCreateModal = () => {
    setShowCreateModal((n) => !n)
    reload()
    if (reloadTree) {
      reloadTree()
    }
  }

  const onDeleteMilestone = () => {
    deleteMilestone(selectedMilestone)
      .then(() => {
        reload()
        if (reloadTree) {
          reloadTree()
        }
      })
      .catch((err) => console.error(err))

    toggleDeleteModal()
  }

  const openMilestone = (row: any) => {
    onOpenItem(row.id, 'milestone')
  }

  const updateResponsible = (
    id: number,
    responsibleId: number,
    disciplineId: number,
  ) => {
    const milestone = {
      id,
      responsible_id: responsibleId,
      discipline_id: disciplineId,
    } as IMilestone
    milestoneUpdate(milestone)
  }

  const updateDiscipline = (
    id: number,
    disciplineId: number,
    responsibleId: number,
  ) => {
    const milestone = {
      id,
      responsible_id: responsibleId,
      discipline_id: disciplineId,
    } as IMilestone
    milestoneUpdate(milestone)
  }

  const updateMainProcess = (
    id: number,
    mainProcessId: number,
    temaId: number,
  ) => {
    const milestone = {
      id,
      main_process_id: mainProcessId,
      team_id: temaId,
    } as IMilestone
    milestoneUpdate(milestone)
  }

  const updateTema = (id: number, temaId: number, mainProcessId: number) => {
    const milestone = {
      id,
      main_process_id: mainProcessId,
      team_id: temaId,
    } as IMilestone
    milestoneUpdate(milestone)
  }

  const milestoneUpdate = (milestone: IMilestone) => {
    editMilestone(milestone).finally(reload)
  }

  const onFieldChange = (field: string, key: number, value: string) => {
    const milestone = { id: key, [field]: value }
    editMilestone(milestone).finally(reload)
  }

  const onDateChange = (date: moment.Moment, key: number) => {
    const dataField = { id: key, deadline: date } as IMilestone
    const projectEndDate = projectContext.state.currentProject.endDate
    if (moment(date).isAfter(moment(projectEndDate), 'day')) {
      showAlert(
        'error',
        t('an_error_occurred'),
        getMileStoneErrorMessage(
          StructureValidationError.PAST_PARENT_DEADLINE,
          t,
        ) +
          ' (' +
          moment(projectEndDate).format('L') +
          ')',
      )
    } else {
      editMilestone(dataField).then(() => {
        reload()
        if (reloadTree) {
          reloadTree()
        }
      })
    }
  }

  const onStatusSelect = (status: IStatusTypes, key: number) => {
    const oldMilestones = [...milestonesRef.current]

    const milestone = oldMilestones.filter((d) => d.id === key).pop()
    if (milestone) {
      if (
        milestone.open_children &&
        milestone.open_children > 0 &&
        status.id === 'done'
      ) {
        showAlert(
          'error',
          t('an_error_occurred'),
          getMileStoneErrorMessage(
            StructureValidationError.HAS_OPEN_CHILDREN_DETAIL,
            t,
          ),
        )
        return
      }

      milestone.status = status.id

      const updateMilestone = {
        id: milestone.id,
        status: status.id,
      }

      editMilestone(updateMilestone as IMilestone).finally(reload)
    }
  }

  const deleteSelectedMilestones = () => {
    deleteBulkMilestones(projectRef.current, selectedMilestones).then(() => {
      addAlert({
        type: 'success',
        title: t('successfully_deleted'),
        description: t('selected_items_deleted', { items: t('milestones') }),
        autoClose: true,
      })
      reload()
      if (reloadTree) {
        reloadTree()
      }
      setSelectedMilestones([])
    })
  }

  const updateMetaFiled = useCallback((data: IMetaValue, _id: number) => {
    if (data.id) {
      updateUserDefinedFieldsValue(data.id, data).then(() => {
        setReloadTable((n) => !n)
      })
    }
  }, [])

  const userDefinedAttributesColumns = useMemo(() => {
    if (!mounted.current) {
      mounted.current = true
      return []
    }
    if (systemTypeGroup) {
      const _col = userDefinedColumns(
        systemTypeGroup.optional_fields,
        updateMetaFiled,
        'meta_data',
      )
      setTimeout(() => setLoading(false), 500)
      return _col
    } else {
      setTimeout(() => setLoading(false), 500)
      return []
    }
  }, [systemTypeGroup, updateMetaFiled])

  const fetchSystemGroupType = useCallback(() => {
    setLoading(true)
    getProjectSystemTypeGroupPerDomain(projectId, 'Milestone').then(
      (res: ISystemTypeGroup[]) => {
        setSystemTypeGroup(res.pop())
        setMilestoneOptionalFieldsReload(false)
      },
    )
  }, [projectId])

  useEffect(() => {
    fetchSystemGroupType()
  }, [fetchSystemGroupType])

  const getColumns = (): IListColumns[] => {
    return [
      RecordIdColumn('150'),
      TitleColumn('name', '300', (key, value) =>
        onFieldChange('name', key, value),
      ),
      MainProcessColumn(projectId, mainProcesses, themes, updateMainProcess),
      TeamColumn(projectId, themes, updateTema),
      TagColumn(projectId, (key, value) => onFieldChange('tag_id', key, value)),
      StatusColumn(
        'status||deadline||expired_children||open_children||behind_schedule_children',
        statusTypes(t),
        onStatusSelect,
      ),
      DeadlineColumn('deadline', 'deadline||baseline', onDateChange),
      ClosedColumn,
      BaselineColumn,
      DurationColumn,
      DelayColumn,
      KeypointsColumn,
      ResponsibleColumn(
        projectId,
        projectUsers,
        projectDiscipline,
        updateResponsible,
      ),
      DisciplineColumn(
        projectId,
        projectDiscipline,
        projectUsers,
        updateDiscipline,
      ),
      ContractColumn(),
      ...userDefinedAttributesColumns,
      CreatedAtColumn(),
      UpdatedAtColumn(),
    ]
  }
  return (
    <>
      <List
        data={milestones}
        actionButton={
          <Button
            type={Button.ButtonType.PRIMARY}
            size={Button.ButtonSize.SMALL}
            onClick={toggleCreateModal}
          >
            {t('new_milestone')}
          </Button>
        }
        exportTemplate={milestoneListExportTemplate}
        tableName={'milestoneList'}
        key={milestoneOptionalFieldsReload ? 1 : 2}
        columns={getColumns()}
        itemsPerPage={pageSize}
        pagination={true}
        filterParent={'milestone'}
        onRowClick={openMilestone}
        isRowSelectable={true}
        onSelectRow={(data) => setSelectedMilestones(data)}
        selectedRows={selectedMilestones}
        bulkDelete={true}
        onBulkDelete={deleteSelectedMilestones}
        getFilteredData={getFilteredData}
        reload={reloadTable}
        totalPages={totalPages}
        sortBackend={true}
        filterResetOption={true}
        importItem={importMilestone ? importMilestoneTemplate : undefined}
        actionMenu={[
          {
            name: t('duplicate'),
            action: onCloneItem,
          },
          {
            name: t('delete'),
            action: onDeleteItemClick,
          },
        ]}
        onPreviewClick={handlePreviewClick}
      />

      <Modal
        show={showCreateModal}
        closeModal={toggleCreateModal}
        title={t('add_milestone')}
        maxWidth={800}
        confirmOutsideClick={true}
      >
        <MilestoneForm
          milestone={tempMilestone}
          projectId={projectId}
          closeModal={closeCreateModal}
          reloadList={reload}
        />
      </Modal>

      <Modal
        show={showEditModal}
        closeModal={openEditModal}
        size={'w-5/6'}
        title={
          t('edit_item', { item: t('milestone') }) + selectedMilestone.record_id
        }
        maxWidth={800}
      >
        <MilestoneForm
          milestone={selectedMilestone}
          projectId={projectId}
          closeModal={closeEditModal}
        />
      </Modal>

      <Modal
        show={copyModalOpen}
        closeModal={closeCopyModal}
        title={t('add_milestone')}
        maxWidth={800}
        confirmOutsideClick={true}
      >
        <MilestoneForm
          milestone={copyMilestone}
          projectId={projectId}
          closeModal={closeCopyModal}
          reloadList={reload}
        />
      </Modal>

      {!isEmpty(selectedMilestone) && (
        <DeleteModal
          onDelete={onDeleteMilestone}
          show={showDeleteModal}
          closeModal={toggleDeleteModal}
          itemIdnType={`${selectedMilestone.record_id} ${t('milestone')}`}
          itemName={`${selectedMilestone.record_id} - ${selectedMilestone.name}`}
        />
      )}

      {openInspectorPanel && selectedMilestone && selectedMilestone.id && (
        <MilestoneInspectorPanel
          origin={{
            name: t('table_overview'),
            url: '/milestone?tabIndex=0',
          }}
          milestoneId={selectedMilestone.id}
          open={openInspectorPanel}
          onClose={onCloseInpectionPanel}
          onUpdate={reload}
        />
      )}

      {loading ? <Loader /> : null}
    </>
  )
}

export default memo(MilestoneList)
