import Attachment from '@icons/attachment.svg'
import Comment from '@icons/comment.svg'
import LegendToggle from '@icons/legend_toggle.svg'
import moment from 'moment'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import MetaDataFieldsForPage from 'src/document/components/DocumentCreateModal/MetaDataFieldsForPage'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import { IMetaValue } from 'src/document/types/IMetaData'
import history from 'src/history'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { editMilestone, getMilestone } from 'src/service/MilestoneService'
import {
  getProjectKeypointsFilteredWithPagination,
  getProjectProcesses,
  IModalOrigin,
  IProjectKeypointResponse,
} from 'src/service/ProcessService'
import { statusTypes } from 'src/service/SystemValues'
import { getProjectTags } from 'src/service/TagService'
import { getMainprocessTeams, getProjectTeams } from 'src/service/TeamService'
import {
  getDisplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import {
  getErrorMessage,
  getMileStoneErrorMessage,
  StructureValidationError,
  ValidationError,
} from 'src/service/ValidationErrors'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { useInlineDependencyUpdate } from 'src/ui-elements/page-display/inline-components/useInlineDependencyUpdate'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'
import { capFirstLetter, classNames } from 'src/utility/utils'
import InspectorSections, {
  IInspectorSection,
} from '../../document/components/Inspector/InspectorSections'
import { IMilestone, ISystemTypeGroup } from '../../service/OrgTypes'
import { getProjectSystemTypeGroupPerDomain } from '../../service/SystemTypeGroupService'
import FixedPane from '../../ui-elements/fixed-pane/FixedPane'
import CopyUrl from '../copy/CopyUrl'
import AttachmentsInspectorSectionTableWithUpload from '../inspector-section-tables/AttachmentsInspectorSectionTableWithUpload'
import KeypointInspectorPanel from '../key-point/KeyPointInspectorPanel'
import QrModal from '../qr/QrModal'
import { getMetaDataValues, loadMetaValues } from '../system/SystemUtil'

interface IMilestoneInspectorPanel {
  milestoneId: number
  open: boolean
  onClose: () => void
  origin: IModalOrigin
  notFound?: (id: number) => void
  onUpdate?: () => void
  defaultIndex?: number
  readonly?: boolean
}

const styleClass = {
  root: classNames('flex', 'flex-col', 'min-h-full'),
}

const MilestoneInspectorPanel: React.FC<IMilestoneInspectorPanel> = ({
  milestoneId,
  open,
  onClose,
  origin,
  notFound,
  onUpdate,
  defaultIndex = 0,
  readonly,
}) => {
  const [milestone, setMilestone] = useState<IMilestone>()
  const [loading, setLoading] = useState<boolean>(false)
  const [keypoints, setKeypoints] = useState<IProjectKeypointResponse[]>([])
  const [hasOpenKeypoint, setHasOpenKeypoint] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedKeypointId, setSelectedKeypointId] = useState<number>(0)

  const { t } = useTranslation()
  const { addChangesToAppendWhenKeyUpdates, getChangesForUpdate } =
    useInlineDependencyUpdate<IMilestone>(setMilestone, milestone)
  const projectContext = useContext(ProjectContext)
  const projectId = projectContext?.state.currentProject.id
  const { isBaselineAdmin } = projectContext.state
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])
  const [systemTypeGroup, setSystemTypeGroup] = useState<
    ISystemTypeGroup | undefined
  >(undefined)
  const { addAlert } = useAlert()

  const [qrString, setQrString] = useState('')
  const [showQrModal, setShowQrModal] = useState(false)

  useEffect(() => {
    getProjectSystemTypeGroupPerDomain(projectId, 'Milestone').then(
      (resp: ISystemTypeGroup[]) => {
        const value = resp.pop()
        setSystemTypeGroup(value)
        loadMilestone(value)
      },
    )
  }, [milestoneId])

  const loadMilestone = (value?: ISystemTypeGroup) => {
    getMilestone(projectId, milestoneId).then(async (milestone) => {
      if (typeof milestone.status === 'number' && notFound) {
        notFound(milestoneId)
      }
      setMilestone(milestone)
      const metaData = getMetaDataValues(milestone.meta_data)
      setHasOpenKeypoint(
        (milestone.open_children && milestone.open_children > 0) || false,
      )
      if (value || systemTypeGroup) {
        setOptionalFields(
          loadMetaValues(
            milestoneId,
            'Milestone',
            value?.optional_fields ?? systemTypeGroup?.optional_fields,
            metaData,
          ),
        )
      }
      setLoading(false)
    })
  }

  const loadKeypoints = () => {
    const activeFilters = {}
    activeFilters['mile_stone'] = []
    activeFilters['mile_stone'].push(milestoneId)

    getProjectKeypointsFilteredWithPagination(
      projectId,
      activeFilters,
      1,
      50,
    ).then((res) => {
      setKeypoints(res.key_points)
    })
  }

  const onKeypointClick = (id: number) => {
    setSelectedKeypointId(id)
    setShowModal(true)
  }

  const getMetaDataContent = (): JSX.Element => (
    <div className={'flex flex-col -ml-4 -mt-2'}>
      {optionalFields.length > 0 && (
        <MetaDataFieldsForPage
          onFieldsUpdate={onMilestoneUpdate}
          fields={optionalFields}
          required={false}
          editMode={false}
          inspectorPanel={true}
        />
      )}
    </div>
  )

  const getRows = (): IInspectorSection[] => [
    {
      name: t('details'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: getMainContent(),
      onClick: loadMilestone,
    },
    {
      name: t('additional_information'),
      icon: Icons.DATABASE_GREY,
      activeIcon: Icons.DATABASE,
      content: getMetaDataContent(),
      onClick: loadMilestone,
      overflowVisible: true,
    },
    {
      name: capFirstLetter(t('keypoints')),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      onClick: loadKeypoints,
      content: (
        <InspectorSectionTable
          headerColumns={[
            t('id'),
            t('title'),
            t('status'),
            t('deadline'),
            t('responsible'),
          ]}
          rowsData={keypoints?.map((keypoint) => {
            return {
              cells: [
                keypoint?.record_id,
                keypoint?.name,
                t(keypoint.status),
                keypoint?.endTime ? moment(keypoint.endTime).format('L') : '',
                keypoint?.responsible
                  ? `${keypoint?.responsible?.firstName} ${keypoint?.responsible?.lastName}`
                  : '',
              ],
              id: keypoint.id,
            }
          })}
          handleClick={onKeypointClick}
        />
      ),
    },
    {
      name: capFirstLetter(t('attachments')),
      icon: <Attachment />,
      content: (
        <AttachmentsInspectorSectionTableWithUpload
          parentId={milestoneId}
          parentType="MileStone"
          readonly={false}
        />
      ),
    },
    {
      name: t('comments'),
      icon: <Comment />,
      content: <Comments parentId={milestoneId} parentType="MileStone" />,
    },
    {
      name: t('change_log'),
      icon: <LegendToggle />,
      content: <ChangeLog parentId={milestoneId} parentType="MileStone" />,
    },
  ]

  const onMilestoneUpdate = () => {
    loadMilestone()
    onUpdate?.()
  }

  const onChangeInput = async (update: Partial<IMilestone>) => {
    try {
      if (milestone?.id) {
        const allUpdates = getChangesForUpdate(update)
        setMilestone({ ...milestone, ...allUpdates })
        await editMilestone({
          ...convertUndefinedToNull(allUpdates),
          id: milestone?.id,
        }).then(() => {
          onMilestoneUpdate()
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const getModalTitle = (): string => {
    return milestone?.record_id
      ? milestone?.record_id + ' - ' + milestone.name
      : t('loading...')
  }

  const onStatusSelect = () => {
    if (!milestone?.update_access) {
      showAlart('error', t('access_limited'), t('do_not_have_access_to_edit'))
      return
    }

    if (milestone.open_children && milestone.open_children > 0) {
      showAlart(
        'error',
        t('an_error_occurred'),
        getMileStoneErrorMessage(
          StructureValidationError.HAS_OPEN_CHILDREN_DETAIL,
          t,
        ),
      )
      return
    }

    const updateMilestone = {
      id: milestone.id,
      status: 'done',
    }

    editMilestone(updateMilestone as IMilestone).then(() => {
      loadMilestone()
    })
  }

  const showAlart = (
    type: IAlertType,
    alertTitle: string,
    description: string,
  ) => {
    addAlert({ type, title: alertTitle, description })
  }

  const generateQrCode = () => {
    const url = window.location.href.split('?')[0]
    const qrString =
      url +
      '?modal=milestone&id=' +
      milestone?.id +
      '&project=' +
      milestone?.project_id
    setQrString(qrString)
  }

  const toggleQrModal = () => {
    generateQrCode()
    setShowQrModal(true)
  }

  const additionalButtons = () => {
    return (
      <div className="flex flex-row justify-end items-center">
        {milestone && milestone.status && milestone.status !== 'done' && (
          <Button
            type={ButtonType.SUCCESS}
            size={Button.ButtonSize.SMALL}
            onClick={onStatusSelect}
          >
            {t('done')}
          </Button>
        )}

        <span
          className={
            'mx-2 w-8 h-8 p-0.5 flex justify-center items-center border-gray-300 border rounded-full hover:cursor-pointer'
          }
        >
          <CopyUrl
            params={{
              modal: 'milestone',
              id: milestone?.id ?? '',
            }}
          />
        </span>
        <span
          className={
            'mx-2 w-8 h-8 p-0.5 flex justify-center items-center border-gray-300 border rounded-full hover:cursor-pointer'
          }
        >
          <a title={t('generate_qr_code')} className="flex items-center">
            <Icon
              style={{ width: 20, height: 20 }}
              icon={Icon.IconType.QR}
              onClick={toggleQrModal}
            />
          </a>
        </span>
        <Link
          to={`/main-process/milestone/${milestoneId}?rootUrl=${origin?.url}&rootName=${origin?.name}&showHome=${!!origin?.showHome}${addProjectToUrl()}`}
        >
          <Button size={Button.ButtonSize.SMALL}>
            {t('view_or_plan_keypoints')}
          </Button>
        </Link>
      </div>
    )
  }

  const addProjectToUrl = (): string => {
    const params = new URLSearchParams(history.location.search)
    if (params.has('project')) {
      return `&project=${params.get('project')}`
    }
    return ''
  }

  const getMainContent = () => {
    return (
      <div className="-mt-2 -ml-6">
        <InlineComponentsWrapper
          loading={loading}
          padding="left"
          border={undefined}
          inputWidth="w-[480px]"
        >
          <TextInlineInputCompontent
            label={t('title')}
            value={milestone?.name}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ name: newValue })
            }}
            validate={(value) => {
              if (value === undefined || value === '')
                return getErrorMessage(ValidationError.MISSING_TITLE, t)
              return
            }}
            disabled={readonly}
          />
          <SelectorInlineInputComponent
            items={statusTypes(t)}
            label={t('status')}
            getItemLabel={(stat) => stat?.name}
            initialItem={{
              id: milestone?.status ?? '',
              name:
                statusTypes(t).find(
                  (statData) => statData.id === milestone?.status,
                )?.name ?? '',
            }}
            validate={(value) => {
              if (value === undefined)
                return getErrorMessage(ValidationError.MISSING_STATUS, t)
              if (hasOpenKeypoint && value === 'done') {
                return getMileStoneErrorMessage(
                  StructureValidationError.HAS_OPEN_CHILDREN,
                  t,
                )
              }
              return
            }}
            selectedId={milestone?.status}
            onValueSubmitted={(stat) => {
              onChangeInput({ status: stat })
            }}
            inspectorPanel={true}
            disabled={readonly}
          />
          <DateTimeInlineInputComponent
            label={t('deadline')}
            selectedTime={`${milestone?.deadline}`}
            onValueSubmitted={(deadline) => {
              onChangeInput({ deadline: moment(deadline) })
            }}
            validate={(value) => {
              if (!value || !moment(value).isValid()) {
                return getErrorMessage(ValidationError.MISSING_DEADLINE, t)
              }
              return
            }}
            inspectorPanel={true}
            disabled={readonly}
          />
          <DateTimeInlineInputComponent
            label="closed_date"
            selectedTime={`${milestone?.closed_date}`}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={true}
          />
          <DateTimeInlineInputComponent
            label={t('baseline_date')}
            selectedTime={`${milestone?.baseline}`}
            onValueSubmitted={(baseline) => {
              onChangeInput({ baseline: moment(baseline) })
            }}
            validate={(value) => {
              if (!value || !moment(value).isValid()) {
                return getErrorMessage(ValidationError.MISSING_DEADLINE, t)
              }
              return
            }}
            disabled={!isBaselineAdmin}
            inspectorPanel={true}
          />
          <TextInlineInputCompontent
            label={t('duration_days')}
            value={`${milestone?.duration}`}
            onValueSubmitted={(newValue) => {
              if (newValue)
                onChangeInput({ duration: parseInt(`${newValue}`, 10) })
            }}
            validate={(newValue) => {
              if (newValue?.length && isNaN(+newValue)) {
                return t('must_be_a_number')
              }
              return undefined
            }}
            disabled={readonly}
          />
          <TextInlineInputCompontent
            label={t('delay_days')}
            disabled={true}
            value={`${milestone?.delay ?? 0}`}
          />
          <TextInlineInputCompontent
            label={t('keypoints')}
            disabled={true}
            value={`${milestone?.done_children || 0}/${
              (milestone?.open_children || 0) + (milestone?.done_children || 0)
            }`}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectDisciplines(projectId)}
            label="discipline"
            initialItem={milestone?.discipline}
            getItemLabel={(discipline) =>
              `${discipline?.shortName} - ${discipline?.name}`
            }
            validate={(value) => {
              if (value === undefined)
                return t('fill_out_w_param', {
                  param: t('discipline'),
                })
              return
            }}
            selectedId={milestone?.discipline_id}
            onValueSubmitted={(discipline_id) => {
              addChangesToAppendWhenKeyUpdates('responsible_id', {
                discipline_id,
              })
            }}
            inspectorPanel={true}
            disabled={readonly}
          />
          <SelectorInlineInputComponent
            getItems={() =>
              milestone?.discipline_id
                ? getDisplineUsers(milestone?.discipline_id)
                : getProjectUsersWithDisciplines(projectId)
            }
            label="responsible"
            getItemLabel={(responsible) =>
              `${responsible?.firstName} ${responsible?.lastName}`
            }
            initialItem={milestone?.responsible}
            validate={(value) => {
              if (value === undefined)
                return t('fill_out_w_param', {
                  param: t('responsible'),
                })
              return
            }}
            selectedId={milestone?.responsible_id}
            onValueSubmitted={(responsible_id) => {
              onChangeInput({ responsible_id })
            }}
            dependencies={[milestone?.discipline_id]}
            inspectorPanel={true}
            disabled={readonly}
          />
          <SelectorInlineInputComponent
            label={'contract'}
            disabled={true}
            selectedId={milestone?.contract_id ?? ''}
            getItemLabel={(contract) =>
              `${contract?.contractNumber} - ${contract?.contractName}`
            }
            initialItem={milestone?.contract}
            inspectorPanel={true}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectProcesses(projectId)}
            label={t('main_process')}
            getItemLabel={(process) =>
              `${process?.record_id} - ${process?.name}`
            }
            initialItem={milestone?.main_process}
            validate={(value) => {
              if (!value || parseInt(`${value}`, 10) === 0) {
                return getErrorMessage(ValidationError.MISSING_MAIN_PROCESS, t)
              }
              return
            }}
            selectedId={milestone?.main_process_id}
            onValueSubmitted={(val) => {
              onChangeInput({ main_process_id: val, team_id: undefined })
            }}
            inspectorPanel={true}
            disabled={readonly}
          />
          <SelectorInlineInputComponent
            getItems={() =>
              milestone?.main_process_id
                ? getMainprocessTeams(milestone?.main_process_id)
                : getProjectTeams(projectId)
            }
            label={t('team')}
            getItemLabel={(team) => team?.name}
            initialItem={milestone?.team}
            selectedId={milestone?.team_id ?? 0}
            onValueSubmitted={(val) => {
              onChangeInput({ team_id: val })
            }}
            cancelButton={true}
            dependencies={[milestone?.main_process_id]}
            inspectorPanel={true}
            disabled={readonly}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectTags(projectId)}
            label={t('type')}
            getItemLabel={(tag) => tag?.name}
            initialItem={milestone?.tag}
            selectedId={milestone?.tag_id ?? 0}
            onValueSubmitted={(val) => {
              onChangeInput({ tag_id: val })
            }}
            cancelButton={true}
            inspectorPanel={true}
            disabled={readonly}
          />
          <TextInlineInputCompontent
            label={t('description')}
            textArea={true}
            value={milestone?.description}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ description: newValue })
            }}
            disabled={readonly}
          />
          <DateTimeInlineInputComponent
            label="created_at"
            selectedTime={`${milestone?.created_at}`}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={true}
          />
          <DateTimeInlineInputComponent
            label="updated_at"
            selectedTime={`${milestone?.updated_at}`}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={true}
          />
        </InlineComponentsWrapper>
      </div>
    )
  }

  return (
    <>
      <FixedPane
        title={
          milestone?.name
            ? `${milestone?.record_id} - ${milestone?.name}`
            : t('loading...')
        }
        show={open}
        onClose={onClose}
        className={'w-[700px]'}
        disableOutsideClose={true}
        detailPageData={{
          key: DetailPageKeys.MILESTONE,
          ids: { milestoneId: milestoneId },
        }}
      >
        <div className={styleClass.root}>
          {!readonly && additionalButtons()}
          <InspectorSections defaultIndex={defaultIndex} sections={getRows()} />
          {showQrModal && (
            <QrModal
              show={showQrModal}
              close={() => setShowQrModal(false)}
              title={getModalTitle()}
              value={qrString}
            />
          )}
        </div>
      </FixedPane>
      {showModal && selectedKeypointId && (
        <KeypointInspectorPanel
          readonly={readonly}
          keyPointId={selectedKeypointId}
          open={showModal}
          onClose={() => {
            setShowModal(false)
            setSelectedKeypointId(0)
          }}
          origin={origin}
          onUpdate={onUpdate}
        />
      )}
    </>
  )
}
export default MilestoneInspectorPanel
