import { isEqual } from 'lodash'
import * as React from 'react'
import { useRef } from 'react'
import {
  BASE_METADATA_TYPES,
  metadataTimeTypes,
} from 'src/document/types/FolderMetadataTypes'
import { IMetaData } from 'src/document/types/IMetaData'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import { IDropdown } from 'src/ui-elements/page-display/inline-components/IDropDown'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import MultiSelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/MultiSelectorInlineInputComponent'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import Checkbox from '../../../../components/switchHoc/CheckBox'
import Input from '../../../../ui-elements/input/Input'

interface IDefaultData {
  value: string
  label: string
}

interface IFolderMetadataDefault {
  field: IMetaData
  updateMetadata: (id: string, fieldUpdate: Partial<IMetaData>) => void
  enumValues: IDefaultData[]
  disabled: boolean
}

const FolderMetadataDefault: React.FC<IFolderMetadataDefault> = ({
  field,
  updateMetadata,
  enumValues,
  disabled,
}) => {
  const { t } = useMultipleKeysTranslation()
  const parentRef = useRef(null)

  const TextDefault = (
    <Input
      label={t('default_value')}
      placeholder={t('type_field_name_and_default')}
      type={'text'}
      block={true}
      value={typeof field.default_value === 'string' ? field.default_value : ''}
      onChange={(e) =>
        updateMetadata(field.id, { ...field, default_value: e.target.value })
      }
      disabled={disabled}
    />
  )

  const onEnumChange = (value?: string) => {
    updateMetadata(field.id, {
      ...field,
      default_value: value,
      pattern: enumValues.map((value) => value.value),
    })
  }

  const onMultipleEnumChange = (values: string[]) => {
    updateMetadata(field.id, {
      ...field,
      default_value_list: values,
      pattern: enumValues.map((value) => value.value),
    })
  }

  const renderMetadataDefault = (data_type: BASE_METADATA_TYPES) => {
    switch (data_type) {
      case 'string':
        return TextDefault
      case 'boolean':
        return (
          <div className="flex items-center">
            <p className="ml-2 text-sm mr-2">{t('default_value')}</p>
            <Checkbox
              disable={disabled}
              valueProp={field.default_value === 'true'}
              onChange={(default_value) =>
                updateMetadata(field.id, {
                  ...field,
                  default_value: default_value ? 'true' : 'false',
                })
              }
            />
          </div>
        )
      case 'time':
        return (
          <div ref={parentRef} className="flex flex-col ml-3">
            <DateTimeInlineInputComponent
              key={field.id}
              label={t('default_value')}
              selectedTime={`${field.default_value ?? ''}`}
              disabled={disabled}
              includeTime={isEqual(
                field.pattern,
                metadataTimeTypes['dateTime'].pattern,
              )}
              inspectorPanel={false}
              onValueSubmitted={(value) =>
                updateMetadata(field.id, { default_value: value })
              }
              cancelButton
            />
          </div>
        )
      case 'enum':
        return (
          <InlineComponentsWrapper padding={'pl-2 pt-3'} inputWidth={'w-56'}>
            <SelectorInlineInputComponent<string, IDropdown<string>>
              label={t('default_value')}
              inspectorPanel={false}
              items={enumValues.map((value) => ({
                ...value,
                id: value.value,
                name: value.value,
              }))}
              getItemLabel={(item) => `${item?.label}`}
              cancelButton
              selectedId={field.default_value?.toString()}
              initialItem={
                field.default_value
                  ? {
                      id: `${field.default_value}`,
                      label: `${field.default_value}`,
                      name: `${field.default_value}`,
                    }
                  : undefined
              }
              onValueSubmitted={onEnumChange}
            />
          </InlineComponentsWrapper>
        )
      case 'multi_enum':
        return (
          <InlineComponentsWrapper padding={'pl-2 pt-2'} inputWidth={'w-56'}>
            <MultiSelectorInlineInputComponent<string, IDropdown<string>>
              label={t('default_value')}
              items={enumValues.map((value) => ({
                ...value,
                id: value.value,
                name: value.value,
              }))}
              getItemLabel={(item) => `${item?.label}`}
              cancelButton
              selectedIds={field.default_value_list ?? []}
              initialItems={field.default_value_list?.map((value) => ({
                id: value,
                label: value,
                name: value,
              }))}
              onValueSubmitted={onMultipleEnumChange}
            />
          </InlineComponentsWrapper>
        )
      default:
        return TextDefault
    }
  }

  return <>{renderMetadataDefault(field.data_type)}</>
}

export default FolderMetadataDefault
