export const testWorkGroupExportTemplate = {
  geniusApiRequest: {
    url: '{organizationApiBaseUrl}projects/{projectId}/test_work_groups/export',
    method: 'POST',
  },
  name: 'test_work_group_Export',
  displayName: 'Test work Group',
  runInBg: true,
  columns: [
    'test_work_group_id',
    'title',
    'description',
    'location',
    'status',
    'priority',
    'percent_completed',
    'discipline',
    'contract',
    'planned_start',
    'planned_end',
    'actual_start',
    'actual_end',
    'created_by',
    'updated_by',
    'created_at',
    'updated_at',
  ],
  rows: [
    'record_id',
    'title',
    'description',
    'location',
    'test_work_group_status.name',
    'priority',
    'percent_completed',
    'discipline.shortName||discipline.name',
    'contract.contractNumber||contract.contractName',
    'planned_start',
    'planned_end',
    'actual_start',
    'actual_end',
    'created_by.firstName||created_by.lastName',
    'updated_by.firstName||updated_by.lastName',
    'created_at',
    'updated_at',
  ],
}
