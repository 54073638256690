import { Column } from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { ImportTemplate } from 'src/components/upload-item/ImportHelpers'
import {
  ExportData,
  ExportResponse,
  ExportTask,
  ExportTemplate,
  QrExportTemplate,
} from 'src/context/ExportContext/ExportTypes'
import { IListColumns } from 'src/ui-elements/list/ListTypes'
import { getFrontendBaseUrl } from 'src/utility/utils'
import BaseExportService, {
  cc,
  ExportFilter,
  MeetingExport,
} from './ExportBaseService'
import { IDocument, IEmail, ITestExecution } from './OrgTypes'

class ExportService extends BaseExportService {
  private readonly DocxType = {
    extension: 'docx',
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  }

  private readonly PdfType = {
    extension: 'pdf',
    type: 'application/pdf',
  }

  private readonly XlsxType = {
    extension: 'xlsx',
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  }

  private getContentType = (extension: string) => {
    switch (extension) {
      case 'docx':
        return this.DocxType.type
      case 'pdf':
        return this.PdfType.type
      case 'xlsx':
        return this.XlsxType.type
      default:
        return ''
    }
  }

  constructor() {
    super()
  }

  public exportToExcelNew = (
    t: TFunction<'translation', undefined>,
    template: ExportData,
    selectedColumns: Column<unknown>[],
    filter: Record<string, string | number | number[] | string[]>,
  ): Promise<ExportResponse> => {
    this.replaceUrl(template)
    const dataFields = selectedColumns.reduce(
      (fields: { columns: string[]; rows: string[] }, column) => {
        if (!column.columnDef.meta) return fields
        fields.columns.push(column.columnDef.meta.name)
        fields.rows.push(column.columnDef.meta.field)
        return fields
      },
      { columns: [], rows: [] },
    )
    return this.ExportFile(
      {
        name: template.name,
        displayName: t(template.name),
        runInBg: template.runInBg,
        geniusApiRequest: { ...template.geniusApiRequest, body: filter },
        rows: dataFields.rows,
        columns: dataFields.columns,
      },
      'excel',
    )
  }

  public exportToExcel = (
    t: TFunction<'translation', undefined>,
    rawTemplate: ExportTemplate,
    selectedColumns: IListColumns[],
    exportFilter: ExportFilter,
  ): Promise<ExportResponse> => {
    const { parentId } = exportFilter
    const template = { ...rawTemplate }
    this.replaceUrl(template)
    if (
      parentId &&
      !Array.isArray(parentId) &&
      this.projectId &&
      template.geniusRiskGroupDataRequest &&
      template.geniusMatrixApiRequest
    ) {
      template.geniusMatrixApiRequest.url =
        template.geniusMatrixApiRequest.url.replace(
          '{organizationApiBaseUrl}',
          this.orgUrl(),
        )
      template.geniusMatrixApiRequest.url =
        template.geniusMatrixApiRequest.url.replace(
          '{projectId}',
          this.projectId,
        )
      template.geniusRiskGroupDataRequest.url =
        template.geniusRiskGroupDataRequest.url.replace(
          '{riskGroupId}',
          `${parentId}`,
        )
      template.geniusApiRequest.url = template.geniusApiRequest.url.replace(
        '{riskGroupId}',
        `${parentId}`,
      )
      template.geniusRiskGroupDataRequest.url =
        template.geniusRiskGroupDataRequest.url.replace(
          '{organizationApiBaseUrl}',
          this.orgUrl(),
        )
    }

    const preparedTemplate = this.prepareTemplate(t, template, selectedColumns)

    if (exportFilter.filter) {
      preparedTemplate.geniusApiRequest.body = this.buildFilter(exportFilter)
    }

    return this.ExportFile(preparedTemplate, 'excel')
  }

  public prepareTemplate = (
    t: TFunction<'translation', undefined>,
    template: ExportTemplate,
    selectedColumns?: IListColumns[],
  ) => {
    const selectedTemplate =
      template.ignoreListColumns || !selectedColumns
        ? template.columns.map((c: string) => ({ name: c, id: c }))
        : selectedColumns.filter((value: IListColumns) =>
            template.columns.find((col: string) =>
              this.checkColumn(value, col),
            ),
          )

    const tempColumn = [...template.columns]
    const tempRow = [...template.rows]
    template.rows = []
    selectedTemplate.map((column: cc) => {
      const index = tempColumn.findIndex((tempCol: string) =>
        this.checkColumn(column, tempCol),
      )
      if (index > -1) {
        template.rows.push(tempRow[index])
      }
    })

    template.columns = selectedTemplate.map((value) =>
      this.capFirst(t(value.name)),
    )
    // remove duplicate columns
    template.columns = template.columns.filter(
      (value, index, self) => self.indexOf(value) === index,
    )
    template.displayName = this.sp(
      t(template?.name?.toLowerCase() || template.displayName),
    )
    template.hiddenColumns = template.hiddenColumns?.map(
      (hiddenColumn: string) => this.capFirst(t(hiddenColumn)),
    )

    return template
  }

  public exportQrDocx = (
    template: QrExportTemplate,
    projectLogo: string,
    exportFilter: ExportFilter,
  ): Promise<ExportResponse> => {
    const url = window.location.href.split('?')[0]
    this.replaceUrl(template)
    template.qrUrlTemplate = template.qrUrlTemplate.replace(
      '{projectId}',
      this.projectId ?? '',
    )
    template.qrUrlTemplate = template.qrUrlTemplate.replace('{currentUrl}', url)
    template.qrUrlTemplate = template.qrUrlTemplate.replace(
      '{baseUrl}',
      getFrontendBaseUrl(),
    )
    template.projectLogo = projectLogo

    if (exportFilter.filter) {
      template.geniusApiRequest.body = this.buildFilter(exportFilter)
    }

    return this.ExportFile(template, 'docx/downloadQrCodes')
  }

  public exportImportTemplate = (template: ImportTemplate) => {
    return this.ExportAndDownloadFile(template, 'excel/template', this.XlsxType)
  }

  public meetingToDocx = (data: MeetingExport): Promise<ExportResponse> => {
    const template = this.prepareMeeting(data)
    return this.ExportFile(template, 'docx')
  }

  public meetingToPdf = (data: MeetingExport): Promise<ExportResponse> => {
    const template = this.prepareMeeting(data)
    return this.ExportFile(template, 'pdf')
  }

  public exportTestReport = (
    template: ExportTemplate,
    testExecution?: ITestExecution,
  ) => {
    if (!testExecution) return
    this.replaceUrl(template)
    template.geniusApiRequest.url = template.geniusApiRequest.url.replace(
      '{executionId}',
      `${testExecution.id}`,
    )
    template.displayName = `${testExecution.test?.record_id} - ${testExecution.test?.name}`
    return this.ExportFile(template, 'pdf/testReport')
  }

  public exportPDFHighlight = (
    template: ExportTemplate,
    fileContainer: {
      fileId: string
      fileName: string
    },
  ): Promise<ExportResponse> => {
    const { fileId, fileName } = fileContainer
    this.replaceUrl(template)
    template.geniusApiRequest.url = template.geniusApiRequest.url.replace(
      '{fileContainerId}',
      fileId,
    )
    template.displayName = fileName
    return this.ExportFile(template, 'pdf/highlight')
  }

  public archiveMeeting = (
    data: MeetingExport,
    message: IEmail,
  ): Promise<ExportResponse> => {
    const template = this.prepareMeeting(data)
    template.maaSApiRequest.body = message
    return this.ExportFile(template, 'pdf/meetingUpload')
  }

  public emailMeeting = (
    data: MeetingExport,
    message: IEmail,
  ): Promise<ExportResponse> => {
    const template = this.prepareMeeting(data)
    template.maaSApiRequest.body = message
    return this.ExportFile(template, 'pdf/email')
  }

  public downloadSingleFile = (document: IDocument) => {
    const type =
      document.contentType === 'application/pdf' ? this.PdfType : this.DocxType
    return this.downloadFile(document.fileUrl, type, document.created_at)
  }

  public getUserExportTasks = (): Promise<ExportTask[]> => {
    return this.ExportTasksApi('event', 'GET')
  }

  public markAllExportTasksRead = (): Promise<ExportTask[]> => {
    return this.ExportTasksApi('event/readAll', 'GET')
  }

  public downloadExportFile = (
    url: string,
    fileName: string,
    extension: string,
  ) => {
    this.downloadGeneric(url, fileName, this.getContentType(extension))
  }
}

export default new ExportService()
