import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TaskForm from 'src/components/task/TaskForm'
import TaskInspectorPanel from 'src/components/task/TaskInspectorPanel'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import { useImprovementTasks } from 'src/query/improvements'
import { ITaskData } from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'

interface ImprovementTasksSectionTableProps {
  improvementId: number
  projectId: number
}

const ImprovementTasksSectionTable = ({
  improvementId,
  projectId,
}: ImprovementTasksSectionTableProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [selectedTaskId, setSelectedTaskId] = useState<number>()
  const [showCreateNewTaskModal, setShowCreateNewKeyTaskModal] = useState(false)

  const { data: tasks, isLoading: tasksLoading } =
    useImprovementTasks(improvementId)

  const reload = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['improvement/tasks', improvementId],
    })
  }, [queryClient, improvementId])

  return (
    <div>
      <Button
        type={Button.ButtonType.PRIMARY}
        size={Button.ButtonSize.SMALL}
        onClick={() => setShowCreateNewKeyTaskModal(true)}
        margin="mb-2"
      >
        {t('new_action')}
      </Button>
      <InspectorSectionTable
        headerColumns={[
          t('id'),
          t('title'),
          t('status'),
          t('deadline'),
          t('task_type'),
        ]}
        rowsData={(tasks || [])?.map(
          ({ id, title, status, deadline, task_type }) => ({
            cells: [
              id,
              title,
              t(status),
              moment(deadline).format('L'),
              t(task_type?.taskTypeName || ''),
            ],
            id,
          }),
        )}
        loading={tasksLoading}
        handleClick={setSelectedTaskId}
      />
      {selectedTaskId && (
        <TaskInspectorPanel
          taskId={selectedTaskId}
          open={selectedTaskId !== undefined}
          onClose={() => setSelectedTaskId(undefined)}
          projectId={projectId}
          onUpdate={reload}
        />
      )}
      {showCreateNewTaskModal && (
        <TaskForm
          editingMode={false}
          task={
            {
              parent_id: projectId,
              parent_type: 'Project',
              taskType: 'Aksjon',
              deadline: moment(),
              status: 'open',
              responsible_id: 0,
              discipline_id: 0,
            } as ITaskData
          }
          closeModal={() => setShowCreateNewKeyTaskModal(false)}
          projectId={projectId}
          improvementId={improvementId}
          onTaskCreated={reload}
        />
      )}
    </div>
  )
}

export default ImprovementTasksSectionTable
