import * as React from 'react'
import FolderMetadataRow from '../../../document/components/Folders/CreateFolderModal/FolderMetadataRow'
import { IMetaData } from '../../../document/types/IMetaData'
import { IMetaField } from '../system-type-fields/SystemTypeFieldsForm'

interface ISystemTypeFieldGroupProps {
  fields: IMetaField[]
}

const SystemTypeFieldsGroup: React.FC<ISystemTypeFieldGroupProps> = ({
  fields,
}) => {
  return (
    <div className="flex flex-col gap-2">
      {fields.map((field) => (
        <div key={field.id}>
          {field.id && (
            <FolderMetadataRow
              field={field as unknown as IMetaData}
              disabled={true}
              updateMetadata={() => {}}
            />
          )}
        </div>
      ))}
    </div>
  )
}

export default SystemTypeFieldsGroup
