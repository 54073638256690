import { ColumnDef } from '@tanstack/react-table'
import { t } from 'i18next'
import { capitalize } from 'lodash'
import moment, { Moment } from 'moment/moment'
import React from 'react'
import {
  DisciplineAndResponsibleColumn,
  ResponsibleAndDisciplineColumn,
} from 'src/components/TableColumns/DisciplineResponsibleColumns'
import { MainProcessAndThemeColumn } from 'src/components/TableColumns/MainProcessTemaColumns'
import useProjectId from 'src/components/hooks/useProjectId'
import { IStatusCell } from 'src/components/status-dropdown/TableStatusLabel'
import { getFilteredProjectImprovementsWithPagination } from 'src/service/ImprovementsService'
import {
  IDelivery,
  IDiscipline,
  IMainProcess,
  IStatusTypes,
  ITeam,
  IUserData,
} from 'src/service/OrgTypes'
import { getProjectKeypoints } from 'src/service/ProcessService'
import { statusTypes } from 'src/service/SystemValues'
import { getProjectTags } from 'src/service/TagService'
import {
  getDeliveryErrorMessage,
  StructureValidationError,
} from 'src/service/ValidationErrors'
import {
  dateColumn,
  editableDateColumn,
  editableTextColumn,
  multiFilterOptionsColumn,
  multiFilterOptionsColumnEditable,
  numberColumn,
  statusColumn,
  taskCountColumn,
  textColumn,
  userColumn,
} from 'src/ui-elements/Table/Columns'
import useListHelper from 'src/ui-elements/list/UseListHelper'
import useAlert from 'src/ui-elements/toast/useAlert'

export function useDeliveryColumns(
  saveDelivery: (delivery: Partial<IDelivery>, id: number) => void,
  updateDelivery: (delivery: IDelivery) => void,
  projectUsers: React.MutableRefObject<IUserData[]>,
  projectDiscipline: React.MutableRefObject<IDiscipline[]>,
  mainProcesses: React.MutableRefObject<IMainProcess[]>,
  themes: React.MutableRefObject<ITeam[]>,
  onDateChange: (date: string, delivery: IDelivery) => void,
) {
  const {
    getContractFilter,
    getMilestoneFilter,
    getKeypointFilter,
    getTagFilter,
    getImprovementFilter,
    getReporterFilter,
    getThemeFilter,
  } = useListHelper()

  const projectId = useProjectId()
  const { addAlert } = useAlert()

  const showAlert = (text: string) => {
    addAlert({
      type: 'error',
      title: t('something_went_wrong'),
      description: text,
    })
  }

  const onStatusSelect = (
    status: IStatusTypes,
    key: number,
    delivery: IStatusCell,
  ) => {
    if (
      delivery.open_children &&
      delivery.open_children > 0 &&
      status.id === 'done'
    ) {
      showAlert(
        getDeliveryErrorMessage(
          StructureValidationError.HAS_OPEN_CHILDREN_DETAIL,
          t,
        ),
      )
      return
    }

    saveDelivery({ status: status.id }, key)
  }

  const validateDateChange = (delivery: IDelivery, date?: Moment): string => {
    if (!date) return ''
    const backInTime = moment(date).isBefore(delivery.endTime, 'day')
    if (backInTime) return ''
    if (
      delivery.key_point &&
      moment(delivery.key_point.endTime).isBefore(date, 'day')
    ) {
      showAlert(
        getDeliveryErrorMessage(
          StructureValidationError.PAST_PARENT_DEADLINE,
          t,
          moment(delivery.key_point.endTime).format('L'),
        ),
      )
      return 'past_keypoint_deadline'
    }
    if (
      delivery.improvement &&
      moment(delivery.improvement.deadline).isBefore(date, 'day')
    ) {
      showAlert(
        getDeliveryErrorMessage(
          StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT,
          t,
          moment(delivery.improvement.deadline).format('L'),
        ),
      )
      return 'past_improvement_deadline'
    }
    return ''
  }

  const onFieldChange = (field: string, key: number, value: string) => {
    const delivery = { id: key, [field]: value }
    saveDelivery(delivery, key)
  }

  const updateResponsible = (
    id: number,
    responsibleId: number,
    disciplineId: number,
  ) => {
    const delivery = {
      id,
      responsible_id: responsibleId,
      discipline_id: disciplineId,
    } as IDelivery
    updateDelivery(delivery)
  }

  const updateDiscipline = (
    id: number,
    disciplineId: number,
    responsibleId: number,
  ) => {
    const delivery = {
      id,
      responsible_id: responsibleId,
      discipline_id: disciplineId,
    } as IDelivery
    updateDelivery(delivery)
  }

  const updateMainProcess = (
    id: number,
    mainProcessId: number,
    teamId: number,
  ) => {
    const delivery = {
      id,
      main_process_id: mainProcessId,
      team_id: teamId,
    } as IDelivery
    updateDelivery(delivery)
  }

  return [
    textColumn('record_id', { name: t('id') }),
    editableTextColumn('name', { name: capitalize(t('title')) }, (key, value) =>
      onFieldChange('name', +key, value),
    ),
    dateColumn('created_at', { name: t('created_at') }),
    dateColumn('updated_at', { name: t('updated_at') }),
    editableDateColumn(
      'endTime',
      { name: capitalize(t('deadline')) },
      onDateChange,
      validateDateChange,
    ),
    dateColumn('baseline', { name: t('baseline_date') }),
    dateColumn('closed_date', { name: t('closed_date') }),
    multiFilterOptionsColumn(
      'contract',
      { name: t('contract'), getFilter: getContractFilter },
      ['contractNumber', 'contractName'],
    ),
    multiFilterOptionsColumn(
      'mile_stone',
      { name: t('milestone'), getFilter: getMilestoneFilter },
      ['record_id', 'name'],
    ),
    multiFilterOptionsColumnEditable(
      'key_point',
      { name: t('key_point'), getFilter: getKeypointFilter },
      ['record_id', 'name'],
      () => getProjectKeypoints(projectId),
      (key, value) => onFieldChange('key_point_id', key, value),
    ),
    multiFilterOptionsColumnEditable(
      'tag',
      { name: t('type'), getFilter: getTagFilter },
      ['name'],
      () => getProjectTags(projectId),
      (key, value) => onFieldChange('tag_id', key, value),
    ),
    multiFilterOptionsColumnEditable(
      'improvement',
      { name: t('improvement'), getFilter: getImprovementFilter },
      ['title'],
      () =>
        getFilteredProjectImprovementsWithPagination(projectId).then(
          (res) => res.items,
        ),
      (key, value) => onFieldChange('improvement_id', key, value),
    ),
    multiFilterOptionsColumn(
      'team',
      { name: t('team'), getFilter: getThemeFilter },
      ['name'],
    ),
    statusColumn(
      'status',
      ['status', 'endTime', 'expired_children', 'open_children'],
      { name: t('status') },
      statusTypes(t),
      onStatusSelect,
    ),
    userColumn('user', { name: t('reporter'), getFilter: getReporterFilter }),
    ResponsibleAndDisciplineColumn(
      projectId,
      projectUsers,
      projectDiscipline,
      updateResponsible,
    ),
    DisciplineAndResponsibleColumn(
      projectId,
      projectDiscipline,
      projectUsers,
      updateDiscipline,
    ),
    MainProcessAndThemeColumn(
      projectId,
      mainProcesses,
      themes,
      updateMainProcess,
    ),
    numberColumn('delay', { name: t('delay_days') }),
    numberColumn('duration', { name: t('duration_days') }),
    taskCountColumn({ name: t('tasks') }),
  ] as ColumnDef<IDelivery>[]
}
