export const testSystemGroupExportTemplate = {
  geniusApiRequest: {
    url: '{organizationApiBaseUrl}projects/{projectId}/test_system_groups/export',
    method: 'POST',
  },
  name: 'test_system_Export',
  displayName: 'Test Systems Group',
  runInBg: true,
  columns: [
    'test_object_group_id',
    'title',
    'description',
    'status',
    'percent_completed',
    'test_work_group',
    'responsible',
    'discipline',
    'contract',
    'planned_start',
    'planned_end',
    'actual_start',
    'actual_end',
    'created_by',
    'updated_by',
    'created_at',
    'updated_at',
  ],
  rows: [
    'record_id',
    'title',
    'description',
    'test_system_group_status.name',
    'percent_completed',
    'test_work_group.record_id||test_work_group.title',
    'responsible.firstName||responsible.lastName',
    'discipline.shortName||discipline.name',
    'contract.contractNumber||contract.contractName',
    'planned_start',
    'planned_end',
    'actual_start',
    'actual_end',
    'created_by.firstName||created_by.lastName',
    'updated_by.firstName||updated_by.lastName',
    'created_at',
    'updated_at',
  ],
}
