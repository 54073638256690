import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import {
  getDomainSystemTypeGroupUserDefinedFields,
  getProjectSystemSettingSystemTypeGroup,
} from '../../service/SystemTypeGroupService'

export const systemMetaData = 'system-meta-data'
export const useSystemMetaData = (systemSettingId?: number) => {
  const projectId = useProjectId()

  const key = systemSettingId ? 'SyntaxSystems' : 'AllSystem'
  return useQuery({
    queryKey: [systemMetaData, projectId, systemSettingId, key],
    queryFn: () =>
      systemSettingId
        ? getProjectSystemSettingSystemTypeGroup(projectId, systemSettingId)
        : getDomainSystemTypeGroupUserDefinedFields(projectId, 'System'),
  })
}
