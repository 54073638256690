import { FileStatusEnum } from 'src/document/types/IFileContainer'
import { StatusEnum } from 'src/utility/statusEnums'

export enum BadgeSize {
  SMALL = 'small',
  DEFAULT = 'default',
  LARGE = 'large',
}

export const BadgeSizeMapper = {
  [BadgeSize.SMALL]: 'text-xs px-3 leading-4',
  [BadgeSize.DEFAULT]: 'text-sm px-3 leading-4',
  [BadgeSize.LARGE]: 'text-md px-3 leading-5',
}

export enum BadgeColor {
  DEFAULT = 'gray',
  RED = 'red',
  ORANGE = 'orange',
  YELLOW = 'yellow',
  GREEN = 'green',
  TEAL = 'teal',
  BLUE = 'blue',
  INDIGO = 'indigo',
  PURPLE = 'purple',
  PINK = 'pink',
}

export const BadgeColorMapper = (colorStrength: string = '100') => ({
  [BadgeColor.DEFAULT]: `bg-gray-${colorStrength} text-gray-800`,
  [BadgeColor.RED]: `bg-red-${colorStrength} text-red-800`,
  [BadgeColor.ORANGE]: `bg-orange-${colorStrength} text-orange-800`,
  [BadgeColor.YELLOW]: `bg-yellow-${colorStrength} text-yellow-800`,
  [BadgeColor.GREEN]: `bg-green-${colorStrength} text-green-900`,
  [BadgeColor.TEAL]: `bg-teal-${colorStrength} text-teal-800`,
  [BadgeColor.BLUE]: `bg-blue-${colorStrength} text-blue-800`,
  [BadgeColor.INDIGO]: `bg-indigo-${colorStrength} text-indigo-800`,
  [BadgeColor.PURPLE]: `bg-purple-${colorStrength} text-purple-800`,
  [BadgeColor.PINK]: `bg-pink-${colorStrength} text-pink-800`,
})

export const StatusColorMapper = {
  [StatusEnum.DONE]: BadgeColorMapper()[BadgeColor.GREEN],
  [StatusEnum.IN_PROGRESS]: BadgeColorMapper()[BadgeColor.BLUE],
  [StatusEnum.OPEN]: BadgeColorMapper()[BadgeColor.YELLOW],
  [StatusEnum.NOT_RELEVANT]: BadgeColorMapper()[BadgeColor.DEFAULT],
  [StatusEnum.ERROR]: BadgeColorMapper()[BadgeColor.RED],
}

export const FileStatusColorMapper = {
  [FileStatusEnum.IN_PROGRESS]: BadgeColor.BLUE,
  [FileStatusEnum.APPROVED]: BadgeColor.GREEN,
  [FileStatusEnum.DECLINED]: BadgeColor.RED,
  [FileStatusEnum.NOT_CHECKED]: BadgeColor.DEFAULT,
}

export enum BadgeRoundedness {
  DEFAULT = 'full',
  SMALL = 'small',
  NONE = 'none',
}

export const BadgeRoundednessMapper = {
  [BadgeRoundedness.DEFAULT]: 'rounded-full',
  [`${BadgeRoundedness.SMALL}-${BadgeSize.LARGE}`]: 'rounded-md',
  [BadgeRoundedness.SMALL]: 'rounded',
  [BadgeRoundedness.NONE]: 'rounded-none',
}
