import Close from '@icons/close.svg'
import { Button } from '@tremor/react'
import { capitalize } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { DateRangePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import {
  BarColors,
  barSize,
  cartesianDashArray,
  cartesianGridStroke,
  graphMargin,
  KPIFilterStyles,
  legendStyle,
  maxBarWidth,
  NotifyNoDisplay,
  toolTipStyle,
} from 'src/components/KPI/KPIUtils'
import { FloatingFilterstyleClass } from 'src/components/canvas-filter/CanvasFloatingFilter'
import FilterIcon from 'src/components/canvas-header/FilterIcon'
import HelperIcons from 'src/components/canvas-header/HelperIcons'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import {
  InformationComponent,
  InformationTitleComponent,
} from 'src/components/information-component'
import MultiSelector from 'src/components/multi-selector/MultiSelector'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { IBarData, IDiscipline } from 'src/service/OrgTypes'
import {
  IFilter,
  IPlannedTestWorkGroupsSummary,
  plannedTestWorkGroupsSummary,
} from 'src/service/TestWorkGroupService'
import Loader from 'src/ui-elements/loader/Loader'
import { renderDayContents } from 'src/utility/Utility'
import { monthNames } from '../../../utility/dateUtils'

export type IPlannedTWGSummary =
  IPlannedTestWorkGroupsSummary[0][0]['status_count'] & {
    monthAndYear: string
    year: number
    month: number
  }

interface ITestWorkGroupGraphProps {
  projectId: number
  updateFilter: (
    dateFilter: Pick<IPlannedTWGSummary, 'year' | 'month'>,
    selectedDisciplines: number[],
  ) => void
}

interface IFilterDates {
  earliest: moment.Moment
  latest: moment.Moment
}

const TestWorkGroupGraph: React.FC<ITestWorkGroupGraphProps> = ({
  projectId,
  updateFilter,
}: ITestWorkGroupGraphProps) => {
  const styleClass = FloatingFilterstyleClass()

  const getOneYearFromToday = () => {
    return {
      earliest: moment().startOf('month'),
      latest: moment().add(1, 'year').endOf('month'),
    }
  }

  const { t } = useTranslation()
  const [plannedTWGSummary, setPlannedTWGSummary] = useState<
    IPlannedTWGSummary[]
  >([])
  const [loading, setLoading] = useState(false)
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [selectedDisciplines, setSelectedDisciplines] = useState<number[]>([])
  const [disciplineLoading, setDisciplineLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [filtersApplied, setFiltersApplied] = useState(false)
  const [plannedEnd, setPlannedEnd] = useState<IFilterDates>(() =>
    getOneYearFromToday(),
  )
  const [fInput, setFInput] = useState<'startDate' | 'endDate' | null>(null)
  const [isDisciplineFirst, setIsDisciplineFirst] = useState(true)

  const barDataKeys: IBarData[] = [
    {
      dataKey: 'done',
      name: capitalize(t('done')),
      stackId: 'a',
      fill: BarColors.DONE,
    },
    {
      dataKey: 'open',
      name: capitalize(t('open')),
      stackId: 'a',
      fill: BarColors.OPEN,
    },
    {
      dataKey: 'in_progress',
      name: capitalize(t('in_progress')),
      stackId: 'a',
      fill: BarColors.IN_PROGRESS,
    },
    {
      dataKey: 'not_relevant',
      name: capitalize(t('not_relevant')),
      stackId: 'a',
      fill: BarColors.NOT_RELEVANT,
    },
    {
      dataKey: 'not_set',
      name: capitalize(t('not_set')),
      stackId: 'a',
      fill: BarColors.NOT_SET,
    },
    {
      dataKey: 'error',
      name: capitalize(t('error')),
      stackId: 'a',
      fill: BarColors.ERROR,
    },
  ]

  const testWorkGroupFilter = useCallback(() => {
    if (!Object.values(plannedEnd).every((v) => !!v)) return

    setLoading(true)

    const activeFilters: IFilter = {
      planned_end: [
        plannedEnd.earliest.startOf('month'),
        plannedEnd.latest.endOf('month'),
      ],
      discipline: selectedDisciplines,
    }

    plannedTestWorkGroupsSummary(projectId, activeFilters)
      .then((res) =>
        Object.keys(res)
          .map((year) =>
            Object.keys(res[year]).map((month) => {
              return {
                monthAndYear: `${capitalize(t(monthNames[month]))} '${year.slice(2)}`,
                month,
                year,
                ...res[year][month].status_count,
              }
            }),
          )
          .flat(),
      )
      .then((summary: IPlannedTWGSummary[]) => {
        setPlannedTWGSummary(summary)
        setLoading(false)
      })
  }, [plannedEnd, selectedDisciplines, projectId, t])

  useEffect(() => {
    testWorkGroupFilter()
  }, [testWorkGroupFilter])

  const onClear = () => {
    setSelectedDisciplines([])
    setFiltersApplied(false)
    setPlannedEnd(getOneYearFromToday())
  }

  const onOpenDiscipline = () => {
    return new Promise<void>(async (resolve) => {
      if (isDisciplineFirst) {
        setDisciplineLoading(true)
        const allDisciplines = await getProjectDisciplines(projectId)
        setDisciplines(allDisciplines)
        setDisciplineLoading(false)
        setIsDisciplineFirst(false)
      }
      resolve()
    })
  }

  const onChangeDiscipline = async (selectedIds: number[]) => {
    setSelectedDisciplines([...selectedIds])
    setFiltersApplied(true)
  }

  const onChangeDate = (range: {
    startDate: moment.Moment
    endDate: moment.Moment
  }) => {
    const { startDate, endDate } = range
    setPlannedEnd({ earliest: startDate, latest: endDate })
    setFiltersApplied(true)
  }

  const updateListView = ({ month, year }: IPlannedTWGSummary) =>
    updateFilter({ month, year }, selectedDisciplines)

  return (
    <InformationComponent fullWidth={false} noMargin>
      <InformationTitleComponent
        title={t('monthly_summary_based_on_planned_date')}
      />
      <CloseClickOutside onClose={() => setOpen(false)}>
        <HelperIcons className="top-16 right-5 z-50">
          <>
            <FilterIcon
              open={open}
              setOpen={setOpen}
              filtersApplied={filtersApplied}
            />
            {open && (
              <div className={styleClass.filter}>
                <div className={styleClass.rootFilter}>
                  <div className={'pl-5 pt-4 pb-5'}>
                    <div className={`${styleClass.filterSelector}`}>
                      <p className={KPIFilterStyles.filtersRow.label}>
                        {capitalize(t('date_range'))}
                      </p>
                      <DateRangePicker
                        firstDayOfWeek={1}
                        startDate={plannedEnd.earliest}
                        startDateId="date-start"
                        endDate={plannedEnd.latest}
                        endDateId="date-end"
                        onDatesChange={onChangeDate}
                        renderDayContents={renderDayContents}
                        focusedInput={fInput}
                        onFocusChange={setFInput}
                        block
                        displayFormat={() =>
                          // eslint-disable-next-line import/no-named-as-default-member
                          moment.localeData('no').postformat('MMMM YY')
                        }
                        hideKeyboardShortcutsPanel
                        showDefaultInputIcon
                        isOutsideRange={() => false}
                      />
                    </div>
                    {selectedDisciplines && (
                      <div className={styleClass.filterSelector}>
                        <MultiSelector
                          items={disciplines}
                          onOpenList={onOpenDiscipline}
                          label={t('disciplines')}
                          hidelabel={false}
                          dataFields={['shortName', 'name']}
                          selectedItems={selectedDisciplines}
                          fontWeight={'bold'}
                          onSelect={onChangeDiscipline}
                          loading={disciplineLoading}
                          scroll
                          noBorder
                          bgColor={'white'}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={'w-full flex justify-end px-4 py-2 bg-gray-100'}
                  >
                    <Button onClick={onClear}>
                      <div className="flex items-center space-x-1">
                        <Close fill="#999999" />
                        <p>{capitalize(t('reset'))}</p>
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        </HelperIcons>
      </CloseClickOutside>
      {loading || plannedTWGSummary.length === 0 ? (
        <div className={'h-[360px]'}>
          <Loader centerLoader />
        </div>
      ) : (
        <ResponsiveContainer width="100%" minHeight={360} height={360}>
          {plannedTWGSummary.length > 0 ? (
            <ComposedChart data={plannedTWGSummary} margin={graphMargin}>
              <XAxis dataKey="monthAndYear" tickLine={false} />
              <YAxis width={30} tickLine={false} allowDecimals={false} />
              <Tooltip formatter={toolTipStyle.noDescimalTrailingZeroes} />
              <Legend wrapperStyle={legendStyle.wrapper} />
              <CartesianGrid
                stroke={cartesianGridStroke}
                strokeDasharray={cartesianDashArray}
              />
              {barDataKeys.map((data, i) => (
                <Bar
                  key={`barData-${i}`}
                  maxBarSize={maxBarWidth}
                  barSize={barSize}
                  stackId={data.stackId}
                  name={data.name}
                  dataKey={data.dataKey}
                  fill={data.fill}
                  onClick={updateListView}
                />
              ))}
            </ComposedChart>
          ) : (
            <NotifyNoDisplay />
          )}
        </ResponsiveContainer>
      )}
    </InformationComponent>
  )
}

export default TestWorkGroupGraph
