import moment from 'moment'
import {
  memo,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  ClosedColumn,
  ControlAreaColumn,
  CreatedAtColumn,
  DeadlineColumn,
  DurationColumn,
  ImprovementColumn,
  MainprocessColumn,
  RecordIdColumn,
  ReporterColumn,
  RoomColumn,
  sourceColumn,
  SystemColumn,
  TagColumn,
  TaskTypeColumn,
  TitleColumn,
  UpdatedAtColumn,
  userDefinedColumns,
} from 'src/components/TableColumns/Columns'
import {
  ContractColumn,
  DisciplineColumn,
  ResponsibleColumn,
} from 'src/components/TableColumns/DisciplineResponsibleColumns'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import { statusPieShowTypes } from 'src/components/issue-kpi/ResolvedOverDueGraphs'
import TableStatusLabel, {
  IStatusCell,
} from 'src/components/status-dropdown/TableStatusLabel'
import SwitchHOC from 'src/components/switchHoc/switchHoc'

import MeetingTaskForm from 'src/components/task/MeetingTaskForm'
import TaskForm from 'src/components/task/TaskForm'
import TaskInspectorPanel from 'src/components/task/TaskInspectorPanel'
import TaskTypeTaskForm from 'src/components/task/TaskTypeTaskForm'
import TaskTypeInspector from 'src/components/task/TaskTypeTaskInspector'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { IMetaValue } from 'src/document/types/IMetaData'
import { actionListExportTemplate } from 'src/export-templates/ActionExports'
import { actionImportTemplate } from 'src/export-templates/ActionImportTemplate'
import TestSendInvitation from 'src/page/systematic-completion/tests/TestSendInvitation'
import {
  IDiscipline,
  IImportItemList,
  IListFilterFromParent,
  IStatusTypes,
  ISystemTypeGroup,
  ITask,
  ITaskData,
  IUserData,
  ITaskType,
} from 'src/service/OrgTypes'
import { updateUserDefinedFieldsValue } from 'src/service/SystemTypeFieldService'
import { getProjectSystemTypeGroup } from 'src/service/SystemTypeGroupService'
import { actionStatus } from 'src/service/SystemValues'
import {
  deleteBulkTasks,
  deleteTask,
  editTask,
  getFilterTasks,
} from 'src/service/TaskService'
import { sendInviteToTestParticipant } from 'src/service/TestService'
import {
  getTaskErrorMessage,
  StructureValidationError,
} from 'src/service/ValidationErrors'
import Button from 'src/ui-elements/button/Button'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import List from 'src/ui-elements/list/List'
import {
  IListFilter,
  ResourceType,
} from 'src/ui-elements/list/ListContextProvider'
import {
  filterType,
  IListActionMenuItem,
  IListColumns,
  ISorting,
} from 'src/ui-elements/list/ListTypes'
import Loader from 'src/ui-elements/loader/Loader'
import Modal from 'src/ui-elements/modal/Modal'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import useAlert from 'src/ui-elements/toast/useAlert'
import {
  addMetaDataInfo,
  addUserDefinedInfoToImport,
} from 'src/utility/exportUtils'
import { constructFilterJson, IActiveFilter } from 'src/utility/utils'

interface IActionsListProps {
  projectId: number
  leaderId?: number
  parentType?: string
  parentId?: number
  parentIds?: number[]
  lastAllowedDeadline?: moment.Moment
  inModal?: boolean
  parentDone?: boolean
  isExpandedElement?: boolean
  tableName?: string
  improvementId?: number
  updateParentList?: () => void
  importActions?: boolean
  taskTypeTree?: boolean
  taskType?: ITaskType
  taskTypeStatFilters?: {
    date: moment.Moment[]
    discipline: number[]
    show: statusPieShowTypes
    task_type: number[]
  }
  onOpenItem?: (taskId: number, type: string, taskTypeId?: number) => void
  reloadTree?: () => void
  module?: 'planning'
}

const ActionsList = ({
  projectId,
  tableName,
  parentType,
  parentId,
  parentIds,
  lastAllowedDeadline,
  parentDone,
  isExpandedElement,
  improvementId,
  importActions,
  updateParentList,
  taskTypeTree,
  taskType,
  taskTypeStatFilters,
  onOpenItem,
  leaderId,
  reloadTree,
  module,
}: IActionsListProps) => {
  const projectContext = useContext(ProjectContext)

  const tempTask = {
    parent_id: parentId ? parentId : projectId,
    parent_type: parentType ? parentType : 'Project',
    taskType: 'Aksjon',
    deadline: moment(),
    status: 'open',
    responsible_id: 0,
    discipline_id: 0,
  } as ITaskData

  const userContext = useContext(UserContext)
  const userId = userContext.state.user.id

  const [tasks, setTasks] = useState<ITask[]>([])
  const [selectedTask, setSelectedTask] = useState<ITaskData>({} as ITaskData)
  const [loading, setLoading] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [copyTask, setCopyTask] = useState<ITaskData>(tempTask)
  const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false)
  const [totalPages, setTotalPages] = useState<number>(0)
  const pageSize = 30

  const [reloadTable, setReloadTable] = useState<boolean>(false)
  const [selectedTasks, setSelectedTasks] = useState<number[]>([])
  const [showSuggestion, setShowSuggestion] = useState<boolean>(false)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [tasksSelected, setTasksSelected] = useState<ITask[]>()
  const [openInspectorPanel, setOpenInspection] = useState(false)
  const [selectedTaskId, setSelectedTaskId] = useState<number | undefined>(
    undefined,
  )
  const [systemTypeGroup, setSystemTypeGroup] = useState<ISystemTypeGroup>()

  const { addAlert } = useAlert()
  const tasksRef = useRef(tasks)
  const projectUsers = useRef<IUserData[]>([])
  const projectDiscipline = useRef<IDiscipline[]>([])

  const filterFromParent: IListFilterFromParent[] = useMemo(() => {
    const filter = []
    if (improvementId) {
      filter.push({
        id: 'improvement',
        filed: 'improvement',
        value: [improvementId],
      })
    }

    if (taskType) {
      filter.push({
        id: 'task_type',
        filed: 'task_type',
        value: [taskType.id],
      })
    }

    return filter
  }, [improvementId, taskType])

  const { t } = useTranslation()

  const onCloneItem = (row: ITaskData) => {
    const task = copyTask
    task.title = `${row.title}-Copy`
    task.discipline_id = row.discipline_id
    task.responsible_id = row.responsible_id
    task.contract_id = row.contract_id
    task.description = row.description
    task.parent_id = row.parent_id
    task.parent_type = row.parent_type
    task.tag_id = row.tag_id
    task.tag = row.tag
    setCopyTask(task)
    setCopyModalOpen(true)
  }

  const reload = () => {
    setReloadTable((n) => !n)
  }

  useEffect(() => {
    tasksRef.current = tasks
  })

  useEffect(() => {
    reload()
  }, [JSON.stringify(taskTypeStatFilters), taskType])

  useEffect(() => {
    setTasksSelected(tasks.filter((each) => selectedTasks.includes(each.id)))
  }, [selectedTasks, tasks])

  const importActionTemplate: IImportItemList = {
    title: t('upload_actions'),
    templateJson: addUserDefinedInfoToImport(
      actionImportTemplate,
      [],
      taskType?.optional_fields ?? [],
    ),
    type: 'tasks',
    reload,
  }

  const onShowTaskModal = (row: ITaskData) => {
    if (onOpenItem && (row.task_type_id || !taskTypeTree)) {
      onOpenItem(row.id, 'task', row.task_type_id ?? undefined)
    } else {
      setOpenInspection(!openInspectorPanel)
      setSelectedTask(row)
    }
  }

  const onDeleteItemClick = (row: ITaskData) => {
    setShowDeleteModal(!showDeleteModal)
    setSelectedTask(row)
  }

  const closeCopyModal = () => {
    setCopyModalOpen(false)
    reload()
    if (reloadTree) {
      reloadTree()
    }
    if (updateParentList && isExpandedElement) {
      updateParentList()
    }
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
    reload()
    if (reloadTree) {
      reloadTree()
    }
    if (updateParentList && isExpandedElement) {
      updateParentList()
    }
  }

  const openCreateModal = () => {
    setShowCreateModal(!showCreateModal)
  }

  const closeCreateModal = () => {
    setShowCreateModal(!showCreateModal)
    reload()
    if (reloadTree) {
      reloadTree()
    }
    if (updateParentList) {
      updateParentList()
    }
  }

  const removeTask = () => {
    deleteTask(selectedTask)
      .then(() => {
        setShowDeleteModal(!showDeleteModal)
        reload()
        if (reloadTree) {
          reloadTree()
        }
        if (updateParentList && isExpandedElement) {
          updateParentList()
        }
      })
      .catch((err) => console.error(err))
  }

  const onStatusSelect = (status: IStatusTypes, key: number) => {
    const dataField: Partial<ITask> = { id: key }
    if (status.id === 'done') {
      dataField.closed_date = moment()
    } else {
      if (dataField.status === 'done') {
        dataField.closed_date = null
      }
    }
    dataField.status = status.id
    dataField.id = key
    editTask(dataField).then(() => {
      reload()
      if (reloadTree) {
        reloadTree()
      }
      if (updateParentList) {
        updateParentList()
      }
    })
  }

  const saveTask = (value: ITask) => {
    editTask(value).then(() => {
      reload()
      if (reloadTree) {
        reloadTree()
      }
    })
  }

  const showAlert = (text: string) => {
    addAlert({
      type: 'error',
      title: t('an_error_occurred'),
      description: text,
    })
  }

  const onFieldChange = (field: string, key: number, value: string) => {
    const taskList = [...tasksRef.current]
    const task = taskList.find((d) => d.id === key)
    if (task) {
      task[field] = value
      saveTask(task)
    }
  }

  const getActionButton = () => {
    return parentType !== 'KeyPoint' &&
      !parentDone &&
      parentIds === undefined &&
      (!taskTypeStatFilters || taskTypeStatFilters?.show === 'all') ? (
      isExpandedElement ? (
        <Icon
          icon={Icons.PLUS}
          className={'py-1 text-center w-6'}
          onClick={openCreateModal}
        />
      ) : (
        <>
          <Button
            onClick={openCreateModal}
            type={Button.ButtonType.BOUNDARY_WHITE}
          >
            {tableName === 'TaskActionTable'
              ? t('new_task_original')
              : t(taskTypeTree ? 'new_case' : 'new_action')}
          </Button>
          <Button
            onClick={() => {
              selectedTasks.length > 0
                ? setConfirmationModal(true)
                : setConfirmationModal(false)
            }}
            type={Button.ButtonType.BOUNDARY_WHITE}
            disabled={selectedTasks.length === 0}
          >
            {t('send_reminder')}
          </Button>
        </>
      )
    ) : undefined
  }

  const onDateChange = (date: moment.Moment, key: number) => {
    const oldTasks = [...tasksRef.current]

    const task = oldTasks.filter((d) => d.id === key).pop()
    if (
      task &&
      task.parent &&
      ![
        'CheckInParameter',
        'ChecklistParameter',
        'ConstructionLocomotive',
        'ControlArea',
      ].includes(task.parent_type)
    ) {
      if (task.parent.deadline || task.parent.endTime || task.parent.endDate) {
        const deadline = moment(
          task.parent.deadline || task.parent.endTime || task.parent.endDate,
        )
        if (deadline.isBefore(date, 'day')) {
          showAlert(
            `${getTaskErrorMessage(
              StructureValidationError.PAST_PARENT_DEADLINE_TASK,
              t,
            )} ( ${moment(deadline).format('L')} )`,
          )
          return
        }
      }
      const projectEndDate = projectContext.state.currentProject.endDate
      if (moment(date).isAfter(moment(projectEndDate), 'day')) {
        showAlert(
          `${getTaskErrorMessage(
            StructureValidationError.PAST_PROJECT_DEADLINE,
            t,
          )} ( ${moment(projectEndDate).format('L')} )`,
        )
        return
      }
    }

    if (task && task.improvement && task.improvement.deadline) {
      if (moment(task.improvement.deadline).isBefore(date, 'day')) {
        showAlert(
          `${getTaskErrorMessage(
            StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT,
            t,
          )} ( ${moment(task.improvement.deadline).format('L')} )`,
        )
        return
      }
    }
    editTask({ id: key, deadline: date } as ITask).then(() => {
      reload()
      if (reloadTree) {
        reloadTree()
      }
      if (updateParentList && isExpandedElement) {
        updateParentList()
      }
    })
  }

  const updateDiscipline = (
    id: number,
    disciplineId: number,
    responsibleId: number,
  ) => {
    const dataField = {
      id,
      discipline_id: disciplineId,
      responsible_id: responsibleId,
    } as ITask
    saveTask(dataField)
  }

  const updateUser = (
    id: number,
    responsibleId: number,
    disciplineId: number,
  ) => {
    const dataField = {
      id,
      discipline_id: disciplineId,
      responsible_id: responsibleId,
    } as ITask
    saveTask(dataField)
  }

  const taskFilter = (activeFilters: IActiveFilter) => {
    if (parentId) {
      activeFilters['parent_id'] = []
      activeFilters['parent_id'].push(parentId)
    }

    if (parentIds) {
      activeFilters['parent_id'] = parentIds
    }

    if (parentType) {
      activeFilters['parent_type'] = []
      activeFilters['parent_type'].push(parentType)
    }

    if (taskType) {
      activeFilters['task_type'] = [taskType.id]
    } else if (tableName === 'TaskTypesDetailPageTable') {
      activeFilters['task_type'] = ['null']
    }

    if (leaderId && showSuggestion) {
      activeFilters['suggested'] = showSuggestion
    }

    if (parentType === 'Delivery') {
      activeFilters['parent_type'] = undefined
      activeFilters['parent_id'] = undefined
      activeFilters['delivery'] = []
      activeFilters['delivery'].push(parentId)
    }

    if (improvementId) {
      activeFilters['improvement'] = []
      activeFilters['improvement'].push(improvementId)
    }

    if (activeFilters['parent_type']?.includes('Meeting')) {
      activeFilters['parent_type'].push('Topic')
    }

    if (activeFilters['parent_type']?.includes('ControlArea')) {
      activeFilters['parent_type'].push(
        'ChecklistParameter',
        'CheckInParameter',
        'ConstructionLocomotive',
      )
    }

    if (taskTypeStatFilters) {
      activeFilters['discipline'] = [...taskTypeStatFilters.discipline]

      activeFilters['created_at'] = [
        moment(taskTypeStatFilters.date[0]).utc(),
        moment(taskTypeStatFilters.date[1]).utc(),
      ]

      if (
        taskTypeStatFilters.show === 'overdue' ||
        taskTypeStatFilters.show === 'open'
      ) {
        activeFilters['status'] = ['open', 'in_progress']
        if (taskTypeStatFilters.show === 'overdue')
          activeFilters['deadline'] = [
            new Date(0),
            moment().subtract(1, 'day').utc(),
          ]
        else activeFilters['deadline'] = [moment(), new Date(Math.max())]
      }
      if (taskTypeStatFilters.show === 'resolved') {
        activeFilters['status'] = ['done']
      }
      if (taskTypeStatFilters.task_type[0] === -1) {
        activeFilters['task_type'] = ['null']
      }
    }
    return activeFilters
  }

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    setLoading(true)
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting
    const newFilter = taskFilter(activeFilters)

    getFilterTasks(projectId, newFilter, page, pageSize, 'Aksjon').then(
      (res) => {
        setTasks(res.tasks)
        setTotalPages(res.pages)
        setLoading(false)
      },
    )
  }

  const deleteSelectedTasks = () => {
    deleteBulkTasks(projectId, selectedTasks).then(() => {
      addAlert({
        type: 'success',
        title: t('successfully_deleted'),
        description: t('selected_action_is_deleted'),
        autoClose: true,
      })
      reload()
      if (reloadTree) {
        reloadTree()
      }
      setSelectedTasks([])
    })
  }

  const updateMetaFiled = (data: IMetaValue) => {
    if (data.id) {
      updateUserDefinedFieldsValue(data.id, data).then(() => {
        reload()
      })
    }
  }

  const userDefinedAttributesColumns = useMemo(() => {
    if (systemTypeGroup && !taskType) {
      return userDefinedColumns(
        systemTypeGroup.optional_fields,
        updateMetaFiled,
        'meta_data',
      )
    } else if (taskType?.optional_fields) {
      return userDefinedColumns(
        taskType.optional_fields,
        updateMetaFiled,
        'meta_data',
      )
    } else {
      return []
    }
  }, [systemTypeGroup, taskType])

  useEffect(() => {
    getProjectSystemTypeGroup(projectId).then((res: ISystemTypeGroup[]) => {
      const value = res.find((item) => item.domain === 'Task')
      setSystemTypeGroup(value)
    })
  }, [projectId])

  const getStatusFilter = useCallback(() => {
    const showOpenAndInprogress = taskTypeStatFilters?.show !== 'resolved'
    const showDone = !!(
      taskTypeStatFilters && taskTypeStatFilters?.show !== 'overdue'
    )
    return [
      {
        name: 'open',
        value: 'open',
        active: showOpenAndInprogress,
      },
      {
        name: 'in_progress',
        value: 'in_progress',
        active: showOpenAndInprogress,
      },
      {
        name: 'done',
        value: 'done',
        active: showDone,
      },
    ]
  }, [taskTypeStatFilters])

  const getColumns = (): IListColumns[] => {
    const col: IListColumns[] = [
      RecordIdColumn('200'),
      TitleColumn('title', '480', (key, value) =>
        onFieldChange('title', key, value),
      ),
      MainprocessColumn(
        projectId,
        (key, value) => onFieldChange('main_process_id', key, value),
        taskTypeTree,
      ),
      TaskTypeColumn(projectId, (key, value) =>
        onFieldChange('task_type_id', key, value),
      ),
      SystemColumn(
        projectId,
        (key, value) => onFieldChange('system_id', key, value),
        !taskTypeTree,
      ),
      {
        name: 'status',
        size: '200',
        id: 'status',
        sortingField: 'status',
        dataField: 'status||deadline',
        filterType: filterType.DEFAULT,
        filter: getStatusFilter(),
        cell: (cell: IStatusCell, key: number) => (
          <TableStatusLabel
            statusTypes={actionStatus(t)}
            cell={cell}
            rowId={key}
            onStatusSelect={onStatusSelect}
          />
        ),
      },
      DeadlineColumn('deadline', 'deadline||parent||parent_type', onDateChange),
      DurationColumn,
      ClosedColumn,
      ResponsibleColumn(projectId, projectUsers, projectDiscipline, updateUser),
      DisciplineColumn(
        projectId,
        projectDiscipline,
        projectUsers,
        updateDiscipline,
      ),
      ContractColumn(),
      ImprovementColumn(
        projectId,
        (key, value) => onFieldChange('improvement_id', key, value),
        taskTypeTree,
      ),
      ControlAreaColumn(
        projectId,
        (key, value) => onFieldChange('control_area_id', key, value),
        taskTypeTree,
      ),
      RoomColumn(
        projectId,
        (key, value) => onFieldChange('location_id', key, value),
        taskTypeTree,
      ),
      TagColumn(
        projectId,
        (key, value) => onFieldChange('tag_id', key, value),
        taskTypeTree,
      ),
      ReporterColumn('reporter'),
      ...userDefinedAttributesColumns,
      CreatedAtColumn(),
      UpdatedAtColumn(),
    ]

    if (parentType === undefined) {
      col.splice(col.length - 2, 0, sourceColumn(t))
    }

    return col
  }

  const reloadSuggestion = () => {
    setShowSuggestion((n) => !n)
    reload()
  }

  const approveItem = async (row: ITask) => {
    const tas: ITask = {
      ...row,
      approved: true,
    } as ITask
    await editTask(tas)
    reload()
  }

  const actionItem = () => {
    const ai: IListActionMenuItem[] = [
      {
        name: t('delete'),
        action: onDeleteItemClick,
      },
      {
        name: t('duplicate'),
        action: onCloneItem,
      },
    ]
    if (
      (parentType === 'Topic' || parentType === 'Meeting') &&
      leaderId === userId &&
      showSuggestion
    ) {
      ai.push({
        name: t('approve_item'),
        action: approveItem,
        icon: 'check_circle_outline',
      })
    }

    return ai
  }

  const handlePreviewClick = (e: MouseEvent, data: ITask) => {
    e.preventDefault()
    e.stopPropagation()
    setOpenInspection(true)
    if (data.id) {
      setSelectedTaskId(data.id)
    }
  }

  const onCloseInspectionPanel = () => {
    setOpenInspection(false)
  }

  const title = (
    <div>
      <div className="m-4 flex items-center ml-2">
        <SwitchHOC
          valueProp={showSuggestion}
          className="custom-className"
          onChange={reloadSuggestion}
          defaultColor={false}
        />
        <label onClick={reloadSuggestion} className={'mr-2 ml-2 text-sm'}>
          {t('suggestion')}
        </label>
      </div>
    </div>
  )

  return (
    <>
      <List
        isExpandedElement={isExpandedElement}
        actionButton={getActionButton()}
        exportTemplate={addMetaDataInfo(
          actionListExportTemplate(),
          taskType?.optional_fields ?? [],
        )}
        columns={getColumns()}
        title={
          (parentType === 'Topic' || parentType === 'Meeting') && leaderId
            ? title
            : undefined
        }
        data={tasks}
        tableName={tableName ? tableName : 'actionsList'}
        itemsPerPage={pageSize}
        pagination={true}
        resourceType={ResourceType.TASK}
        onRowClick={onShowTaskModal}
        filterParent={'task'}
        getFilteredData={getFilteredData}
        reload={reloadTable}
        totalPages={totalPages}
        parent_type={parentType}
        isRowSelectable={true}
        onSelectRow={(data) => setSelectedTasks(data)}
        selectedRows={selectedTasks}
        bulkDelete={true}
        onBulkDelete={deleteSelectedTasks}
        parentId={parentId || parentIds}
        sortBackend={true}
        filterResetOption={true}
        importItem={importActions ? importActionTemplate : undefined}
        filterFromParent={filterFromParent}
        actionMenu={actionItem()}
        onPreviewClick={handlePreviewClick}
      />

      {!isEmpty(selectedTask) && (
        <DeleteModal
          customTitle={
            selectedTask.delete_access
              ? t('are_you_sure_you_want_to_delete_with_param', {
                  item: selectedTask.record_id,
                })
              : t('you_are_not_entitled_to_that_operation')
          }
          show={showDeleteModal}
          closeModal={closeDeleteModal}
          onDelete={removeTask}
          itemIdnType={`${selectedTask.record_id} (${t('task')})`}
          itemName={`${selectedTask.record_id} - ${selectedTask.title}`}
        />
      )}

      {showCreateModal &&
        !(parentType === 'Topic' || parentType === 'Meeting') &&
        (taskTypeTree ? (
          <TaskTypeTaskForm
            editingMode={false}
            projectId={projectId}
            task={tempTask}
            taskType={true}
            closeModal={closeCreateModal}
            selectedTaskTypeId={taskType?.id}
          />
        ) : (
          <TaskForm
            lastAllowedDeadline={lastAllowedDeadline}
            editingMode={false}
            task={tempTask}
            projectId={projectId}
            closeModal={closeCreateModal}
            tableName={tableName}
          />
        ))}

      {showCreateModal &&
        (parentType === 'Topic' || parentType === 'Meeting') && (
          <Modal
            show={showCreateModal}
            closeModal={openCreateModal}
            title={t('add_task')}
            maxWidth={800}
          >
            <MeetingTaskForm
              editingMode={false}
              task={tempTask}
              projectId={projectId}
              closeModal={closeCreateModal}
              createdByLeader={leaderId === userId}
            />
          </Modal>
        )}

      {copyModalOpen && (
        <TaskForm
          lastAllowedDeadline={lastAllowedDeadline}
          editingMode={false}
          task={copyTask}
          projectId={projectId}
          closeModal={closeCopyModal}
          module="planning"
        />
      )}

      <TestSendInvitation
        show={confirmationModal}
        closeModal={() => {
          setConfirmationModal(false)
        }}
        itemId={selectedTasks}
        api={sendInviteToTestParticipant}
        numberOfUsers={selectedTasks.length}
        defaultInvitationSubject="Send Reminders to responsible people"
        title="send_reminder"
        participants={tasksSelected?.map((each) => each.responsible) ?? []}
        dataType={'Task'}
      />

      {openInspectorPanel &&
        selectedTaskId &&
        (taskTypeTree ? (
          <TaskTypeInspector
            taskId={selectedTaskId}
            open={openInspectorPanel}
            onClose={onCloseInspectionPanel}
            projectId={projectId}
            onUpdate={reload}
          />
        ) : (
          <TaskInspectorPanel
            taskId={selectedTaskId}
            open={openInspectorPanel}
            onClose={onCloseInspectionPanel}
            projectId={projectId}
            onUpdate={reload}
            module={module}
          />
        ))}
      {loading ? <Loader /> : null}
    </>
  )
}

export default memo(ActionsList)
