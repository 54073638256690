import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ISystemType, ISystemTypeGroup } from 'src/service/OrgTypes'
import {
  createSystemType,
  editSystemType,
  getSystemType,
  massEditSystemType,
  validateSystemType,
} from 'src/service/SystemService'
import Button from 'src/ui-elements/button/Button'
import Input from 'src/ui-elements/input/Input'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Textarea from 'src/ui-elements/textarea/Textarea'
import { getProjectSystemTypeGroup } from '../../../service/SystemTypeGroupService'
import { capFirstLetter, classNames } from '../../../utility/utils'
import Selector from '../../selectors/Selector'
import SystemTypeFieldsGroup from '../system-type-group/SystemTypeFieldsGroup'

export interface ISystemTypeFormProps {
  projectId: number
  systemSettingId: number
  categoryId?: number
  categoryCode?: string
  systemTypeCodeRegex?: string
  closeModal: () => void
  show: boolean
  systemTypeId?: number
  systemTypeIds?: number[]
  onSubmitSelect?: (data: number[]) => void
}

const styleClass = {
  root: classNames('flex', 'flex-col'),
  inputGroup: classNames('w-full', 'flex', 'row', 'pt-1'),
  label: classNames(
    'flex',
    'items-center',
    'w-full',
    'font-roboto',
    'block',
    'font-medium',
    'text-sm',
    'leading-5',
    'text-gray-700',
    'my-2',
  ),
  errorMessage: classNames(
    'flex',
    'items-center',
    'text-red-600',
    'ml-2',
    'text-sm',
    'font-normal',
  ),
  wrapper: classNames('px-2', 'w-full'),
  inputWraper: classNames('w-1/2'),
}

const SystemTypeForm = ({
  projectId,
  systemSettingId,
  categoryId,
  categoryCode,
  systemTypeCodeRegex,
  closeModal,
  show,
  systemTypeId,
  systemTypeIds,
}: ISystemTypeFormProps) => {
  const { t } = useTranslation()
  const [name, setName] = useState<string>('')
  const [nameError, setNameError] = useState<string | undefined>(undefined)
  const [typeCodeError, setTypeCodeError] = useState<string | undefined>(
    undefined,
  )
  const [typeCode, setTypeCode] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [systemTypeGroupId, setSystemTypeGroupId] = useState<
    number | undefined
  >(undefined)
  const [systemTypeGroups, setSystemTypeGroups] = useState<ISystemTypeGroup[]>(
    [],
  )
  const [selectedSystemTypeGroup, setSelectedSystemTypeGroup] = useState<
    ISystemTypeGroup | undefined
  >(undefined)
  const [systemTypeGroupLoading, setSystemTypeGroupLoading] = useState(false)
  const [createMultiple, setCreateMultiple] = useState(false)

  const loadSystemTypes = useCallback(() => {
    if (systemTypeId) {
      getSystemType(systemTypeId).then((res: ISystemType) => {
        setName(res.name)
        setTypeCode(res.type_code)
        setDescription(res.description)
        setSystemTypeGroupId(res.system_type_group_id)
        setSelectedSystemTypeGroup(res.system_type_group)
        setSystemTypeGroups(
          res.system_type_group ? [res.system_type_group] : [],
        )
      })
    }
  }, [systemTypeId, projectId])

  useEffect(() => {
    if (!systemTypeIds) {
      loadSystemTypes()
    }
  }, [loadSystemTypes, systemTypeIds])

  const getSystemTypeGroups = () => {
    setSystemTypeGroupLoading(true)
    getProjectSystemTypeGroup(projectId).then((res: ISystemTypeGroup[]) => {
      setSystemTypeGroups(res.filter((field) => field.domain === 'System'))
      setSystemTypeGroupLoading(false)
    })
  }

  const onSystemTypeGroupChange = (value: number) => {
    const systemTypeGroup = systemTypeGroups.find((stg) => stg.id === value)
    setSelectedSystemTypeGroup(systemTypeGroup)
    setSystemTypeGroupId(value)
  }

  const onChangeNameInput = (value: string) => {
    setName(value)
    setNameError(undefined)
    validate(value, typeCode)
  }

  const onChangeCodeInput = (value: string) => {
    setTypeCode(value)
    setTypeCodeError(undefined)
    validate(name, value)
  }

  const onChangeDescriptionInput = (value: string) => {
    setDescription(value)
  }

  const validateInput = () => {
    let errorVal = false
    setNameError(undefined)
    setTypeCodeError(undefined)
    if (!name || name === '') {
      setNameError(t('fill_in_the_name'))
      errorVal = true
    }

    if (!typeCode || typeCode === '') {
      setTypeCodeError(t('fill_in_object_code'))
      errorVal = true
    }

    const typeCodeRegex = systemTypeCodeRegex
      ? new RegExp(systemTypeCodeRegex)
      : undefined
    if (typeCodeRegex && typeCode && !typeCodeRegex.test(typeCode)) {
      setTypeCodeError(
        t('invalid_object_type_code_for_category', {
          category: categoryCode,
        }),
      )
      errorVal = true
    }

    return errorVal
  }

  const onSubmit = () => {
    const errorVal = validateInput()
    if (!errorVal) {
      const systemType: ISystemType = {
        name,
        type_code: typeCode,
        description: description,
        system_type_group_id: systemTypeGroupId,
        system_setting_id: systemSettingId,
        project_id: projectId,
        system_syntax_category_id: categoryId,
      }
      systemTypeId
        ? editSystemType({ ...systemType, id: systemTypeId }).then(() => {
            closeModal()
          })
        : createSystemType(projectId, systemSettingId, systemType).then(() => {
            if (createMultiple) {
              setName('')
              setTypeCode('')
            } else {
              closeModal()
            }
          })
    }
  }

  const onMassEdit = () => {
    if (systemTypeIds) {
      const systemType = {
        name,
        description: description,
        system_type_group_id: systemTypeGroupId,
        system_setting_id: systemSettingId,
        project_id: projectId,
      }
      massEditSystemType(projectId, systemTypeIds, systemType).then(() => {
        closeModal()
      })
    }
  }

  const validate = (stName: string, stCode: string) => {
    if (systemTypeIds || !categoryId) return
    setNameError(undefined)
    setTypeCodeError(undefined)
    if (stName && stName !== '' && stCode && stCode !== '') {
      const systemType = {
        name: stName,
        type_code: stCode,
        system_setting_id: systemSettingId,
        project_id: projectId,
        system_syntax_category_id: categoryId,
      }
      validateSystemType(projectId, systemSettingId, systemType).then((res) => {
        if (res.system_type_exists) {
          setNameError(t('already_exists'))
          setTypeCodeError(t('already_exists'))
        }
        if (!res.system_type_valid) {
          setTypeCodeError(
            t('invalid_object_type_code_for_category', {
              category: categoryCode,
            }),
          )
        }
      })
    }
  }

  const onSingleCreate = () => {
    setCreateMultiple(false)
  }

  const onMultipleCreate = () => {
    setCreateMultiple(true)
  }

  return (
    <Modal
      show={show}
      title={
        systemTypeIds
          ? t('edit_multiple_system_type')
          : systemTypeId
            ? t('edit_system_type')
            : t('add_or_select_system_type')
      }
      closeModal={closeModal}
      size={'w-3/4'}
      maxWidth={800}
    >
      <form
        className={styleClass.root}
        onSubmit={(e) => {
          e.preventDefault()
          systemTypeIds ? onMassEdit() : onSubmit()
        }}
      >
        <>
          <div className={styleClass.inputGroup}>
            <Input
              block={true}
              required={true}
              label={t('name')}
              onChange={(e) => onChangeNameInput(e.target.value)}
              value={name}
              errorMessage={nameError}
            />
          </div>
          <div className={styleClass.inputGroup}>
            {!systemTypeIds && (
              <div className={'w-full lg:w-1/2'}>
                <Input
                  label={t('type_code')}
                  value={typeCode}
                  onChange={(e) => onChangeCodeInput(e.target.value)}
                  block={true}
                  disabled={!!systemTypeId}
                  required={true}
                  errorMessage={typeCodeError}
                />
              </div>
            )}
            <div className={'w-full lg:w-1/2'}>
              <Selector
                items={systemTypeGroups}
                selectedItemId={systemTypeGroupId}
                onOpenSelectFunction={getSystemTypeGroups}
                loading={systemTypeGroupLoading}
                onSelect={onSystemTypeGroupChange}
                label={t('system_type_metadata')}
                dataFields={['name']}
                fontSize={'sm'}
                fontWeight={'bold'}
              />
            </div>
          </div>
          <div className={styleClass.inputGroup}>
            <Textarea
              isValid={false}
              label={t('description')}
              value={description}
              onChange={(e) => onChangeDescriptionInput(e.target.value)}
              block={true}
              required={false}
            />
          </div>

          {selectedSystemTypeGroup && (
            <div className="py-2">
              <p className="pl-1 block font-medium text-sm leading-5 text-gray-700 my-2 font-roboto">
                {t('metadata')}
              </p>
              <SystemTypeFieldsGroup
                fields={selectedSystemTypeGroup.optional_fields || []}
              />
            </div>
          )}
        </>

        <ModalFooter>
          <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
            {capFirstLetter(t('cancel'))}
          </Button>
          {systemTypeId || systemTypeIds ? (
            <Button
              type={Button.ButtonType.PRIMARY}
              disabled={!!nameError || !!typeCodeError}
            >
              {capFirstLetter(t('edit'))}
            </Button>
          ) : (
            <>
              <Button
                type={Button.ButtonType.SECONDARY}
                onClick={onMultipleCreate}
                disabled={!!nameError || !!typeCodeError}
              >
                {t('add_multiple')}
              </Button>
              <Button
                type={Button.ButtonType.PRIMARY}
                onClick={onSingleCreate}
                disabled={!!nameError || !!typeCodeError}
              >
                {capFirstLetter(t('add'))}
              </Button>
            </>
          )}
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default SystemTypeForm
