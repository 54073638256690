import Close from '@icons/close.svg'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import CheckboxWithLabel from './checkbox/CheckboxWithLabel'

type Item = {
  id: number | string
  name: string
  selected: boolean
}

type Props = {
  items: Item[]

  // Callback to deselect all items
  clearAll?: () => void

  // Toggle selection of item
  toggleItem: (id: number | string) => void

  filter?: string

  // Set filter to search
  setFilter?: (value: string) => void

  // Height as tw-class
  height?: string
}

const MultiselectList = ({
  clearAll,
  filter,
  setFilter,
  items,
  toggleItem,
  height = 'h-64',
}: Props) => {
  const { t } = useTranslation()

  const showClear = items.some((item) => item.selected) && clearAll

  return (
    <>
      <div className="relative text-sm">
        <div className="flex">
          {setFilter && (
            <input
              autoFocus={true}
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
              className="border rounded m-1 p-1 w-full"
              placeholder={t('search')}
            />
          )}
        </div>
        <div
          className={twMerge(
            'overflow-y-scroll whitespace-nowrap',
            height,
            showClear && 'mb-2',
            !setFilter && 'mt-2',
          )}
        >
          {items.map((item) => (
            <CheckboxWithLabel
              key={item.id}
              name={item.name}
              labelRight={true}
              value={item.selected}
              onChange={() => toggleItem(item.id)}
            />
          ))}
        </div>
      </div>
      {showClear && (
        <div
          className="p-0.5 items-center flex w-full absolute bottom-0 bg-gray-100 cursor-pointer hover:bg-gray-50 hover:font-bold translate-y-1/2"
          onClick={clearAll}
        >
          <Close className="text-lg fill-red" />
          <span>{capitalize(t('clear'))}</span>
        </div>
      )}
    </>
  )
}

export default MultiselectList
