import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import Selector from 'src/components/selectors/Selector'
import {
  getDisciplines,
  getProjectDisciplines,
} from 'src/service/DisciplineService'
import {
  IDiscipline,
  IStatus,
  ISystemTypeGroup,
  ITestSystemGroup,
  ITestWorkGroup,
  IUserData,
} from 'src/service/OrgTypes'
import {
  createTestSystemGroup,
  editTestSystemGroup,
  getSingleTestSystemGroup,
  massTestSystemGroup,
} from 'src/service/TestSystemGroupService'
import {
  getDisplineUsers,
  getProjectDisplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import { getErrorMessage, ValidationError } from 'src/service/ValidationErrors'
import Button from 'src/ui-elements/button/Button'
import Input from 'src/ui-elements/input/Input'
import Spinner from 'src/ui-elements/loader/Spinner'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Textarea from 'src/ui-elements/textarea/Textarea'
import { renderDayContents } from 'src/utility/Utility'
import { classNames } from 'src/utility/utils'
import DocumentMetaDataFields from '../../../document/components/DocumentCreateModal/DocumentMetaDataFields'
import { IMetaValue } from '../../../document/types/IMetaData'
import { getStatusesForType } from '../../../service/SystemStatusService'
import { getProjectSystemTypeGroupPerDomain } from '../../../service/SystemTypeGroupService'
import { getProjectTestWorkGroupOnly } from '../../../service/TestWorkGroupService'
import { initializeMetaValues, setExistingValues } from '../SystemUtil'

interface ITestSystemGroupFormProps {
  projectId: number
  workGroupId?: number
  closeModal: () => void
  editingMode: boolean
  testSystemGroup?: ITestSystemGroup
  testSystemGroupIds?: number[]
}

const TestSystemGroupForm = ({
  projectId,
  workGroupId,
  closeModal,
  editingMode,
  testSystemGroup,
  testSystemGroupIds,
}: ITestSystemGroupFormProps) => {
  const styleClass = {
    root: classNames('w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row', 'py-1'),
  }

  const massEditing = !!testSystemGroupIds
  const { t } = useTranslation()
  const [recordId, setRecordId] = useState(
    massEditing ? undefined : testSystemGroup?.record_id,
  )
  const [title, setTitle] = useState(
    massEditing ? undefined : testSystemGroup?.title,
  )
  const [description, setDescription] = useState(
    massEditing ? undefined : testSystemGroup?.description,
  )
  const [statusId, setStatusId] = useState(
    massEditing ? undefined : testSystemGroup?.test_system_group_status_id,
  )
  const [statusErrorMessage, setSetatusErrorMessage] = useState('')
  const [completedPercent, setCompletedPercent] = useState(
    massEditing ? undefined : testSystemGroup?.percent_completed,
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [recordIdErrorMessage, setrecordIdErrorMessage] = useState<string>('')
  const [contractId, setContractId] = useState(
    massEditing ? undefined : testSystemGroup?.contract_id,
  )
  const [responsibleErrorMessage, setResponsibleErrorMessage] =
    useState<string>('')
  const [disciplineLoading, setDisciplineLoading] = useState<boolean>(false)
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [responsibleId, setResponsibleId] = useState(
    massEditing ? undefined : testSystemGroup?.responsible_id,
  )
  const [disciplineId, setDisciplineId] = useState(
    massEditing ? undefined : testSystemGroup?.discipline_id,
  )
  const [disciplineErrorMessage, setDisciplineErrorMessage] =
    useState<string>('')
  const [users, setUsers] = useState<IUserData[]>([])
  const [userLoading, setUserLoading] = useState<boolean>(false)
  const [testWorkId, setTestWorkId] = useState(
    massEditing
      ? undefined
      : testSystemGroup
        ? testSystemGroup?.test_work_group_id
        : workGroupId,
  )
  const [testWorks, setTestWorks] = useState<ITestWorkGroup[]>([])
  const [testWorkLoading, setTestWorkLoading] = useState<boolean>(false)
  const [createMultiple, setCreateMultiple] = useState<boolean>(false)
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])
  const [systemTypeGroup, setSystemTypeGroup] = useState<
    ISystemTypeGroup | undefined
  >(undefined)
  const [statuses, setStatuses] = useState<IStatus[]>([])
  const [statusLoading, setStatusLoading] = useState(false)
  const [plannedStart, setPlannedStart] = useState<moment.Moment | null>(null)
  const [plannedEnd, setPlannedEnd] = useState<moment.Moment | null>(null)
  const [actualStart, setActualStart] = useState<moment.Moment | null>(null)
  const [actualEnd, setActualEnd] = useState<moment.Moment | null>(null)
  const [focus, setFocus] = useState<string>('')

  useEffect(() => {
    if (workGroupId) {
      fetchTestWorkGroups()
    }

    fetchSystemGroupType()
  }, [projectId, workGroupId])

  const fetchSystemGroupType = () => {
    getProjectSystemTypeGroupPerDomain(projectId, 'TestSystemGroup').then(
      (res: ISystemTypeGroup[]) => {
        const value = res.pop()
        setSystemTypeGroup(value)
        if (value) {
          const metaFields = initializeMetaValues(
            value.optional_fields ?? [],
            'TestSystemGroup',
            testSystemGroup?.id,
          )
          setOptionalFields(
            setExistingValues(
              testSystemGroup?.optional_fields ?? [],
              metaFields,
            ),
          )
        }
      },
    )
  }

  const getStatus = () => {
    setStatusLoading(true)
    getStatusesForType(projectId, 'TestSystemGroup').then((res) => {
      setStatuses(res)
      setStatusLoading(false)
    })
  }

  const onMassEdit = (e: any) => {
    e.preventDefault()
    setLoading(true)
    if (testSystemGroupIds) {
      const updateTestSystemGroup = {
        title,
        description,
        percent_completed: completedPercent,
        discipline_id: disciplineId,
        contract_id: contractId,
        test_work_group_id: testWorkId,
        responsible_id: responsibleId,
        test_system_group_status_id: statusId,
        optional_fields: optionalFields,
        system_type_group_id: systemTypeGroup?.id,
        planned_start: plannedStart ?? undefined,
        planned_end: plannedEnd ?? undefined,
        actual_start: actualStart ?? undefined,
        actual_end: actualEnd ?? undefined,
      } as ITestSystemGroup

      massTestSystemGroup(projectId, testSystemGroupIds, updateTestSystemGroup)
        .then(() => {
          setLoading(false)
          closeModal()
        })
        .catch(() => setLoading(false))
    }
  }

  const onSubmit = (e: any) => {
    let error = false
    e.preventDefault()

    setLoading(true)
    setrecordIdErrorMessage('')

    if (!recordId) {
      setrecordIdErrorMessage(t('fill_in_id'))
      error = true
    }

    if (!responsibleId) {
      setResponsibleErrorMessage(t('select_responsible'))
      error = true
    }

    if (!disciplineId) {
      setDisciplineErrorMessage(t('select_discipline'))
      error = true
    }

    if (!statusId) {
      setSetatusErrorMessage(getErrorMessage(ValidationError.MISSING_STATUS, t))
      error = true
    }

    if (!error) {
      if (editingMode && testSystemGroup && testSystemGroup.id) {
        const editedSystemGroup = {
          id: testSystemGroup.id,
          record_id: recordId,
          title,
          description,
          percent_completed: completedPercent,
          discipline_id: disciplineId,
          contract_id: contractId,
          test_work_group_id: testWorkId,
          responsible_id: responsibleId,
          test_system_group_status_id: statusId,
          optional_fields: optionalFields,
          system_type_group_id: systemTypeGroup?.id,
          planned_start: plannedStart ?? undefined,
          planned_end: plannedEnd ?? undefined,
          actual_start: actualStart ?? undefined,
          actual_end: actualEnd ?? undefined,
        } as ITestSystemGroup
        editTestSystemGroup(editedSystemGroup).then(() => {
          closeModal()
          setLoading(false)
        })
      } else {
        const newTestSystemGroup = {
          record_id: recordId,
          title,
          description,
          percent_completed: completedPercent,
          discipline_id: disciplineId,
          contract_id: contractId,
          test_work_group_id: testWorkId,
          responsible_id: responsibleId,
          test_system_group_status_id: statusId,
          optional_fields: optionalFields,
          system_type_group_id: systemTypeGroup?.id,
          planned_start: plannedStart ?? undefined,
          planned_end: plannedEnd ?? undefined,
          actual_start: actualStart ?? undefined,
          actual_end: actualEnd ?? undefined,
        }
        createTestSystemGroup(newTestSystemGroup, projectId).then(() => {
          if (createMultiple) {
            setRecordId('')
            setLoading(false)
          } else {
            closeModal()
            setLoading(false)
          }
        })

        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }

  const onRecordIdChange = (e: any) => {
    setRecordId(e.target.value)
    setrecordIdErrorMessage('')
  }

  const onTitleChange = (e: any) => {
    setTitle(e.target.value)
  }

  const onDescriptionChange = (e: any) => {
    setDescription(e.target.value)
  }

  const onPercentageChange = (e: any) => {
    setCompletedPercent(+e.target.value)
  }

  const fetchDisciplines = async () => {
    setDisciplineLoading(true)
    const allDisciplines: IDiscipline[] = await getProjectDisciplines(projectId)
    setDisciplines(allDisciplines)
    setDisciplineLoading(false)
  }

  const getUsers = async () => {
    setUserLoading(true)
    const allUsers = disciplineId
      ? await getDisplineUsers(disciplineId)
      : await getProjectUsersWithDisciplines(projectId)
    setUsers(allUsers)
    setUserLoading(false)
  }

  const onDisciplineChange = async (value: number) => {
    const discipline = disciplines
      ? disciplines.find((v) => v.id === value)
      : undefined
    if (!responsibleId || responsibleId <= 0) {
      const usersList = await getProjectDisplineUsers(projectId, value)
      setResponsibleId(0)
      setUsers(usersList)
    }

    setDisciplineId(value)
    if (discipline && discipline.contract_id) {
      setContractId(discipline?.contract_id)
    }

    setResponsibleErrorMessage('')
    setDisciplineErrorMessage('')
  }

  const onResponsibleChange = (userId: number) => {
    const selecteduser: IUserData | undefined = users
      .filter((u) => u.id === userId)
      .pop()
    const userDisciplines =
      selecteduser && selecteduser.disciplines
        ? selecteduser.disciplines.filter((d) => d.project_id === projectId)
        : undefined
    const discipline =
      userDisciplines &&
      (!disciplineId ||
        !userDisciplines?.find((ud) => ud.id === disciplineId)) &&
      userDisciplines.length > 0
        ? userDisciplines[0]
        : undefined

    setResponsibleId(userId)
    setDisciplines(userDisciplines ? userDisciplines : [])
    if (discipline) {
      setDisciplineId(discipline.id)
      setContractId(discipline.contract_id)
    }

    setResponsibleErrorMessage('')
    setDisciplineErrorMessage('')
  }

  const fetchTestWorkGroups = () => {
    setTestWorkLoading(true)
    getProjectTestWorkGroupOnly(projectId).then((res) => {
      setTestWorks(res.length ? res : [])
      setTestWorkLoading(false)
    })
  }

  const onStatusChange = (val: number) => {
    setStatusId(val)
    setSetatusErrorMessage('')
  }

  const onSingleCreate = () => {
    setCreateMultiple(false)
  }

  const onMultipleCreate = () => {
    setCreateMultiple(true)
  }

  const onOptionalFieldsUpdate = (values: IMetaValue[]) => {
    setOptionalFields(values)
  }

  const loadTestSystemGroup = useCallback(() => {
    if (testSystemGroup?.id) {
      getSingleTestSystemGroup(testSystemGroup.id).then((res) => {
        if (res) {
          setRecordId(res.record_id)
          setCompletedPercent(res.percent_completed ?? 0)
          setTitle(res.title ?? '')
          setDescription(res.description ?? '')
          setResponsibleId(res.responsible_id ?? 0)
          setContractId(res.contract_id ?? 0)
          setDisciplines(res.discipline ? [res.discipline] : [])
          setUsers(res.responsible ? [res.responsible] : [])
          setDisciplineId(res.discipline_id ?? 0)
          setTestWorkId(res.test_work_group_id ?? 0)
          setStatuses(
            res.test_system_group_status ? [res.test_system_group_status] : [],
          )
          setTestWorks(res.test_work_group ? [res.test_work_group] : [])

          if (systemTypeGroup) {
            const metaFields = initializeMetaValues(
              systemTypeGroup?.optional_fields ?? [],
              'TestSystemGroup',
              testSystemGroup?.id,
            )
            setOptionalFields(
              setExistingValues(res.optional_fields ?? [], metaFields),
            )
          }
        }
      })
    }
  }, [testSystemGroup?.id])

  useEffect(() => {
    loadTestSystemGroup()
  }, [loadTestSystemGroup])

  const onDisplineUserClear = async () => {
    setDisciplineId(0)
    setResponsibleId(0)
    const allProjectDisciplines = await getDisciplines(projectId)
    const allUsers = await getProjectUsersWithDisciplines(projectId)
    setDisciplines(allProjectDisciplines)
    setUsers(allUsers)
  }

  return (
    <form
      className={styleClass.root}
      onSubmit={massEditing ? onMassEdit : onSubmit}
    >
      <div className={styleClass.inputGroup}>
        <Input
          label={t('title')}
          value={title}
          autoFocus={false}
          onChange={onTitleChange}
          block={true}
          required={false}
        />
      </div>
      <div className={`${styleClass.inputGroup} flex-wrap`}>
        {!massEditing && (
          <div className={'w-full lg:w-1/2'}>
            <Input
              label={t('test_object_group_id')}
              block={true}
              value={recordId}
              onChange={onRecordIdChange}
              required={true}
              errorMessage={recordIdErrorMessage}
              autoFocus={true}
            />
          </div>
        )}
        <div className={'w-full  lg:w-1/2'}>
          <Input
            block={true}
            label={t('completed_percent')}
            type={'number'}
            onChange={onPercentageChange}
            value={completedPercent}
            autoFocus={true}
          />
        </div>
      </div>

      <div className={`${styleClass.inputGroup} flex-wrap`}>
        <div className={'w-full lg:w-1/2'}>
          <Selector
            items={testWorks}
            selectedItemId={testWorkId || ''}
            onOpenSelectFunction={fetchTestWorkGroups}
            loading={testWorkLoading}
            onSelect={setTestWorkId}
            label={t('test_work_group')}
            dataFields={['record_id', 'title']}
            required={false}
            fontSize={'sm'}
            fontWeight={'bold'}
          />
        </div>
        <div className={'w-full lg:w-1/2'}>
          <Selector
            items={statuses}
            selectedItemId={statusId || ''}
            onOpenSelectFunction={getStatus}
            loading={statusLoading}
            onSelect={onStatusChange}
            label={t('status')}
            dataFields={['name']}
            required={!massEditing}
            fontSize={'sm'}
            fontWeight={'bold'}
            errorMessage={statusErrorMessage}
          />
        </div>
      </div>
      <div className={`${styleClass.inputGroup} flex-wrap`}>
        <div className={'w-full lg:w-1/2'}>
          <div className={'flex flex-col px-2 pb-1 items-start w-full'}>
            <label className={'text-sm font-medium capitalize'}>
              {t('planned_start')}
            </label>
            <SingleDatePicker
              firstDayOfWeek={1}
              date={plannedStart}
              onDateChange={(d) => {
                if (d) {
                  setPlannedStart(moment(d))
                  setPlannedEnd(moment(d).add(6, 'weeks'))
                }
              }}
              renderDayContents={renderDayContents}
              focused={focus === 'plannedStart'}
              onFocusChange={(focused) =>
                setFocus(focused.focused ? 'plannedStart' : '')
              }
              id="datePicker-plannedStart"
              small={true}
              isOutsideRange={() => false}
              showDefaultInputIcon={true}
              noBorder={true}
              numberOfMonths={1}
              displayFormat={() =>
                moment.localeData('no').postformat('DD.MM.YY')
              }
              required={false}
              hideKeyboardShortcutsPanel={true}
            />
          </div>
        </div>
        <div className={'w-full lg:w-1/2'}>
          <div className={'flex flex-col px-2 pb-1 items-start w-full'}>
            <label className={'text-sm font-medium capitalize'}>
              {t('planned_end')}
            </label>

            <SingleDatePicker
              firstDayOfWeek={1}
              date={plannedEnd}
              onDateChange={(d) => setPlannedEnd(d)}
              renderDayContents={renderDayContents}
              focused={focus === 'plannedEnd'}
              onFocusChange={(focused) =>
                setFocus(focused.focused ? 'plannedEnd' : '')
              }
              id="datePicker-plannedEnd"
              small={true}
              isOutsideRange={() => false}
              showDefaultInputIcon={true}
              noBorder={true}
              numberOfMonths={1}
              displayFormat={() =>
                moment.localeData('no').postformat('DD.MM.YY')
              }
              required={false}
              hideKeyboardShortcutsPanel={true}
            />
          </div>
        </div>
      </div>
      <div className={`${styleClass.inputGroup} flex-wrap`}>
        <div className={'w-full lg:w-1/2'}>
          <div className={'flex flex-col px-2 pb-1 items-start w-full'}>
            <label className={'text-sm font-medium capitalize'}>
              {t('actual_start')}
            </label>

            <SingleDatePicker
              firstDayOfWeek={1}
              date={actualStart}
              onDateChange={(d) => setActualStart(d)}
              renderDayContents={renderDayContents}
              focused={focus === 'actualStart'}
              onFocusChange={(focused) =>
                setFocus(focused.focused ? 'actualStart' : '')
              }
              id="datePicker-actualStart"
              small={true}
              isOutsideRange={() => false}
              showDefaultInputIcon={true}
              noBorder={true}
              numberOfMonths={1}
              displayFormat={() =>
                moment.localeData('no').postformat('DD.MM.YY')
              }
              required={false}
              hideKeyboardShortcutsPanel={true}
            />
          </div>
        </div>
        <div className={'w-full lg:w-1/2'}>
          <div className={'flex flex-col px-2 pb-1 items-start w-full'}>
            <label className={'text-sm font-medium capitalize'}>
              {t('actual_end')}
            </label>

            <SingleDatePicker
              firstDayOfWeek={1}
              date={actualEnd}
              onDateChange={(d) => setActualEnd(d)}
              renderDayContents={renderDayContents}
              focused={focus === 'actualEnd'}
              onFocusChange={(focused) =>
                setFocus(focused.focused ? 'actualEnd' : '')
              }
              id="datePicker-actualEnd"
              small={true}
              isOutsideRange={() => false}
              showDefaultInputIcon={true}
              noBorder={true}
              numberOfMonths={1}
              displayFormat={() =>
                moment.localeData('no').postformat('DD.MM.YY')
              }
              required={false}
              hideKeyboardShortcutsPanel={true}
            />
          </div>
        </div>
      </div>
      <div className={`${styleClass.inputGroup} flex-wrap`}>
        <div className={'w-full lg:w-1/2'}>
          <Selector
            items={disciplines}
            selectedItemId={disciplineId || ''}
            onOpenSelectFunction={fetchDisciplines}
            onSelect={onDisciplineChange}
            label={t('discipline')}
            loading={disciplineLoading}
            dataFields={['shortName', 'name']}
            required={!massEditing}
            fontSize={'sm'}
            fontWeight={'bold'}
            errorMessage={disciplineErrorMessage}
            cancelButton={true}
            onCancel={onDisplineUserClear}
          />
        </div>
        <div className={'w-full lg:w-1/2'}>
          <Selector
            items={users}
            selectedItemId={responsibleId || ''}
            onOpenSelectFunction={getUsers}
            loading={userLoading}
            onSelect={onResponsibleChange}
            label={t('responsible')}
            dataFields={['firstName', 'lastName']}
            required={!massEditing}
            fontSize={'sm'}
            userSelector={true}
            fontWeight={'bold'}
            errorMessage={responsibleErrorMessage}
            onCancel={onDisplineUserClear}
            cancelButton={true}
          />
        </div>
        <div className={styleClass.inputGroup}>
          <Textarea
            label={t('description')}
            value={description}
            isValid={false}
            autoFocus={false}
            onChange={onDescriptionChange}
            block={true}
            required={false}
          />
        </div>
      </div>

      {optionalFields.length > 0 && (
        <div className="pb-8">
          <DocumentMetaDataFields
            title={''}
            onFieldsUpdate={onOptionalFieldsUpdate}
            fields={optionalFields}
            required={false}
          />
        </div>
      )}
      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
          {t('cancel')}
        </Button>
        {editingMode ? (
          <Button
            type={Button.ButtonType.PRIMARY}
            disabled={loading ? true : false}
          >
            {loading ? <Spinner /> : t('update')}
          </Button>
        ) : (
          <>
            <Button
              type={Button.ButtonType.SECONDARY}
              onClick={onMultipleCreate}
              disabled={loading}
            >
              {loading ? <Spinner /> : t('add_multiple')}
            </Button>
            <Button
              type={Button.ButtonType.PRIMARY}
              disabled={loading ? true : false}
              onClick={onSingleCreate}
            >
              {loading ? <Spinner /> : t('add')}
            </Button>
          </>
        )}
      </ModalFooter>
    </form>
  )
}

export default TestSystemGroupForm
