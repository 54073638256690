import Edit from '@icons/edit.svg'
import Publish from '@icons/upload_2-fill.svg'
import moment from 'moment'
import * as React from 'react'
import { MouseEvent, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  CreatedAtColumn,
  CreatedByColumn,
  DisciplineColumnEditable,
  EditableDateColumn,
  TestWorkGroupStatusColumn,
  TextColumn,
  UpdatedAtColumn,
  UpdatedByColumn,
  userDefinedColumns,
} from 'src/components/TableColumns/Columns'
import { ContractColumn } from 'src/components/TableColumns/DisciplineResponsibleColumns'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import TestWorkGroupForm from 'src/components/system/test-work-groups/TestWorkGroupForm'
import TestWorkGroupInspectorPanel from 'src/components/system/test-work-groups/TestWorkGroupInspectorPanel'
import TableSelectorEditor from 'src/components/table-ResponsibleEditor/TableSelectorEditor'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { IMetaValue } from 'src/document/types/IMetaData'
import { testWorkGroupExportTemplate } from 'src/export-templates/TestWorkGroupExport'
import { useSystemTypeGroups } from 'src/query/systemTypeGroups'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { ITestWorkGroup } from 'src/service/OrgTypes.js'
import { getStatusesForType } from 'src/service/SystemStatusService'
import { updateUserDefinedFieldsValue } from 'src/service/SystemTypeFieldService'
import {
  deleteTestWorkGroup,
  editTestWorkGroup,
  getProjectTestWorkGroup,
  testWorkGroupBulkDelete,
  testWorkGroupValidateFromJson,
} from 'src/service/TestWorkGroupService.js'
import Button from 'src/ui-elements/button/Button'
import List from 'src/ui-elements/list/List'
import { IListFilter } from 'src/ui-elements/list/ListContextProvider'
import { filterType, ISorting } from 'src/ui-elements/list/ListTypes'
import Loader from 'src/ui-elements/loader/Loader'
import Modal from 'src/ui-elements/modal/Modal'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import { addMetaDataInfo } from 'src/utility/exportUtils'
import {
  capFirstLetter,
  classNames,
  constructFilterJson,
  IActiveFilter,
} from 'src/utility/utils'
import DataSelectors, {
  DATA_TYPES,
  ImportFiledTypes,
} from '../../../document/components/FileUpload/selectors/DataSelectors'
import { testWorkGroupImport } from '../../../export-templates/TestWorkGroupImport'
import { IMPORT_TYPE } from '../../notifications/import/ImportNotificationItem'
import ImportModal from '../../upload-item/ImportModal'
import TestWorkGroupGraph, { IPlannedTWGSummary } from './TestWorkGroupGraph'

const priorities = [
  {
    name: 'non_critical',
    value: 'non_critical',
    active: false,
  },
  {
    name: 'critical',
    value: 'critical',
    active: false,
  },
]

export const testWorkGroupsListImportColumns = [
  DataSelectors.defineDataField('record_id', DATA_TYPES.string),
  DataSelectors.defineDataField('title', DATA_TYPES.string),
  DataSelectors.defineDataField('description', DATA_TYPES.string),
  DataSelectors.defineDataField('location', DATA_TYPES.string),
  DataSelectors.getDataField(ImportFiledTypes.PRIORITY),
  DataSelectors.defineDataField('planned_start', DATA_TYPES.date),
  DataSelectors.defineDataField('planned_end', DATA_TYPES.date),
  DataSelectors.defineDataField('actual_start', DATA_TYPES.date),
  DataSelectors.defineDataField('actual_end', DATA_TYPES.date),
  DataSelectors.getDataField(ImportFiledTypes.TEST_WORK_GROUP_STATUSES),
  DataSelectors.getDataField(ImportFiledTypes.DISCIPLINE),
]

interface IFilter extends IActiveFilter {
  record_id?: string[]
  title?: string[]
  description?: string[]
  location?: string[]
  percent_completed?: string[]
  discipline?: string[]
  priority?: string[]
  planned_start?: string[]
  planned_end?: string[]
  actual_start?: string[]
  actual_end?: string[]
  contract?: string[]
  created_by?: string[]
  updated_by?: string[]
  created_at?: string[]
  updated_at?: string[]
  test_work_group_status?: string[]
}

interface ITestWorkGroupsListProps {
  projectId: number
  statusId?: number
  onTestWorkGroupChange?: () => void
  disciplineId?: number
}

const TestWorkGroupList = ({
  projectId,
  statusId,
  onTestWorkGroupChange,
  disciplineId,
}: ITestWorkGroupsListProps) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row'),
  }

  const userContext = useContext(UserContext)
  const writeAccess = userContext.actions.hasWriteAccess('object')

  const { t } = useTranslation()
  const [testWorkGroups, setTestWorkGroups] = useState<ITestWorkGroup[]>([])
  const [selectedTestWorkGroup, setSelectedTestWorkGroup] =
    useState<ITestWorkGroup>()
  const [loading, setLoading] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [showInspector, setShowInspector] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 30
  const [reloadTable, setReloadTable] = useState(false)
  const [selectedTestWorkGroupIds, setSelectedTestWorkGroupIds] = useState<
    number[]
  >([])
  const [showMassEditModal, setShowMassEditModal] = useState<boolean>(false)
  const [showImportModal, setImportModal] = useState(false)
  const { addAlert } = useAlert()
  const showAlert = (type: IAlertType, title: string, text: string) => {
    addAlert({ type, title, description: text })
  }

  const { data: systemTypeGroup, isLoading: isLoadingUserDefinedColumns } =
    useSystemTypeGroups('TestWorkGroup')

  const testWorkGroup = React.useRef(testWorkGroups)

  useEffect(() => {
    testWorkGroup.current = testWorkGroups
  })

  const reload = useCallback(() => {
    setReloadTable((n) => !n)
  }, [])

  const saveSystemGroup = useCallback(
    (value: NullablePartial<ITestWorkGroup>) => {
      editTestWorkGroup(value).then(() => {
        reload()
      })
    },
    [reload],
  )

  useEffect(() => {
    reload()
  }, [reload])

  const history = useHistory()

  const onRowClick = (workGroup: ITestWorkGroup) => {
    if (onTestWorkGroupChange) {
      onTestWorkGroupChange()
    }
    history.push(
      `/systems/test_work_groups_list/testworkgroups/${workGroup.id}`,
    )
  }

  const onDeleteItemClick = (WorkGroup: ITestWorkGroup) => {
    setShowDeleteModal((n) => !n)
    setSelectedTestWorkGroup(WorkGroup)
  }

  const closeDeleteModal = () => {
    setShowDeleteModal((n) => !n)
  }

  const openCreateModal = () => {
    setShowCreateModal((n) => !n)
  }

  const closeCreateModal = () => {
    setShowCreateModal((n) => !n)
    reload()
  }

  const deleteWorkGroup = () => {
    if (selectedTestWorkGroup?.id) {
      deleteTestWorkGroup(selectedTestWorkGroup?.id).then(reload)
      setShowDeleteModal((n) => !n)
    }
  }

  const updateUserDefinedField = (data: IMetaValue, _id: number) => {
    if (data.id) {
      updateUserDefinedFieldsValue(data.id, data).then(() => {
        setReloadTable((n) => !n)
      })
    }
  }

  const onFieldChange = (
    field: string,
    key: number,
    value: string | number | null,
  ) => {
    const system = { id: key, [field]: value }
    saveSystemGroup(system)
  }

  const userDefinedAttributesColumns = systemTypeGroup
    ? userDefinedColumns(
        systemTypeGroup.optional_fields,
        updateUserDefinedField,
        'meta_data',
        true,
        false,
        !writeAccess,
      )
    : []

  const columns = [
    TextColumn(
      'test_work_group_id',
      'record_id',
      '200',
      (key, value) => onFieldChange('record_id', key, value),
      !writeAccess,
    ),
    TextColumn(
      'title',
      'title',
      '200',
      (key, value) => onFieldChange('title', key, value),
      !writeAccess,
    ),
    TestWorkGroupStatusColumn(
      () => getStatusesForType(projectId, 'TestWorkGroup'),
      (id, status_id) =>
        onFieldChange('test_work_group_status_id', id, status_id ?? ''),
      !writeAccess,
    ),
    TextColumn(
      'description',
      'description',
      '200',
      (key, value) => onFieldChange('description', key, value),
      !writeAccess,
    ),
    TextColumn(
      'location',
      'location',
      '200',
      (key, value) => onFieldChange('location', key, value),
      !writeAccess,
    ),
    TextColumn(
      'percent_completed',
      'percent_completed',
      '200',
      (key, value) => onFieldChange('percent_completed', key, value),
      true,
      'number',
    ),
    DisciplineColumnEditable(
      () => getProjectDisciplines(projectId),
      (id, discipline_id) => onFieldChange('discipline_id', id, discipline_id),
      !writeAccess,
    ),
    {
      name: 'priority',
      size: '200',
      id: 'priority',
      sortingField: 'priority',
      dataField: 'priority||id',
      filterType: filterType.DEFAULT,
      filter: priorities,
      cell: ({ priority, id }: { priority: string; id: number }) => (
        <TableSelectorEditor
          rowId={id}
          selected={{ id: priority, name: priority }}
          getData={() =>
            priorities.map((p) => ({ ...p, name: t(p.name), id: p.value }))
          }
          dataFields={['name']}
          isUserSelector={false}
          onDataSelected={(p) => onFieldChange('priority', id ?? -1, p.value)}
          disableEdit={!writeAccess}
          displayContent={
            priority ? (
              <span className="truncate">{t(priority)}</span>
            ) : (
              <span />
            )
          }
        />
      ),
    },
    EditableDateColumn(
      'planned_start',
      'planned_start',
      'planned_start',
      (plannedStart, id) => {
        onFieldChange(
          'planned_start',
          id ?? -1,
          plannedStart ? plannedStart.toISOString() : null,
        )
        onFieldChange(
          'planned_end',
          id ?? -1,
          plannedStart ? plannedStart.add(6, 'weeks').toISOString() : null,
        )
      },
      { nullable: true, disabled: !writeAccess },
    ),
    EditableDateColumn(
      'planned_end',
      'planned_end',
      'planned_end',
      (plannedEnd, id) =>
        onFieldChange(
          'planned_end',
          id ?? -1,
          plannedEnd ? plannedEnd.toISOString() : null,
        ),
      { nullable: true, disabled: !writeAccess },
    ),
    EditableDateColumn(
      'actual_start',
      'actual_start',
      'actual_start',
      (actualStart, id) =>
        onFieldChange(
          'actual_start',
          id ?? -1,
          actualStart ? actualStart.toISOString() : null,
        ),
      { nullable: true, disabled: !writeAccess },
    ),
    EditableDateColumn(
      'actual_end',
      'actual_end',
      'actual_end',
      (actualEnd, id) =>
        onFieldChange(
          'actual_end',
          id ?? -1,
          actualEnd ? actualEnd.toISOString() : null,
        ),
      { nullable: true, disabled: !writeAccess },
    ),
    ContractColumn(),
    ...userDefinedAttributesColumns,
    CreatedByColumn(),
    UpdatedByColumn(),
    CreatedAtColumn(),
    UpdatedAtColumn(),
  ]

  const onCloseInspector = () => {
    setShowInspector(false)
    setSelectedTestWorkGroup(undefined)
  }

  const taskFilter = (activeFilters: IFilter, page: number) => {
    setLoading(true)
    getProjectTestWorkGroup(projectId, page, activeFilters, pageSize).then(
      (res) => {
        setTestWorkGroups(res.test_work_groups)
        setTotalPages(res.pages)
        setLoading(false)
      },
    )
  }

  const handlePreviewClick = (e: MouseEvent, data: ITestWorkGroup) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedTestWorkGroup(data)
    setShowInspector(true)
  }

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters: IFilter = constructFilterJson(currentFilters)
    if (statusId) {
      activeFilters.test_work_group_status = [`${statusId}`]
    }
    if (disciplineId) {
      activeFilters.discipline = [`${disciplineId}`]
    }
    activeFilters.sort = currentSorting
    taskFilter(activeFilters, page)
  }

  const onBulkDelete = () => {
    testWorkGroupBulkDelete(projectId, selectedTestWorkGroupIds).then(() => {
      showAlert(
        'success',
        t('successfully_deleted'),
        t('selected_test_work_group_are_deleted'),
      )
      setReloadTable((n) => !n)
      setSelectedTestWorkGroupIds([])
    })
  }

  const openMassEditModal = () => {
    if (selectedTestWorkGroupIds.length === 0) {
      showAlert('info', t('items_not_selected'), t('select_item_to_edit'))
      return
    }
    setShowMassEditModal(true)
  }

  const closeMassEditModal = () => {
    setShowMassEditModal(false)
    setSelectedTestWorkGroupIds([])
    setReloadTable((n) => !n)
  }

  const updateFilter = (
    { month, year }: Pick<IPlannedTWGSummary, 'year' | 'month'>,
    disciplines: number[],
  ) => {
    const activeFilters: IFilter = constructFilterJson([])
    const start = moment().year(year).month(month).startOf('month')
    const end = moment().year(year).month(month).endOf('month')

    activeFilters.planned_end = [start.format(), end.format()]
    activeFilters.discipline = disciplines.map(String)
    taskFilter(activeFilters, 1)
  }

  return (
    <div className={styleClass.root}>
      {(loading || isLoadingUserDefinedColumns) && (
        <Loader centerLoader={true} />
      )}
      {!isLoadingUserDefinedColumns && (
        <div className="flex flex-col space-y-2">
          <TestWorkGroupGraph
            projectId={projectId}
            updateFilter={updateFilter}
          />
          <List
            actionButton={
              <span className="flex items-center">
                {writeAccess && (
                  <>
                    <Button
                      onClick={openCreateModal}
                      type={Button.ButtonType.PRIMARY}
                    >
                      {t('new_test_work_group')}
                    </Button>
                    <Button
                      type={Button.ButtonType.PRIMARY}
                      onClick={openMassEditModal}
                    >
                      <Edit className={'fill-white'} />
                      {capFirstLetter(t('edit'))}
                    </Button>
                    <Button
                      type={Button.ButtonType.PRIMARY}
                      onClick={() => setImportModal(true)}
                    >
                      <Publish className={'fill-white'} />
                      {t('import')}
                    </Button>
                  </>
                )}
              </span>
            }
            totalPages={totalPages}
            getFilteredData={getFilteredData}
            data={testWorkGroups}
            columns={columns}
            tableName={'testWorkGroupsList'}
            sortBackend={true}
            filterResetOption={true}
            itemsPerPage={pageSize}
            pagination={true}
            onRowClick={onRowClick}
            onPreviewClick={handlePreviewClick}
            reload={reloadTable}
            selectedRows={selectedTestWorkGroupIds}
            onBulkDelete={onBulkDelete}
            bulkDelete={writeAccess}
            onSelectRow={(data) => setSelectedTestWorkGroupIds(data)}
            isRowSelectable={true}
            exportTemplate={addMetaDataInfo(
              testWorkGroupExportTemplate,
              systemTypeGroup?.optional_fields ?? [],
            )}
            actionMenu={
              writeAccess
                ? [
                    {
                      name: t('delete'),
                      action: onDeleteItemClick,
                    },
                  ]
                : undefined
            }
          />
        </div>
      )}

      <Modal
        show={showMassEditModal}
        closeModal={closeMassEditModal}
        title={t('edit_multiple_test_work_groups')}
        maxWidth={800}
      >
        <TestWorkGroupForm
          editingMode={true}
          projectId={projectId}
          testWorkGroupIds={selectedTestWorkGroupIds}
          closeModal={closeMassEditModal}
        />
      </Modal>

      {selectedTestWorkGroup && showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          closeModal={closeDeleteModal}
          onDelete={deleteWorkGroup}
          itemIdnType={`${selectedTestWorkGroup.record_id}`}
          itemName={`${selectedTestWorkGroup.record_id}`}
        />
      )}
      {selectedTestWorkGroup?.id && showInspector && (
        <TestWorkGroupInspectorPanel
          projectId={projectId}
          testWorkGroupId={selectedTestWorkGroup?.id}
          open={showInspector}
          onClose={onCloseInspector}
          onUpdate={() => setReloadTable((n) => !n)}
        />
      )}
      <Modal
        show={showCreateModal}
        closeModal={openCreateModal}
        title={t('add_work_group')}
        maxWidth={800}
      >
        <TestWorkGroupForm
          editingMode={false}
          projectId={projectId}
          closeModal={closeCreateModal}
        />
      </Modal>
      {showImportModal && (
        <ImportModal
          columns={testWorkGroupsListImportColumns}
          import_type={IMPORT_TYPE.TEST_WORK_GROUP_IMPORT}
          show={showImportModal}
          close={() => {
            setImportModal(false)
            reload()
          }}
          useDefinedField={systemTypeGroup?.optional_fields ?? []}
          importApi={testWorkGroupValidateFromJson}
          modal_title={'upload_test_work_groups'}
          template={testWorkGroupImport}
          uploadUrl={`{ORG_URL}test_work_groups/validate_for_import_job`}
        />
      )}
    </div>
  )
}

export default TestWorkGroupList
