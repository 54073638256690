import { modularRequest } from 'src/service/service-utils'
import { getCurrentProject, getFdvuBaseUrl } from 'src/utility/utils'

export class GenericFdvuService {
  protected getUrl = (...paths: (string | number)[]) => {
    const projectId = getCurrentProject()
    const baseUrl = `${getFdvuBaseUrl()}fdvu/project/${projectId}`
    if (!projectId) {
      console.error(
        `No projectId available. Request for ${baseUrl} will not work`,
      )
    }
    return `${baseUrl}/${paths.join('/')}`
  }

  protected urlParamsFromList = (
    key: string,
    params: any[],
    urlParams?: URLSearchParams,
  ) => {
    const newUrlParams = urlParams || new URLSearchParams()
    params.forEach((param) => newUrlParams.append(key, param))
    return newUrlParams
  }

  protected doRequest = <T = any>(
    {
      url,
      method,
      body,
      text,
    }: {
      url?: string
      method?: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH'
      body?: any
      text?: boolean
    } = { method: 'GET' },
  ) => {
    let tmpUrl = url
    if (!tmpUrl) tmpUrl = this.getUrl()
    return modularRequest<T>(tmpUrl, { method, body, text })
  }
}
