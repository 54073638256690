import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import { ISystemTypeGroup } from 'src/service/OrgTypes.js'
import Button from 'src/ui-elements/button/Button'
import List from 'src/ui-elements/list/List'
import BackgroundTaskLoader from 'src/ui-elements/loader/BackgroundTaskLoader'
import Loader from 'src/ui-elements/loader/Loader'
import Modal from 'src/ui-elements/modal/Modal'
import { TabPageStyleClass } from 'src/ui-elements/tabs/Utils'
import {
  deleteSystemTypeGroup,
  getProjectSystemTypeGroupPerDomain,
  swapSystemTypeGroup,
} from '../../../service/SystemTypeGroupService'
import { columns } from './ListColumns'
import SystemTypeGroupForm from './SystemTypeGroupForm'

interface ISystemTypeGroupListProps {
  projectId: number
  domain: string
  subDomain: string
  canCreatingMultiple: boolean
}

const SystemTypeGroupList = ({
  projectId,
  domain,
  subDomain,
  canCreatingMultiple,
}: ISystemTypeGroupListProps) => {
  const { t } = useTranslation()
  const [systemTypeGroups, setSystemTypeGroups] = useState<ISystemTypeGroup[]>(
    [],
  )
  const [selectedSystemTypeGroup, setSelectedSystemTypeGroup] =
    useState<ISystemTypeGroup>()
  const [loading, setLoading] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)

  const reload = useCallback(() => {
    getProjectSystemTypeGroupPerDomain(projectId, subDomain).then(
      (res: ISystemTypeGroup[]) => {
        setSystemTypeGroups(res)
        setLoading(false)
      },
    )
  }, [projectId, subDomain])

  useEffect(() => {
    reload()
  }, [reload])

  const onEditItemClick = (systemGroup: ISystemTypeGroup) => {
    setShowEditModal((n) => !n)
    setSelectedSystemTypeGroup(systemGroup)
  }

  const onDeletItemClick = (systemGroup: ISystemTypeGroup) => {
    setShowDeleteModal((n) => !n)
    setSelectedSystemTypeGroup(systemGroup)
  }

  const openEditModal = () => {
    setShowEditModal((n) => !n)
  }

  const closeEditModal = () => {
    setShowEditModal((n) => !n)
    reload()
  }

  const closeDeleteModal = () => {
    setShowDeleteModal((n) => !n)
  }

  const openCreateModal = () => {
    setShowCreateModal((n) => !n)
  }

  const closeCreateModal = () => {
    setShowCreateModal((n) => !n)
    reload()
  }

  const deleteSystemGroup = () => {
    if (selectedSystemTypeGroup?.id) {
      deleteSystemTypeGroup(selectedSystemTypeGroup?.id).then(reload)
      setShowDeleteModal((n) => !n)
    }
  }

  const swapSystemGroupSequence = (index: number, seq: number) => {
    const selectedSystemTypeGroup = systemTypeGroups?.[index]
    if (selectedSystemTypeGroup.id) {
      swapSystemTypeGroup(projectId, selectedSystemTypeGroup.id, seq).then(
        () => {
          reload()
        },
      )
    }
  }

  return (
    <div className={TabPageStyleClass.root}>
      <List
        actionButton={
          canCreatingMultiple || systemTypeGroups.length === 0 ? (
            <Button onClick={openCreateModal} type={Button.ButtonType.PRIMARY}>
              {t('new_user_defined_group')}
            </Button>
          ) : undefined
        }
        disableColumnSelector={true}
        data={systemTypeGroups}
        columns={columns}
        tableName={'SystemTypeGroupsList'}
        itemsPerPage={0}
        onRowClick={onEditItemClick}
        isRowDraggable={true}
        swapOrder={swapSystemGroupSequence}
        actionMenu={[
          {
            name: t('delete'),
            action: onDeletItemClick,
          },
        ]}
      />

      {selectedSystemTypeGroup && showEditModal && (
        <Modal
          size={'w-9/10'}
          show={showEditModal}
          closeModal={openEditModal}
          title={t('edit_user_defined_field_group')}
          maxWidth={1200}
        >
          <SystemTypeGroupForm
            editingMode={true}
            systemTypeGroupId={selectedSystemTypeGroup.id}
            projectId={projectId}
            closeModal={closeEditModal}
            domain={domain}
            subDomain={subDomain}
          />
        </Modal>
      )}

      {selectedSystemTypeGroup && showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          closeModal={closeDeleteModal}
          onDelete={deleteSystemGroup}
          itemIdnType={`${selectedSystemTypeGroup.name}`}
          itemName={`${selectedSystemTypeGroup.name}`}
        />
      )}

      {showCreateModal && (
        <Modal
          size={'w-9/10'}
          height={'h-1/2'}
          show={showCreateModal}
          closeModal={openCreateModal}
          title={t('new_user_defined_group')}
          maxWidth={1200}
        >
          <SystemTypeGroupForm
            editingMode={false}
            projectId={projectId}
            closeModal={closeCreateModal}
            domain={domain}
            subDomain={subDomain}
          />
        </Modal>
      )}

      {loading ? <Loader /> : null}
      <BackgroundTaskLoader
        jobName={'create_default_values'}
        loadingMessage={t('applying_field_to_all_connected_items')}
        successMessage={t('field_has_been_applied_to_all_connected_items')}
      />
    </div>
  )
}

export default SystemTypeGroupList
