import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FileUploadModal from '../FileUploadModal'
import UploadSummery, { UploadedFiles } from '../fix-upload/UploadSummery'
import { MovedSteps, NEXT_STEP } from '../types'

interface FoldersFileUploadModalProps {
  folderId: number
  show: boolean
  setMovedSteps: (movedSteps: MovedSteps[]) => void
  close: () => void
  revisionUpload?: boolean
}

const FolderFilesUpload: FC<FoldersFileUploadModalProps> = ({
  folderId,
  show,
  setMovedSteps,
  close,
  revisionUpload = false,
}) => {
  const [data, setRes] = useState<UploadedFiles>()

  const { t } = useTranslation()

  const steps = [
    <UploadSummery
      uploadedFiles={data}
      key={1}
      setMovedSteps={setMovedSteps}
      folderId={folderId}
    />,
  ]

  return (
    <FileUploadModal
      multiple={true}
      title={t('import_documents_and_files')}
      showStepCount={true}
      uploadUrl={
        revisionUpload
          ? `file_containers/${folderId}/project_files`
          : `folders/${folderId}/upload_file`
      }
      handleNext={async (_, data) => {
        setRes(data)
        return NEXT_STEP.CONTINUE
      }}
      stepElements={revisionUpload ? [] : steps}
      show={show}
      close={close}
    />
  )
}

export default FolderFilesUpload
