import { PureComponent } from 'react'
import { twMerge } from 'tailwind-merge'
import { capFirstLetter, classNames } from '../../utility/utils'
import Spinner from '../loader/Spinner'
import Tooltip from '../tooltip/Tooltip'
import { ButtonSize, ButtonTextWeight, ButtonType } from './ButtonEnums'
import type { ButtonHTMLAttributes } from 'react'

export interface IButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  type?: ButtonType
  size?: ButtonSize
  buttonType?: string
  buttonTextWeight?: ButtonTextWeight
  noTextWrap?: boolean
  dontSubmit?: boolean
  width?: number | string
  inMobile?: boolean
  disabled?: boolean
  margin?: string
  toolTipText?: string
  toolTipOffset?: number
  toolTipDirection?: 'top' | 'bottom' | 'right' | 'left'
  loading?: boolean
  isLink?: boolean
  customColor?: string
}

export default class Button extends PureComponent<IButtonProps> {
  public static ButtonType = ButtonType
  public static ButtonSize = ButtonSize
  public static ButtonTextWeight = ButtonTextWeight

  public static defaultProps = {
    type: Button.ButtonType.DEFAULT,
    size: ButtonSize.SMALL,
    buttonTextWeight: Button.ButtonTextWeight.DEFAULT,
    noTextWrap: false,
  }

  private static styleClass = {
    root: (
      type: ButtonType | undefined,
      size: ButtonSize | undefined,
      buttonTextWeight: ButtonTextWeight | undefined,
      noTextWrap: boolean | false,
      mobile: boolean,
      disabled: boolean,
      margin?: string,
      customColor?: string,
    ) =>
      classNames(
        type
          ? Button.setType(type, customColor)
          : Button.setType(ButtonType.DEFAULT),
        size === ButtonSize.XSMALL && 'px-3 py-1.5 text-xs leading-4',
        size === ButtonSize.SMALL && 'px-3 py-1.5 text-sm leading-4',
        size === ButtonSize.DEFAULT && 'px-4 py-2 text-sm leading-5',
        size === ButtonSize.LARGE && 'px-4 py-1 text-sm leading-6',
        size === ButtonSize.XLARGE && 'px-6 py-2 text-sm leading-6',
        size === ButtonSize.NARROW && 'px-1 py-1 text-sm leading-5',
        buttonTextWeight === ButtonTextWeight.THIN
          ? 'font-extralight'
          : 'font-normal',
        'tracking-normal',
        margin ? margin : 'm-1',
        'outline-0',
        size === ButtonSize.NARROW ? 'min-w-10' : 'w-auto',
        noTextWrap ? 'whitespace-nowrap' : '',
        'inline-flex',
        'items-center',
        'leading-4',
        'transition',
        'ease-in-out',
        'duration-150',
        'rounded-sm',
        'inline-flex',
        'justify-center',
        mobile ? 'rounded-full mx-0 shadow-none' : '',
        disabled
          ? 'opacity-75 !bg-d-grey-lightest !text-d-grey-light !border-grey-light cursor-not-allowed !fill-d-grey-light'
          : '',
        'group',
      ),
  }

  private static setType(buttonType: ButtonType, customColor?: string) {
    switch (buttonType) {
      case ButtonType.DEFAULT:
        return classNames(
          'bg-white',
          'text-gray-700',
          'hover:text-gray-500',
          'focus:text-gray-500',
          'active:text-gray-500',
          'border',
          'border-gray-300',
        )
      case ButtonType.PRIMARY:
        return classNames(
          'text-white',
          'bg-blue-root',
          'hover:bg-blue-root-focus',
          'focus:outline-none',
          'active:bg-blue-root-focus',
        )
      case ButtonType.SECONDARY:
        return classNames(
          'text-blue-root',
          'fill-blue-root',
          'bg-light-blue',
          'hover:bg-blue-root-focus',
          'hover:text-white',
          'focus:outline-none',
          'active:bg-blue-root-focus',
        )
      case ButtonType.BOUNDARY_WHITE:
        return classNames(
          'text-blue-root',
          'fill-blue-root',
          'bg-white',
          'border-blue-root',
          'border',
          'hover:bg-blue-root-tab-wash',
          'focus:outline-none',
          'active:bg-blue-root-tab-wash',
        )
      case ButtonType.ICON:
        return classNames(
          'bg-gray-50',
          'hover:bg-blue-root-tab-wash',
          'hover:text-white',
          'focus:outline-none',
          'active:bg-blue-root-tab-wash',
        )
      case ButtonType.INFO:
        return classNames(
          'bg-cyan-300',
          'text-white',
          'hover:bg-cyan-400',
          'focus:bg-cyan-400',
          'active:bg-cyan-400',
        )
      case ButtonType.SUCCESS:
        return classNames(
          'bg-green-two',
          'text-white',
          'hover:bg-green-500',
          'focus:bg-green-500',
          'active:bg-green-500',
        )
      case ButtonType.DANGER:
        return classNames(
          'bg-red-100',
          'text-gray-900',
          'hover:bg-red-200',
          'focus:bg-red-200',
          'active:bg-red-200',
        )
      case ButtonType.WARNING:
        return classNames(
          'bg-yellow-300',
          'text-gray-600',
          'hover:bg-yellow-300',
          'focus:bg-yellow-300',
          'active:bg-yellow-300',
        )
      case ButtonType.TOP_MENU:
        return classNames(
          'border-gray-100',
          'bg-white',
          'hover:bg-gray-100',
          'text-blue-root',
        )
      case ButtonType.FADED:
        return classNames(
          'bg-grey',
          'hover:bg-gray-500',
          'focus:bg-gray-500',
          'active:bg-gray-500',
          'text-gray-400',
          'hover:text-white',
          'focus:text-white',
          'active:text-white',
        )
      case ButtonType.ROUNDED_SECONDARY:
        return classNames(
          '!rounded-full',
          'bg-blue-root-lighter',
          'hover:bg-blue-root',
          'focus:bg-blue-root',
          'active:bg-blue-root',
        )
      case ButtonType.ROUNDED_DANGER:
        return classNames(
          '!rounded-full',
          'bg-red-600',
          'hover:bg-red-500',
          'focus:bg-red-500',
          'active:bg-red-500',
        )
      case ButtonType.CUSTOM:
        return classNames(customColor)
      default:
        return 'bg-white'
    }
  }

  constructor(props: IButtonProps, _context: any) {
    super(props)
  }

  render() {
    const {
      type,
      children,
      size,
      buttonType,
      buttonTextWeight,
      noTextWrap,
      dontSubmit,
      width,
      inMobile = false,
      disabled = false,
      margin,
      toolTipText,
      toolTipOffset,
      toolTipDirection,
      className,
      loading,
      isLink,
      customColor,
      ...rest
    } = this.props

    return isLink ? (
      <div
        style={{ width }}
        className={twMerge(
          Button.styleClass.root(
            type,
            size ? size : ButtonSize.DEFAULT,
            buttonTextWeight,
            noTextWrap || false,
            inMobile,
            disabled,
            margin,
            customColor,
          ),
          className,
        )}
      >
        {typeof children === 'string' ? capFirstLetter(children) : children}
      </div>
    ) : (
      <button
        style={{ width }}
        type={
          dontSubmit ? 'button' : buttonType ? buttonType : (undefined as any)
        }
        className={twMerge(
          Button.styleClass.root(
            type,
            size ? size : ButtonSize.DEFAULT,
            buttonTextWeight,
            noTextWrap || false,
            inMobile,
            disabled,
            margin,
            customColor,
          ),
          className,
        )}
        disabled={disabled}
        {...rest}
      >
        {loading ? (
          <Spinner />
        ) : toolTipText ? (
          <Tooltip
            message={toolTipText}
            offset={toolTipOffset}
            direction={toolTipDirection}
          >
            <div className="relative">
              {typeof children === 'string'
                ? capFirstLetter(children)
                : children}
            </div>
          </Tooltip>
        ) : typeof children === 'string' ? (
          capFirstLetter(children)
        ) : (
          children
        )}
      </button>
    )
  }
}
