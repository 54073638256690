import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import DocumentMetaDataFields from 'src/document/components/DocumentCreateModal/DocumentMetaDataFields'
import { IMetaValue } from 'src/document/types/IMetaData'
import { getDisciplines } from 'src/service/DisciplineService'
import { getUploadDocURl } from 'src/service/FileUploadService'
import { getMainProcesses } from 'src/service/ProcessService'
import { getProjectTags } from 'src/service/TagService'
import Modal from 'src/ui-elements/modal/Modal'
import Textarea from 'src/ui-elements/textarea/Textarea'
import { getControlAreaRooms } from '../../service/ControlAreaService'
import { getAllProjectDeliveries } from '../../service/DeliveryService'
import { deleteFile } from '../../service/DocumentService'
import {
  IDelivery,
  IDiscipline,
  IDocument,
  IImprovement,
  IMainProcess,
  IRoom,
  ISystem,
  ITag,
  ITask,
  ITaskData,
  ITaskType,
  IUserData,
} from '../../service/OrgTypes'
import {
  createTask,
  editTask,
  getProjectTaskData,
  getTask,
} from '../../service/TaskService'
import {
  getProjectDisplineUsers,
  getProjectUsersWithDisciplines,
} from '../../service/UserService'
import {
  getErrorMessage,
  getTaskErrorMessage,
  StructureValidationError,
  ValidationError,
} from '../../service/ValidationErrors'
import Button from '../../ui-elements/button/Button'
import Input from '../../ui-elements/input/Input'
import Spinner from '../../ui-elements/loader/Spinner'
import ModalFooter from '../../ui-elements/modal/ModalFooter'
import { renderDayContents } from '../../utility/Utility'
import { capFirstLetter, classNames } from '../../utility/utils'
import UploadFile from '../document/UploadFile'
import UploadFileList from '../document/UploadFileList'
import Selector from '../selectors/Selector'

import { initializeMetaValues, setExistingValues } from '../system/SystemUtil'
import { IMetaField } from '../system/system-type-fields/SystemTypeFieldsForm'
import NoFilterIssuesList from './SelectedTasksList'
import TaskSelectorList from './TaskSelectorList'

interface ITaskForm {
  projectId: number
  editingMode: boolean
  task?: ITaskData
  improvementId?: number
  closeModal: () => void
  lastAllowedDeadline?: moment.Moment
  fromCanvas?: boolean
  copy?: boolean
  selectedTaskTypeId?: number
  controlAreaId?: number
  hideSystem?: boolean
  hidePlanning?: boolean
  onTaskCreated?: (task: ITask) => void
  tableName?: string
  module?: string
  document?: IDocument
}

const TaskForm = ({
  projectId,
  improvementId,
  editingMode,
  task,
  closeModal,
  lastAllowedDeadline,
  fromCanvas,
  copy,
  selectedTaskTypeId,
  controlAreaId,
  hideSystem,
  hidePlanning,
  onTaskCreated,
  tableName,
  module,
  document: propDocument,
}: ITaskForm) => {
  const styleClass = {
    root: classNames('w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'md:flex', 'flex-row', 'my-1'),
    closedDate: (disabled: boolean) =>
      classNames(
        disabled ? 'border-grey-dark' : 'border-blue-one',
        disabled ? 'bg-grey-light' : '',
        'border-1',
        'py-px',
        'border',
        'rounded',
      ),
  }

  const [taskId] = useState<number>(task ? task.id : 0)
  const [title, setTitle] = useState<string>(task ? task.title : '')
  const [description, setDescription] = useState<string>(
    task ? task.description : '',
  )
  const [status, setStatus] = useState<string>(
    task && task.status ? task.status : 'open',
  )
  const [deadline, setDeadline] = useState<any>(
    task ? moment(task.deadline) : null,
  )
  const [endDate] = useState<moment.Moment>(
    task ? (task.endDate ? moment(task.endDate) : moment()) : moment(),
  )
  const [responsibleId, setResponsibleId] = useState<number>(
    task ? task.responsible_id || 0 : 0,
  )
  const [parentId, setParentId] = useState<number>(task ? task.parent_id : 0)
  const [parentType, setParentType] = useState<string>(
    task ? task.parent_type : 'Project',
  )
  const [disciplineId, setDisciplineId] = useState<number>(
    task ? task.discipline_id : 0,
  )
  const [contractId, setContractId] = useState<number | undefined>(
    task ? task.contract_id : 0,
  )
  const [systemId, setSystemId] = useState<number | undefined | null>(
    task ? task.system_id : 0,
  )
  const [locationId, setLocationId] = useState<number | undefined | null>(
    task ? task.location_id : 0,
  )
  const [taskTypeId, setTaskTypeId] = useState<number | null | undefined>(
    selectedTaskTypeId ? selectedTaskTypeId : task?.task_type_id,
  )
  const [taskTypes, setTaskTypes] = useState<ITaskType[]>(
    task && task.task_type ? [task.task_type] : [],
  )
  const [users, setUsers] = useState<IUserData[]>([])
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [systems, setSystems] = useState<ISystem[]>([])
  const [, setLocations] = useState<IRoom[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [deadlineDatePickerFocused, setDeadlineDatePickerFocused] =
    useState<boolean>(false)
  const [deliveries, setDeliveries] = useState<IDelivery[]>([])
  const [mainProcesses, setMainProcesses] = useState<IMainProcess[]>([])
  const [mainProcessId, setMainProcessId] = useState<number | undefined>(
    task ? task.main_process_id : 0,
  )
  const [selectedDelivery, setSelectedDelivery] = useState<number>(
    task && task.parent_id ? task.parent_id : 0,
  )
  const [datoErrorMessage, setDatoErrorMessage] = useState<string>('')
  const [taskTypeErrorMessage, setTaskTypeErrorMessage] = useState('')
  const [titleErrorMessage, setTitleErrorMessage] = useState<string>('')
  const [disciplineErrorMessage, setDisciplineErrorMessage] =
    useState<string>('')
  const [descriptionErrorMessage, setDescriptionErrorMessage] =
    useState<string>('')
  const [responsibleErrorMessage, setResponsibleErrorMessage] =
    useState<string>('')
  const [createMultiple, setCreateMultiple] = useState<boolean>(false)
  const [documents, setDocuments] = useState<IDocument[]>([])
  const [selectLoading, setSelectLoading] = useState<boolean>(false)
  const [duration, setDuration] = useState<number>(0)
  const [usersLoading, setUsersLoading] = useState<boolean>(false)
  const [displineLoading, setDisplineLoading] = useState<boolean>(false)
  const [processLoading, setProcessLoading] = useState<boolean>(false)
  const [tags, setTags] = useState<ITag[]>(task && task.tag ? [task.tag] : [])
  const [tagId, setTagId] = useState<number | null>(
    task && task.tag_id ? task.tag_id : null,
  )
  const [rooms, setRooms] = useState<IRoom[]>(
    task && task.location ? [task.location] : [],
  )
  const [improvements, setImprovements] = useState<IImprovement[]>([])

  const { t } = useTranslation()
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])
  const [systemTypeGroupId, setSystemTypeGroupId] = useState<number | null>(
    null,
  )
  const [showTasksModal, setShowTasksModal] = useState(false)
  const [relatedTasks, setRelatedTasks] = useState<number[]>([])

  const allDocuments = useMemo(() => {
    return propDocument ? [propDocument, ...documents] : documents
  }, [documents, propDocument])

  useEffect(() => {
    moment.locale('no')
    reload()
    setStatus('open')
    if (!fromCanvas) {
      setDeadline(null)
    }
  }, [projectId])

  const reload = () => {
    if (copy && task) {
      getCopiedTask(task)
    } else {
      getProjectTaskData(projectId)
        .then((res: any) => {
          setTaskTypes(res.task_types)
          setUsers(res.users ? res.users : [])
          setDisciplines(res.disciplines ? res.disciplines : [])
          setMainProcesses(res.main_processes ? res.main_processes : [])
          setSystems(res.systems ? res.systems : [])
          if (!controlAreaId) {
            setRooms(res.rooms ? res.rooms : [])
          }
          setLocations(res.rooms ? res.rooms : [])
          setImprovements(res.improvements ? res.improvements : [])
        })
        .catch((err) => console.error(err))

      if (controlAreaId) {
        getControlAreaRooms(controlAreaId).then((res: IRoom[]) => {
          setRooms(res)
        })
      }
    }
  }

  const getCopiedTask = (copiedTask: any) => {
    getTask(projectId, copiedTask.id).then((res) => {
      setTitle(res.title)
      setDuration(res.duration ? res.duration : 0)
      setTags(res.tag ? [res.tag] : [])
      setTagId(res.tag_id ? res.tag_id : 0)
      setDescription(res.description)
      if (!copiedTask.discipline_id) {
        setResponsibleId(res.responsible_id)
        setDisciplineId(res.discipline_id)
        setContractId(res.contract_id ? res.contract_id : null)
        setDisciplines([res.discipline])
      }
      setParentId(res.parent_id)
      setParentType(res.parent_type)
      if (!copiedTask.main_process_id) {
        setMainProcessId(res.main_process_id)
        setMainProcesses(res.main_process ? [res.main_process] : [])
      }
      setUsers([res.responsible])
      setDeadline(moment(copiedTask.date))
    })

    if (copiedTask.main_process_id) {
      setMainProcessId(copiedTask.main_process_id)
      onOpenMainProcess()
    }
    if (copiedTask.discipline_id) {
      setMainProcessId(copiedTask.main_process_id)
      onDisplineOpen()
      getUsers()
    }
  }

  const onOpenMainProcess = () => {
    setProcessLoading(true)
    getMainProcesses(projectId).then((res) => {
      setMainProcesses(res)
      setProcessLoading(false)
    })
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
    let error = false
    setLoading(true)
    setDatoErrorMessage('')
    setTitleErrorMessage('')
    setDisciplineErrorMessage('')
    setDescriptionErrorMessage('')
    setResponsibleErrorMessage('')

    if (!deadline || !moment(deadline).isValid()) {
      setDatoErrorMessage(getErrorMessage(ValidationError.MISSING_DEADLINE, t))
      error = true
    }

    if (deadline && parentId) {
      const delivery = deliveries.find((del) => del.id === parentId)
      if (
        delivery &&
        delivery.endTime &&
        deadline.isAfter(moment(delivery.endTime), 'day')
      ) {
        setDatoErrorMessage(
          getTaskErrorMessage(
            StructureValidationError.PAST_PARENT_DEADLINE,
            t,
          ) +
            ' (' +
            moment(delivery.endTime).format('L') +
            ')',
        )
        error = true
      }
    }

    const improvement = improvements.find((u) => u.id === improvementId)
    if (deadline && improvement && improvement.deadline) {
      if (moment(deadline).isAfter(moment(improvement.deadline), 'day')) {
        setDatoErrorMessage(
          getTaskErrorMessage(
            StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT,
            t,
          ) +
            ' (' +
            moment(improvement.deadline).format('L') +
            ')',
        )
        error = true
      }
    }

    if (deadline && lastAllowedDeadline) {
      if (deadline.isAfter(lastAllowedDeadline, 'day')) {
        setDatoErrorMessage(
          getTaskErrorMessage(
            StructureValidationError.PAST_PARENT_DEADLINE_TASK,
            t,
          ) +
            ' (' +
            moment(lastAllowedDeadline).format('L') +
            ')',
        )
        error = true
      }
    }

    if (!responsibleId || responsibleId === 0) {
      setResponsibleErrorMessage(
        getErrorMessage(ValidationError.MISSING_RESPONSIBLE, t),
      )
      error = true
    }

    if (!title) {
      setTitleErrorMessage(getErrorMessage(ValidationError.MISSING_TITLE, t))
      error = true
    }

    if (!disciplineId || disciplineId === 0) {
      setDisciplineErrorMessage(
        getErrorMessage(ValidationError.MISSING_DISCIPLINE, t),
      )
      error = true
    }

    if (!error) {
      const taskData = {
        id: taskId,
        title,
        description,
        status,
        taskType: 'Aksjon',
        deadline: moment(deadline, 'L'),
        responsible_id: responsibleId,
        parent_id: parentId,
        parent_type: parentType,
        discipline_id: disciplineId,
        contract_id: contractId,
        system_id: systemId,
        location_id: locationId,
        project_id: projectId,
        related_task_ids: relatedTasks,
        task_type_id: taskTypeId ?? null,
        main_process_id: mainProcessId,
        tag_id: tagId ? tagId : undefined,
        endDate: status === 'done' ? endDate : undefined,
        documents: allDocuments,
        improvement_id: improvementId,
        duration,
        optional_fields: optionalFields,
        system_type_group_id: systemTypeGroupId,
      }

      if (editingMode) {
        await editTask(taskData)
      } else {
        const taskCreatedRes = await createTask(taskData)
        onTaskCreated?.(taskCreatedRes)
      }
      if (!createMultiple) {
        closeModal()
      } else {
        setLoading(false)
        setTitle('')
        setDocuments([])
        setCreateMultiple(false)
      }
    } else {
      setLoading(false)
    }
  }

  const onSelectMainProcess = (id: number) => {
    setMainProcessId(id)
  }

  const onTitleChange = (e: any) => {
    const value = e.target.value
    setTitle(value)
    setTitleErrorMessage('')
  }

  const onDescriptionChange = (e: any) => {
    const value = e.target.value
    setDescription(value)
    setDescriptionErrorMessage('')
  }

  const onDurationChange = (e: any) => {
    const value = +e.target.value
    setDuration(value)
  }

  const onDeadlineChange = (date: moment.Moment) => {
    setDeadline(date)
    setDatoErrorMessage('')
  }

  const setDeadlineDatepickerFocus = (focused: any) => {
    setDeadlineDatePickerFocused(focused.focused)
  }

  const outSideRangeDate = () => {
    return false
  }

  const getUsers = () => {
    setUsersLoading(true)
    if (disciplineId > 0) {
      getProjectDisplineUsers(projectId, disciplineId).then((res) => {
        setUsers(res)
        setUsersLoading(false)
      })
    } else {
      getProjectUsersWithDisciplines(projectId).then((res) => {
        setUsers(res)
        setUsersLoading(false)
      })
    }
  }

  const onDisplineOpen = () => {
    setDisplineLoading(true)
    getDisciplines(projectId).then((res) => {
      setDisciplines(res)
      setDisplineLoading(false)
    })
  }

  const onDisciplineChange = async (value: number) => {
    const discipline = disciplines
      ? disciplines.find((v) => v.id === value)
      : undefined
    if (!responsibleId || responsibleId <= 0) {
      const usersList = await getProjectDisplineUsers(projectId, value)
      setResponsibleId(0)
      setUsers(usersList)
    }

    setDisciplineId(value)
    setContractId(
      discipline && discipline.contract_id ? discipline.contract_id : undefined,
    )

    setResponsibleErrorMessage('')
    setDisciplineErrorMessage('')
  }

  const onResponsibleChange = (userId: number) => {
    const selecteduser: IUserData | undefined = users
      .filter((u) => u.id === userId)
      .pop()
    const userDisciplines =
      selecteduser && selecteduser.disciplines
        ? selecteduser.disciplines.filter((d) => d.project_id === projectId)
        : undefined
    const discipline =
      userDisciplines && userDisciplines.length === 1
        ? userDisciplines[0]
        : undefined

    setResponsibleId(userId)
    setDisciplines(userDisciplines ? userDisciplines : [])
    if (discipline) {
      setDisciplineId(discipline.id)
      setContractId(discipline.contract_id)
    }

    setResponsibleErrorMessage('')
    setDisciplineErrorMessage('')
  }

  const onDisplineUserClear = async () => {
    setDisciplineId(0)
    setResponsibleId(0)
    const allProjectDis = await getDisciplines(projectId)
    const allUsers = await getProjectUsersWithDisciplines(projectId)
    setDisciplines(allProjectDis)
    setUsers(allUsers)
  }

  const onSelectDelivery = (delivery: number) => {
    setSelectedDelivery(delivery)
    setParentId(delivery)
    setParentType('Delivery')
    if (!mainProcessId) {
      const selectDelivery = deliveries.find((del) => del.id === delivery)
      if (selectDelivery) {
        setMainProcessId(selectDelivery.main_process_id)
      }
    }
  }

  const onClearDelivery = () => {
    setSelectedDelivery(0)
    setParentId(projectId)
    setParentType('Project')
    setDatoErrorMessage('')
  }

  const onSingleCreate = () => {
    setCreateMultiple(false)
  }

  const onMultipleCreate = () => {
    setCreateMultiple(true)
  }

  const getDeliveries = async () => {
    setSelectLoading(true)
    const allDelivers = await getAllProjectDeliveries(projectId)
    setDeliveries(allDelivers)
    setSelectLoading(false)
  }

  const filesUploaded = (docs: IDocument[]) => {
    setDocuments(documents.concat(docs))
  }

  const updateUploadedFiles = (docs: IDocument[]) => {
    setDocuments(docs)
  }

  const onCloseModal = () => {
    documents.forEach((item) => {
      deleteFile({ file_url: item.fileUrl }).catch((err) => console.error(err))
    })

    setDocuments([])

    closeModal()
  }

  const onTagChange = (value: number) => {
    setTagId(value)
  }

  const setMetaDataSection = (fields?: IMetaField[]) => {
    const metaFields = initializeMetaValues(fields ?? [], 'Task', task?.id)
    setOptionalFields(
      setExistingValues(task?.optional_fields ?? [], metaFields),
    )
  }
  const onTaskTypeChange = (value: number) => {
    const taskType = taskTypes?.find((item) => item.id === value)
    if (taskType) {
      setMetaDataSection(taskType.optional_fields)
      setSystemTypeGroupId(taskType.system_type_group_id ?? null)
    }
    setTaskTypeId(value)
    setTaskTypeErrorMessage('')
  }

  const onCancelTaskType = () => {
    setTaskTypeId(undefined)
    setMetaDataSection([])
    setSystemTypeGroupId(null)
    setTaskTypeErrorMessage('')
  }

  const getTags = () => {
    setSelectLoading(true)
    getProjectTags(projectId).then((res) => {
      if (res) {
        setTags(res)
        setSelectLoading(false)
      }
    })
  }

  const onOptionalFieldsUpdate = (values: IMetaValue[]) => {
    setOptionalFields(values)
  }

  return (
    <Modal
      show={true}
      title={capFirstLetter(
        tableName === 'TaskActionTable' || module === 'planning'
          ? t('new_task_original')
          : t('add_task'),
      )}
      closeModal={closeModal}
      maxWidth={800}
      confirmOutsideClick={true}
    >
      <form className={styleClass.root} onSubmit={onSubmit}>
        <div className={`${styleClass.inputGroup} flex-wrap items-center`}>
          <div className={'w-mx-content flex flex-col'}>
            <div className={'flex'}>
              <label className={'pl-2 text-sm text-gray-700 font-medium my-2'}>
                {capFirstLetter(t('deadline'))} *
              </label>
              {datoErrorMessage ? (
                <label className={'pl-2 text-sm my-2'} style={{ color: 'Red' }}>
                  {datoErrorMessage}
                </label>
              ) : null}
            </div>
            <div className={'pl-2 mb-1 pr-2'}>
              <SingleDatePicker
                firstDayOfWeek={1}
                date={deadline}
                onDateChange={onDeadlineChange}
                renderDayContents={renderDayContents}
                focused={deadlineDatePickerFocused}
                onFocusChange={setDeadlineDatepickerFocus}
                id="Starttidspunkt"
                small={true}
                isOutsideRange={outSideRangeDate}
                showDefaultInputIcon={true}
                noBorder={true}
                numberOfMonths={1}
                displayFormat={() =>
                  moment.localeData('no').postformat('DD.MM.YY')
                }
                hideKeyboardShortcutsPanel={true}
              />
            </div>
          </div>
        </div>
        <div className={`${styleClass.inputGroup} flex-wrap items-center`}>
          <div className={'w-full lg:w-1/2'}>
            <Input
              label={t('duration_days')}
              value={duration}
              onChange={onDurationChange}
              block={true}
              required={false}
              type={'number'}
              minValue={0}
              step={'any'}
            />
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <Input
            label={t('title')}
            block={true}
            value={title}
            onChange={onTitleChange}
            required={true}
            errorMessage={titleErrorMessage}
            autoFocus={true}
          />
        </div>

        <div className={`${styleClass.inputGroup} flex-wrap`}>
          <div className={'w-full lg:w-1/2'}>
            <Selector
              items={disciplines}
              selectedItemId={disciplineId}
              onOpenSelectFunction={onDisplineOpen}
              loading={displineLoading}
              onSelect={onDisciplineChange}
              label={t('discipline')}
              dataFields={['shortName', 'name']}
              required={true}
              fontSize={'sm'}
              fontWeight={'bold'}
              errorMessage={disciplineErrorMessage}
              onCancel={onDisplineUserClear}
              cancelButton={true}
            />
          </div>
          <div className={'w-full lg:w-1/2'}>
            <Selector
              items={users}
              selectedItemId={responsibleId}
              onOpenSelectFunction={getUsers}
              onSelect={onResponsibleChange}
              label={t('responsible')}
              dataFields={['firstName', 'lastName']}
              required={true}
              fontSize={'sm'}
              userSelector={true}
              fontWeight={'bold'}
              errorMessage={responsibleErrorMessage}
              loading={usersLoading}
              onCancel={onDisplineUserClear}
              cancelButton={true}
            />
          </div>
        </div>
        {!hidePlanning && (
          <div className={`${styleClass.inputGroup} flex-wrap`}>
            {task &&
            task.parent_id &&
            task.parent_type &&
            task.parent_type !== 'Project' ? null : (
              <div className={'w-full lg:w-1/2'}>
                <Selector
                  items={deliveries}
                  selectedItemId={selectedDelivery}
                  onSelect={onSelectDelivery}
                  onOpenSelectFunction={getDeliveries}
                  loading={selectLoading}
                  label={t('delivery')}
                  dataFields={['name']}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                  cancelButton={true}
                  onCancel={onClearDelivery}
                />
              </div>
            )}

            <div
              className={`w-full ${
                task &&
                task.parent_id &&
                task.parent_type &&
                task.parent_type !== 'Project'
                  ? 'lg:w-full'
                  : 'lg:w-1/2'
              }`}
            >
              <Selector
                items={mainProcesses}
                selectedItemId={mainProcessId ? mainProcessId : 0}
                onSelect={onSelectMainProcess}
                loading={processLoading}
                onOpenSelectFunction={onOpenMainProcess}
                label={t('main_process')}
                dataFields={['name']}
                required={false}
                fontSize={'sm'}
                fontWeight={'bold'}
              />
            </div>
          </div>
        )}
        <div className={styleClass.inputGroup}>
          <div className={'w-full md:w-1/2'}>
            <Selector
              items={tags}
              selectedItemId={tagId ? tagId : 0}
              loading={selectLoading}
              onSelect={onTagChange}
              onOpenSelectFunction={getTags}
              label={t('type')}
              dataFields={['name']}
              fontSize={'sm'}
              fontWeight={'bold'}
              cancelButton={true}
              onCancel={() => setTagId(null)}
            />
          </div>
          <div className={'w-full md:w-1/2'}>
            <Selector
              items={taskTypes}
              selectedItemId={taskTypeId ?? 0}
              loading={selectLoading}
              onSelect={onTaskTypeChange}
              label={t('task_type')}
              dataFields={['taskTypeName']}
              fontSize={'sm'}
              fontWeight={'bold'}
              errorMessage={taskTypeErrorMessage}
              cancelButton={true}
              onCancel={onCancelTaskType}
            />
          </div>
        </div>
        {!hideSystem && (
          <div className={styleClass.inputGroup}>
            <div className={'w-full md:w-1/2'}>
              <Selector
                items={rooms}
                selectedItemId={locationId ? locationId : 0}
                onSelect={(value) => setLocationId(value)}
                label={t('room')}
                dataFields={['room_name', 'functional_room_number']}
                fontSize={'sm'}
                fontWeight={'bold'}
                cancelButton={true}
                onCancel={() => setLocationId(null)}
              />
            </div>
            <div className={'w-full md:w-1/2'}>
              <Selector
                items={systems}
                selectedItemId={systemId ?? 0}
                onSelect={(value) => setSystemId(value)}
                label={t('system')}
                dataFields={['record_id', 'name']}
                fontSize={'sm'}
                fontWeight={'bold'}
                cancelButton={true}
                onCancel={() => setSystemId(null)}
              />
            </div>
          </div>
        )}

        <div className="pl-1 py-2">
          <Button
            type={Button.ButtonType.BOUNDARY_WHITE}
            dontSubmit={true}
            onClick={() => setShowTasksModal(true)}
          >
            {tableName === 'TaskActionTable' || module === 'planning'
              ? t('select_related_tasks_planning')
              : t('select_related_tasks')}
          </Button>
        </div>

        <div className="pl-2">
          <NoFilterIssuesList
            key={showTasksModal ? 1 : 2}
            selectedIds={relatedTasks}
          />
        </div>
        <div className={styleClass.inputGroup}>
          <Textarea
            label={t('description')}
            value={description}
            isValid={false}
            autoFocus={false}
            onChange={onDescriptionChange}
            errorMessage={descriptionErrorMessage}
            block={true}
          />
        </div>

        {optionalFields.length > 0 && (
          <div className="pb-8">
            <DocumentMetaDataFields
              title={''}
              onFieldsUpdate={onOptionalFieldsUpdate}
              fields={optionalFields}
              required={false}
            />
          </div>
        )}

        {allDocuments.length > 0 && (
          <UploadFileList
            documents={allDocuments}
            updateDocuments={updateUploadedFiles}
          />
        )}

        <UploadFile
          uploadUrl={getUploadDocURl(projectId, 'Task')}
          uploadedDocuments={filesUploaded}
        />

        <ModalFooter>
          <Button onClick={onCloseModal}>{capFirstLetter(t('cancel'))}</Button>
          {editingMode ? (
            <Button type={Button.ButtonType.PRIMARY} disabled={loading}>
              {loading ? <Spinner /> : capFirstLetter(t('update'))}
            </Button>
          ) : (
            <>
              <Button
                type={Button.ButtonType.SECONDARY}
                noTextWrap={true}
                onClick={onMultipleCreate}
                disabled={loading}
              >
                {loading ? <Spinner /> : capFirstLetter(t('add_multiple'))}
              </Button>
              <Button
                type={Button.ButtonType.PRIMARY}
                noTextWrap={true}
                onClick={onSingleCreate}
                disabled={loading}
              >
                {loading ? <Spinner /> : capFirstLetter(t('add'))}
              </Button>
            </>
          )}
        </ModalFooter>
      </form>
      {showTasksModal && (
        <Modal
          title={t('related_tasks_original')}
          closeModal={() => setShowTasksModal(false)}
          maxWidth={1280}
          show={showTasksModal}
        >
          <div className="pl-1 min-h-[68vh]">
            <TaskSelectorList
              projectId={projectId}
              onSelectIds={setRelatedTasks}
              selectedIds={relatedTasks}
            />
          </div>
          <ModalFooter>
            <Button onClick={() => setShowTasksModal(false)}>
              {capFirstLetter(t('cancel'))}
            </Button>
            <Button
              type={Button.ButtonType.PRIMARY}
              noTextWrap={true}
              onClick={() => setShowTasksModal(false)}
              disabled={loading}
            >
              {capFirstLetter(t('save'))}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Modal>
  )
}

export default TaskForm
